import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getServiceList, emptyServiceList, postService, handleRemove } from '../../actions/serviceAction';
import { Grid, Header, Button, Icon, Image, Dimmer, Loader, Modal, Form, Confirm, Input } from 'semantic-ui-react';
import InfiniteScroll from "react-infinite-scroll-component";

class ServiceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceList: [],
            hasMore: true,
            serviceId: '',
            serviceName: '',
            endScrollText: 'No more data to load!',
            modalOpen: false,
            confirmOpen: false,
            file: {},
            height: 512
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        this.updateWindowDimensions();
        this.props.getServiceList('/api/v1/additionally')
        .then(res => {
            if(res){
                if(res.status === 200){
                    var arrLength = res.data.data.length;
                    if(arrLength < 20){
                        if(arrLength === 0){
                            this.setState({endScrollText: 'The list is empty'});
                        }
                        this.setState({ hasMore: false });
                        return;
                    }
                }
            }
        },function(error){
            if(error){
                this.setState({
                    hasMore: false
                }, function(){
                    alert(error.data.message);
                });
            }
        }.bind(this));
    }

    updateWindowDimensions() {
        this.setState({ height: window.outerHeight - 230 });
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if(prevProps.serviceList !== this.props.serviceList){
            this.setState({serviceList: this.props.serviceList})
        }
    }

    componentWillUnmount(){
        //need to empty the array from reducer when leaving the component
        this.props.emptyServiceList();
    }

    onChange(e) {
        const value = e.target.value;
        const name = e.target.name;
        // console.log(value, name);
        this.setState({
            [name]: value
        });
    }

    onFileChange = (e) => {
        // console.log(e.target.files[0]);
        this.setState({file: e.target.files[0]});
    }

    //for toggling the Modal
    handleModalToggle = () => this.setState({ modalOpen: !this.state.modalOpen });

    //creating new service
    createService(){
        const {file, serviceName} = this.state;
        var data = new FormData();
        data.append('icon', file);
        data.append('name', serviceName)

        const self = this;
        this.props.postService('/api/v1/additionally', data)
        .then(res => {
            if(res){
                self.setState({
                    modalOpen: false,
                    file: {}
                });
            }
            else{
                alert("Could not create a new service, please try again!")
            }
        });
    }

    // calls confirmation window for deleting the service
    handelConfirmation = (serviceId) => {
        this.setState({ confirmOpen: !this.state.confirmOpen, serviceId });
    }

    //removes the deal
    deleteService(serviceId) {
        this.props.handleRemove('/api/v1/additionally/' + serviceId, serviceId)
        .then(function(res) {
            if(res.status === 200){
                if(this.state.serviceList.length === 0)
                    this.setState({endScrollText: 'The list is empty'});
                this.setState({
                    confirmOpen: false
                });
            }
        }.bind(this),function(error){
            if(error){
                this.setState({
                    confirmOpen: false
                }, function(){
                    alert(error.data.message);
                });
            }
        }.bind(this));
    }
    
    //load more services
    fetchMoreData = () => {
        this.props.getServiceList('/api/v1/additionally?limit=20&before=' + this.props.lastItemId)
        .then(res => {
            if(res){
                if(res.status === 200){
                    var arrLength = res.data.data.length;
                    if(arrLength === 0){
                        this.setState({ hasMore: false });
                        return;
                    }
                }
            }
        });
    };

    render() {
        const { serviceList, modalOpen, serviceId, serviceName,
                endScrollText, confirmOpen, height } = this.state;

        const isEmpty = function(obj){
            if(Object.keys(obj).length === 0 && obj.constructor === Object)
                return true;
            else
                return false;
        }
        
        return (
            <div style={{paddingLeft: '5px'}}>
                <Grid style={{margin: '0'}}>
                    <Grid.Row columns='equal'>
                        <Grid.Column></Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h3'>Additional Services</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Button color='teal' floated='right' compact
                                    onClick={this.handleModalToggle.bind(this)}>
                                <Icon name='add' />
                                Add new
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1} color='blue'>№</Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Icon
                        </Grid.Column>
                        <Grid.Column width={9} color='blue'>  
                            Service Name
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>
                            Action
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <InfiniteScroll
                    dataLength={serviceList.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<h4>Loading...</h4>}
                    height={height}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                        <b>{endScrollText}</b>
                        </p>
                    }
                >
                    <Grid celled style={{marginTop: '0'}}>
                        {serviceList.map(function(service, index) {
                            return (
                                <Grid.Row key={service.id} verticalAlign='middle'>
                                    <Grid.Column width={1}>{index + 1}</Grid.Column>
                                    <Grid.Column width={2} textAlign='center'>
                                        <Image
                                            src={isEmpty(service.icon) ? require('../../assets/img/no_image.png') : service.icon.url} 
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={9}>
                                        {service.name}
                                    </Grid.Column>
                                    <Grid.Column width={4} className='tableActions'>
                                        <Button size='mini' inverted color='red'
                                                onClick={this.handelConfirmation.bind(this, service.id)}>
                                            <Icon name='trash' />
                                            Delete
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        }.bind(this))}
                    </Grid>
                </InfiniteScroll>
                <Modal
                    centered={false} size='mini'
                    open={modalOpen}
                    onClose={this.handleModalToggle.bind(this)}
                >
                    <Modal.Content>
                        <Form size='mini' onSubmit={this.createService.bind(this)}>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Input 
                                            fluid type='file' required
                                            onChange = {(e) => this.onFileChange(e)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Form.Input 
                                            fluid label='Название' 
                                            placeholder='название...' required
                                            value={serviceName}
                                            name="serviceName"
                                            onChange={this.onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Button color='green' size='mini'
                                                type='submit' floated='right'>
                                            <Icon name='add'/> 
                                                Create
                                        </Button>
                                        <Button color='orange' size='mini' 
                                                type='button' floated='right'
                                                onClick={this.handleModalToggle.bind(this)}>
                                            <Icon name='cancel' /> Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
                <Confirm
                    open={confirmOpen}
                    content='Вы действительно хотите удалить?'
                    cancelButton='Отмена'
                    confirmButton="Удалить"
                    onCancel={this.handelConfirmation.bind(this, '')}
                    onConfirm={this.deleteService.bind(this, serviceId)}
                />
            </div>
        );
    }
}

ServiceList.propTypes = {
    getServiceList: PropTypes.func,
    serviceList: PropTypes.array,
    lastItemId: PropTypes.string,
    requestInProcess: PropTypes.bool,
    postService: PropTypes.func,
    emptyServiceList: PropTypes.func,
    handleRemove: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceList: (url) => dispatch(getServiceList(url)),
        postService: (url, data) => dispatch(postService(url, data)),
        emptyServiceList: () => dispatch(emptyServiceList()),
        handleRemove: (url, serviceId) => dispatch(handleRemove(url, serviceId))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        serviceList: state.serviceList,
        lastItemId: state.lastItemId
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
export function branchList(state = [], action) {
    switch (action.type) {
        case 'GET_BRANCH_LIST_SUCCESS':
            // return state.concat(action.list);
            return action.list;
        case 'GET_BRANCH_LIST_FOR_DROPDOWN_SUCCESS':
            return action.list;
        case 'ADD_BRANCH_SUCCESS':
            return [action.branch, ...state]
        case 'EMPTY_BRANCH_LIST':
            return [];
        case 'DELETE_BRANCH_SUCCESS':
            return state.filter(item => item.id !== action.branchId);
        default:
            return state;
    }
}

export function dropdownBranchList(state = [], action) {
    switch (action.type) {
        case 'GET_DROPDOWN_BRANCH_LIST_SUCCESS':
            return action.list;
        default:
            return state;
    }
}

export function branch(state = {}, action) {
    switch (action.type) {
        case 'GET_BRANCH_DETAIL_SUCCESS':
            return action.branch;
        default:
            return state;
    }
}

export function corporateUserList(state = [], action) {
    switch (action.type) {
        case 'GET_CORPORATE_USER_LIST':
            return action.list;
        case 'ADD_CORPORATE_USER':
            return [action.user, ...state]
        case 'DELETE_CORPORATE_USER':
            return state.filter(item => item.id !== action.userId);
        default:
            return state;
    }
}

export function starsList(state = [], action) {
    switch (action.type) {
        case 'GET_STARS_LIST':
            return state.concat(action.list);
        case 'EMPTY_STARS_LIST':
            return [];
        default:
            return state;
    }
}
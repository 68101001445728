export function serviceList(state = [], action) {
  switch (action.type) {
      case 'GET_SERVICE_LIST_SUCCESS':
          return state.concat(action.list);
      case 'ADD_SERVICE_SUCCESS':
          return [...state, action.service];
      case 'UPDATE_SERVICE_SUCCESS':
          const index = state.findIndex(d => d.id === action.serviceId);
          var selectedService = state.find(d => d.id === action.serviceId);
          selectedService.name = action.service.name;
          selectedService.description = action.service.description;
          return [...state.slice(0, index), selectedService, ...state.slice(index + 1)];
      case 'EMPTY_SERVICE_LIST':
          return [];
      case 'DELETE_SERVICE_SUCCESS':
          return state.filter(item => item.id !== action.serviceId);
      default:
          return state;
  }
}
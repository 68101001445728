import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStarsList, emptyStarsList, updateBranchDetails } from '../../actions/branchAction';
import { Grid, Image, Icon, Popup, Header } from 'semantic-ui-react';
import InfiniteScroll from "react-infinite-scroll-component";

class Ambassador extends Component {
    state = {
        ambassador: this.props.ambassador,
        starsList: [],
        hasMore: true
    };

    componentDidMount(){
        this.props.getStarsList('/api/v1/stars');
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.starsList !== this.props.starsList){
            this.setState({starsList: this.props.starsList})
        }
    }

    componentWillUnmount(){
        this.props.emptyStarsList();
    }
    
    fetchMoreData = () => {
        this.props.getStarsList('/api/v1/stars?limit=5&before=' + this.props.lastItemId)
        .then(res => {
            if(res){
                if(res.status === 200){
                    var arrLength = res.data.data.length;
                    if(arrLength === 0){
                        this.setState({ hasMore: false });
                        return;
                    }
                }
            }
        });
    };

    makeAmbassador = (id) => {
        const {branchId} = this.props;
        const data = {star_id: id};
        this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/ambassador', data)
        .then(res => {
            if(res.status === 200){
                this.setState({ambassador: res.data.data.ambassador});
            }
        })
    }

    render() {
        const {ambassador, starsList} = this.state;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <span style={{fontSize: '15px', fontWeight: 'bold', color: 'blue'}}>
                            {ambassador ? ambassador.first_name + ' ' + ambassador.last_name : ''}
                        </span>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign='center'>
                        <Header as='h3'>
                            {ambassador ? 'Change the ambassador' : 'Select the ambassador'}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={16}>
                        <Grid style={{margin: '0'}}>
                            <Grid.Row>
                                <Grid.Column width={1} color='blue'></Grid.Column>
                                <Grid.Column width={2} color='blue'>
                                    Avatar
                                </Grid.Column>
                                <Grid.Column width={3} color='blue'>
                                    First name
                                </Grid.Column>
                                <Grid.Column width={3} color='blue'>
                                    Last name
                                </Grid.Column>
                                <Grid.Column width={4} color='blue'>  
                                    Phone
                                </Grid.Column>
                                <Grid.Column width={3} color='blue'>
                                    Is Ambassador?
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <InfiniteScroll
                            dataLength={starsList.length}
                            next={this.fetchMoreData}
                            hasMore={this.state.hasMore}
                            loader={<h4>Loading...</h4>}
                            height={500}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                <b>No more data to load!</b>
                                </p>
                            }
                        >
                            <Grid celled style={{marginTop: '0'}}>
                                {starsList.map((user, index) => {
                                    const isAmbassador = (ambassador && ambassador.id === user.id) ? true : false;
                                    return (
                                        <Grid.Row key={user.id}>
                                            <Grid.Column width={1}>{index + 1}</Grid.Column>
                                            <Grid.Column width={2}>
                                                <Image 
                                                    style={{height: '80px', width: '80px'}}
                                                    avatar size='tiny'
                                                    src={user.avatar === '' ? require('../../assets/img/no_image.png') : user.avatar}  
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={3}>{user.first_name}</Grid.Column>
                                            <Grid.Column width={3}>{user.last_name}</Grid.Column>
                                            <Grid.Column width={4}>{user.phone}</Grid.Column>
                                            <Grid.Column width={3}>
                                                {isAmbassador ?
                                                    <Icon size='large' color='green'
                                                        name='check square'
                                                    /> :
                                                    <Popup
                                                        content='Make Ambassador'
                                                        trigger={<Icon size='large' color='green'
                                                            name='square outline'
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => this.makeAmbassador(user.id)}
                                                        />} 
                                                    />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })}
                            </Grid>
                        </InfiniteScroll>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

Ambassador.propTypes = {
    requestInProcess: PropTypes.bool,
    getStarsList: PropTypes.func,
    emptyStarsList: PropTypes.func,
    starsList: PropTypes.array,
    lastItemId: PropTypes.string,
    updateBranchDetails: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        starsList: state.starsList,
        lastItemId: state.lastItemId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getStarsList: (url) => dispatch(getStarsList(url)),
        emptyStarsList: () => dispatch(emptyStarsList()),
        updateBranchDetails: (url, data) => dispatch(updateBranchDetails(url, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ambassador);
import React, { useState } from 'react';
import { Form, FormField, Input, Button } from 'semantic-ui-react';
import moment from 'moment';

const ProfileData = (props) => {
  const { created_at, started_at, expired_at, docs_received_at, 
    integrated_at, trained_at, contract_renewed_at, contract_expires_at, 
    manager_name, manager_phone, contract_amount, contract_number, 
    business_phone, delay_days, saveSubscriptionData } = props;
  const [createdAt, setCreatedAt] = useState(() => {
    if (created_at !== 0) {
      return moment.unix(created_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [startedAt, setStartedAt] = useState(() => {
    if (started_at !== 0) {
      return moment.unix(started_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [expiredAt, setExpiredAt] = useState(() => {
    if (expired_at !== 0) {
      return moment.unix(expired_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [docsReceivedAt, setDocsReceivedAt] = useState(() => {
    if (docs_received_at !== 0) {
      return moment.unix(docs_received_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [integratedAt, setIntegratedAt] = useState(() => {
    if (integrated_at !== 0) {
      return moment.unix(integrated_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [trainedAt, setTrainedAt] = useState(() => {
    if (trained_at !== 0) {
      return moment.unix(trained_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [contractRenewedAt, setContractRenewedAt] = useState(() => {
    if (contract_renewed_at !== 0) {
      return moment.unix(contract_renewed_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [contractExpiresAt, setContractExpiresAt] = useState(() => {
    if (contract_expires_at !== 0) {
      return moment.unix(contract_expires_at/1000).format("YYYY-MM-DD");
    }
    return '';
  });
  const [managerName, setManagerName] = useState(manager_name);
  const [managerPhone, setManagerPhone] = useState(manager_phone);
  const [contractAmount, setContractAmount] = useState(() => {
    if (contract_amount > 0) {
      return contract_amount;
    }
    return '';
  });
  const [contractNumber, setContractNumber] = useState(contract_number);
  const [businessPhone, setBusinessPhone] = useState(business_phone);
  const [delayDays, setDelayDays] = useState(() => {
    if (delay_days > 0) {
      return delay_days;
    }
    return '';
  });

  const saveData = () => {
    saveSubscriptionData({
      created_at: moment(createdAt, 'YYYY.MM.DD').unix() * 1000,
      started_at: moment(startedAt, 'YYYY.MM.DD').unix() * 1000,
      expired_at: moment(expiredAt, 'YYYY.MM.DD').unix() * 1000,
      docs_received_at: moment(docsReceivedAt, 'YYYY.MM.DD').unix() * 1000,
      integrated_at: moment(integratedAt, 'YYYY.MM.DD').unix() * 1000,
      trained_at: moment(trainedAt, 'YYYY.MM.DD').unix() * 1000,
      contract_renewed_at: moment(contractRenewedAt, 'YYYY.MM.DD').unix() * 1000,
      contract_expires_at: moment(contractExpiresAt, 'YYYY.MM.DD').unix() * 1000,
      manager_name: managerName,
      manager_phone: managerPhone,
      contract_amount: Number(contractAmount),
      contract_number: contractNumber,
      business_phone: businessPhone,
      delay_days: Number(delayDays)
    });
  }

  return (
    <Form style={{ display: 'flex', gap: 16 }} onSubmit={saveData}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
        <FormField>
          <label>Дата создания</label>
          <Input
            name='created_at'
            type='date'
            value={createdAt}
            onChange={(e) => setCreatedAt(e.target.value)}
            readOnly={created_at !== 0}
          />
        </FormField>
        <FormField required>
          <label>Дата запуска</label>
          <Input
            name='started_at'
            type='date'
            value={startedAt}
            onChange={(e) => setStartedAt(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Срок подписки</label>
          <Input
            name='expired_at'
            type='date'
            value={expiredAt}
            onChange={(e) => setExpiredAt(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Дата отправки документации</label>
          <Input
            name='docs_received_at'
            type='date'
            value={docsReceivedAt}
            onChange={(e) => setDocsReceivedAt(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Дата интеграции</label>
          <Input
            name='integrated_at'
            type='date'
            value={integratedAt}
            onChange={(e) => setIntegratedAt(e.target.value)}
            required
          />
        </FormField>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
        <FormField required>
          <label>Имя менеджера</label>
          <Input
            placeholder='Введите имя менеджера'
            name='manager_name'
            value={managerName}
            onChange={(e) => setManagerName(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Номер телефона</label>
          <Input
            placeholder='Введите номер телефона'
            name='manager_phone'
            value={managerPhone}
            onChange={(e) => setManagerPhone(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Сумма договора</label>
          <Input
            placeholder='Введите сумму договора'
            type='number'
            name='contract_amount'
            value={contractAmount}
            onChange={(e) => setContractAmount(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Номер договора</label>
          <Input
            placeholder='Введите номер договора'
            name='contract_number'
            value={contractNumber}
            onChange={(e) => setContractNumber(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Контактный номер бизнеса</label>
          <Input
            placeholder='Введите контактный номер'
            name='business_phone'
            value={businessPhone}
            onChange={(e) => setBusinessPhone(e.target.value)}
            required
          />
        </FormField>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
        <FormField required>
          <label>Кол-во дней отсрочки</label>
          <Input
            placeholder='Введите кол-во дней'
            type='number'
            name='delay_days'
            value={delayDays}
            onChange={(e) => setDelayDays(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Дата обучения</label>
          <Input
            name='trained_at'
            type='date'
            value={trainedAt}
            onChange={(e) => setTrainedAt(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Дата продления договора</label>
          <Input
            name='contract_renewed_at'
            type='date'
            value={contractRenewedAt}
            onChange={(e) => setContractRenewedAt(e.target.value)}
            required
          />
        </FormField>
        <FormField required>
          <label>Дата окончания договора</label>
          <Input
            name='contract_expires_at'
            type='date'
            value={contractExpiresAt}
            onChange={(e) => setContractExpiresAt(e.target.value)}
            required
          />
        </FormField>
        <Button
          color='teal'
          style={{
            display: 'flex',
            width: 'fit-content',
            height: 'fit-content',
            marginTop: 'auto',
            marginLeft: 'auto'
          }}
          type="submit"
        >Сохранить</Button>
      </div>
    </Form>
  )
}

export default ProfileData
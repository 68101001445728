export function productList(state = [], action) {
    switch (action.type) {
        case 'GET_PRODUCT_LIST_SUCCESS':
            return action.list;
        case 'ADD_PRODUCT_TO_LIST':
            return [...state, action.product];
        case 'UPDATE_PRODUCT_SUCCESS':
            const index = state.findIndex(x => x.id === action.product.id);
            return [...state.slice(0, index), action.product, ...state.slice(index + 1)];
        case 'DELETE_PRODUCT_SUCCESS':
            return state.filter(item => item.id !== action.productId);
        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCityList, deleteCity, addCity, editCity } from '../../actions/cityAction';
import { setCoordinates } from '../../actions/mapAction';
import { Grid, Table, Button, Icon, Confirm, Modal, Input, Dimmer, Loader } from 'semantic-ui-react';
import MapComponent from './MapComponent';

class CityList extends Component {
    state = {
        cityId: '',
        cityName: '',
        lat: '',
        lng: '',
        coordinates: this.props.coordinates,
        cityList: [],
        open: false,
        modalOpen: false,
        reqtype: ''
    };

    componentDidMount(){
        this.props.getCityList('/api/v1/cities');
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if(prevProps.cityList !== this.props.cityList){
            this.setState({cityList: this.props.cityList})
        }

        if(prevProps.coordinates !== this.props.coordinates){
            // console.log(this.props.coordinates);
            this.setState({coordinates: this.props.coordinates})
        }
    }

    onChange = (e) => {
        const {value, name} = e.target;
        // console.log(value, name);
        this.setState({
            [name]: value
        });
    }

    //calls confirmation window for deleting the city
    handelConfirmation(cityId) {
        this.setState({ open: !this.state.open, cityId: cityId });
    }

    //add a city
    addCity(){
        var data = {
            name: this.state.cityName,
            latitude: this.props.coordinates.lat,
            longitude: this.props.coordinates.lng
        }

        if(this.state.cityName === ''){
            alert('Please, type a city name.')
        }
        else{
            this.props.addCity('/api/v1/cities', data)
            .then(res => {
                if(res){
                    if(res.statusText === "Created"){
                        this.setState({modalOpen: false});
                    }
                }
            })
        }
    }

    //delete the city by cityId
    deleteCity(id){
        this.props.deleteCity('/api/v1/cities/' + id, id)
        .then(res => {
            if(res){
                if(res.status === 200){
                    this.setState({open: false});
                }
            }
        })
    }

    //opens the Modal for editing
    handleEditCity(id, name, lat, lng){
        const coor = {
            lat,
            lng
        }
        // console.log(id);
        this.setState({cityId: id, cityName: name});
        this.props.setCoordinates(coor);
        this.handleModalToggle('edit');
    }

    //this function for editing the city info
    editCity(){
        const data = {
            name: this.state.cityName,
            latitude: this.props.coordinates.lat,
            longitude: this.props.coordinates.lng 
        }

        this.props.editCity('/api/v1/cities/' + this.state.cityId, data, this.state.cityId)
        .then(res => {
            if(res){
                if(res.status === 200){
                    const coor = {};
                    //clear the coordinates
                    this.props.setCoordinates(coor);
                    //close the Modal and clear the cityName 
                    this.setState({modalOpen: false, cityName: ''});
                }
            }
        });
    }

    handleModalToggle = (reqtype) => {
        this.setState({ modalOpen: !this.state.modalOpen, reqtype });
    }

    render() {
        const { cityList, modalOpen, cityName, lat, lng, reqtype } = this.state;
        return (
            <Grid style={{margin: '0'}}>
                <Grid.Row  columns={1}>
                    <Grid.Column>
                        <Button color='teal' floated='right' onClick={this.handleModalToggle.bind(this, 'add')}>
                            <Icon name='add' />
                            Add new
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Table celled striped color='blue'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>
                                        City Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>  
                                        Latitude
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Longitude
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {cityList.length > 0 &&
                                <Table.Body>
                                    {cityList.map((city, index) => (
                                        <Table.Row key={city.id}>
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell>{city.name}</Table.Cell>
                                            <Table.Cell>{city.lat}</Table.Cell>
                                            <Table.Cell>{city.long}</Table.Cell>
                                            <Table.Cell className='tableActions'>
                                                <Button className='rowEditBtn' compact color='yellow'
                                                        onClick={this.handleEditCity.bind(this, city.id, city.name, city.lat, city.long)}>
                                                    <Icon name='pencil' />
                                                    Edit
                                                </Button>
                                                <Button className='rowDeleteBtn' compact color='red'
                                                        onClick={this.handelConfirmation.bind(this, city.id)}>
                                                    <Icon name='trash' />
                                                    Delete
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))} 
                                </Table.Body>
                            }
                            {!cityList.length > 0 &&
                                <Table.Body> 
                                    <Table.Row> 
                                        <Table.Cell style={{textAlign: 'center'}} colSpan='12'>Пусто</Table.Cell>
                                    </Table.Row> 
                                </Table.Body>
                            }
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Confirm 
                    content='Are you sure, you want to delete this city?'
                    open={this.state.open} 
                    onCancel={this.handelConfirmation.bind(this, '')} 
                    onConfirm={this.deleteCity.bind(this, this.state.cityId)} 
                />
                <Modal
                    centered={false}
                    open={modalOpen}
                    onClose={this.close}
                >
                    <Modal.Content>
                        <Grid>
                            <Grid.Row width={16}>
                                <Input 
                                    placeholder="type a city name"
                                    value={cityName}
                                    name="cityName"
                                    onChange={this.onChange}
                                />
                            </Grid.Row>
                            <Grid.Row width={16}>
                                <MapComponent lat={lat} lng={lng} />
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='orange' onClick={this.handleModalToggle}>
                            <Icon name='cancel' /> Cancel
                        </Button>
                        <Button color='green' 
                                onClick={reqtype === 'add' ? this.addCity.bind(this) : this.editCity.bind(this)}>
                            <Icon name={reqtype === 'add' ? 'checkmark' : 'edit'}/> 
                                {reqtype === 'add' ? 'Add' : 'Edit'}
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
            </Grid>
        );
    }
}

CityList.propTypes = {
    getCityList: PropTypes.func,
    cityList: PropTypes.array,
    requestInProcess: PropTypes.bool,
    deleteCity: PropTypes.func,
    setCoordinates: PropTypes.func,
    coordinates: PropTypes.object,
    addCity: PropTypes.func,
    editCity: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCityList: (url) => dispatch(getCityList(url)),
        deleteCity: (url, id) => dispatch(deleteCity(url, id)),
        setCoordinates: (coor) => dispatch(setCoordinates(coor)),
        addCity: (url, data) => dispatch(addCity(url, data)),
        editCity: (url, data, cityId) => dispatch(editCity(url, data, cityId))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        cityList: state.cityList,
        coordinates: state.coordinates
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CityList);
import React from 'react';
import {Grid, Button} from 'semantic-ui-react';

import CrossMarketing from "../stateful/CrossMarketing";

const CrossMarketingData = (props) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    <Button compact style={{marginBottom: '10px'}}
                            color={props.crossMarketingAvailable ? 'red' : 'green'}
                            onClick={props.crossMarketingAvailable ? () => props.activateDeactivateCrossMarketing(false) : () => props.activateDeactivateCrossMarketing(true)}>
                        {props.crossMarketingAvailable ? 'Deactivate Cross Marketing' : 'Activate Cross Marketing'}
                    </Button>
                </Grid.Column>
            </Grid.Row>
            {props.crossMarketingAvailable &&
                <CrossMarketing branchId={props.branchId} />
            }
        </Grid>
    )
};
export default CrossMarketingData;
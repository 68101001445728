import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCityList } from '../../actions/cityAction';
import { getDropdownBranchList } from '../../actions/branchAction';
import { getOrderList, emptyOrderList, revertTransaction } from '../../actions/orderAction';
import { Grid, Header, Button, Icon, Dimmer, Loader, Modal, Label, Divider, Confirm, Form, Dropdown } from 'semantic-ui-react';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import { getParsedDateTime } from '../../services/common';
import { isEmpty } from 'lodash';
import DatePickerBasic from '../helpers/DatePicker';

class OrderList extends Component {
    state = {
        orderList: [],
        hasMore: true,
        orderId: '',
        selectedOrder: {},
        cityId: '1',
        query: '',
        branch_id: '',
        from: '',
        to: '',
        dateFrom: '',
        dateTo: '',
        //for Modal
        open: false,
        //for Comfirm
        confirmOpen: false,
        height: 512
    };

    componentDidMount() {
        this.updateWindowDimensions();
        this.props.getCityList('/api/v1/cities');
        this.getOrders('scroll');
        this.getBranches();
    }

    updateWindowDimensions() {
        this.setState({ height: window.outerHeight - 230 });
    }

    getBranches = () => {
        const { cityId, query } = this.state;
        this.props.getDropdownBranchList('/api/v1/branches?limit=100&city_id=' + cityId + query);
    }

    getOrders = (type) => {
        const { branch_id, dateFrom, dateTo } = this.state;
        this.props.getOrderList('/api/v1/orders?limit=20' + branch_id + dateFrom + dateTo, type).then(res => {
            if (res) {
                if (res.status === 200) {
                    const arrLength = res.data.data.length;
                    if (arrLength < 20) this.setState({ hasMore: false });
                    else this.setState({ hasMore: true });
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if (prevProps.orderList !== this.props.orderList) {
            this.setState({ orderList: this.props.orderList });
        }

        if (prevProps.cityList !== this.props.cityList) {
            var cityListOptions = [];
            this.props.cityList.forEach(el => {
                const obj = {
                    key: el.id,
                    value: el.id.toString(),
                    text: el.name
                };
                cityListOptions.push(obj);
            });
            this.setState({ cityListOptions });
        }

        if (prevProps.dropdownBranchList !== this.props.dropdownBranchList) {
            this.setState({ branchListOptions: this.props.dropdownBranchList });
        }
    }

    componentWillUnmount() {
        //need to empty the array from reducer when leaving the component
        this.props.emptyOrderList();
    }

    onSelectChange = (e, data) => {
        const { name, value } = data;

        if (name === 'cityId') {
            this.setState({ branchId: '', [name]: value }, () => this.getBranches());
        } else if (name === 'branchId') {
            this.setState({ branch_id: !isEmpty(String(value)) ? `&branch_id=${value}` : '' }, () => {
                this.props.emptyOrderList();
                this.getOrders('filter');
            });
        }

        this.setState({
            [name]: value
        });
    }

    onSearchChange = (e, value) => {
        this.setState({ query: `&query=${value.searchQuery}` }, () => this.getBranches());
    }

    onDateChange = (e, data) => {
        const { name, value } = data;
        const date = moment(value).format("DD-MM-YYYY");
        if (name === "from") {
            this.setState({ dateFrom: value ? `&from=` + date : '' }, () => {
                this.getOrders('filter');
            });
        } else if (name === "to") {
            this.setState({ dateTo: value ? `&to=` + date : '' }, () => {
                this.getOrders('filter');
            });
        }
        this.setState({ [name]: value });
    }

    filterDate = () => {
        return true;
    };

    //reverts the transaction
    revertTransaction = () => {
        const { orderId } = this.state;
        this.props.revertTransaction('/api/v1/orders/' + orderId + '/revert-transaction', orderId)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ confirmOpen: false });
                }
            });
    }

    showHideConfirm = (open, orderId) => this.setState({ confirmOpen: open, orderId });

    openCloseOrderDetails = (orderId) => {
        var foundOrder = {};
        if (orderId !== '') {
            const { orderList } = this.props;
            foundOrder = orderList.find(order => order.id === orderId);
        }
        this.setState({ selectedOrder: foundOrder }, function () {
            this.setState({ open: !this.state.open });
        });
    }

    //load more orderes
    fetchMoreData = () => {
        const { branch_id, dateFrom, dateTo } = this.state;
        this.props.getOrderList('/api/v1/orders?limit=20' + branch_id + dateFrom + dateTo + '&before=' + this.props.lastItemId, 'scroll')
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        var arrLength = res.data.data.length;
                        if (arrLength === 0) {
                            this.setState({ hasMore: false });
                            return;
                        }
                    }
                }
            });
    };

    render() {
        const { orderList, selectedOrder, open, confirmOpen, orderId, height,
            cityListOptions, cityId, branchListOptions, branchId, from, to } = this.state;
        const orderStatus = selectedOrder.status && selectedOrder.status !== "DECLINED" ? true : false;

        return (
            <div style={{ paddingLeft: '5px', height: '100%' }}>
                <Grid style={{ margin: '0' }}>
                    <Grid.Row columns='equal'>
                        <Grid.Column></Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h3'>Order List </Header>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column textAlign='center'>
                            <Form.Select name='cityId'
                                options={cityListOptions || []}
                                label='Город:'
                                value={cityId || ''}
                                onChange={this.onSelectChange}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <span>
                                Филиал:
                                <Dropdown style={{ marginLeft: 5 }}
                                    search placeholder='Выберите филиал...' closeOnChange
                                    options={branchListOptions || []} selection
                                    name="branchId" value={branchId || ''}
                                    onChange={this.onSelectChange} clearable
                                    onSearchChange={this.onSearchChange}
                                />
                            </span>
                        </Grid.Column>
                        <Grid.Column>
                            <DatePickerBasic
                                name="from"
                                value={from}
                                onDateChange={this.onDateChange}
                                filterDate={this.filterDate}
                            />
                            <DatePickerBasic
                                name="to"
                                value={to}
                                onDateChange={this.onDateChange}
                                filterDate={this.filterDate}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1} color='blue'>№</Grid.Column>
                        <Grid.Column width={3} color='blue'>
                            Branch Name
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>
                            Address
                        </Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Order amount
                        </Grid.Column>
                        <Grid.Column width={3} color='blue'>
                            Order date | time
                        </Grid.Column>
                        {/* <Grid.Column width={2} color='blue'>
                            Order status
                        </Grid.Column> */}
                        <Grid.Column width={3} color='blue'>
                            Action
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <InfiniteScroll
                    dataLength={orderList.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<h4>Loading...</h4>}
                    height={height}
                    endMessage={null}
                >
                    <Grid celled style={{ marginTop: '0' }}>
                        {orderList.map((order, index) => (
                            <Grid.Row key={order.id} verticalAlign='middle'>
                                <Grid.Column width={1}>{index + 1}</Grid.Column>
                                <Grid.Column width={3}>
                                    {order.recipient.branch_name}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    {order.recipient.branch_address}
                                </Grid.Column>
                                <Grid.Column width={2}
                                    style={order.order_receiver_amount < 0 ? { color: 'red' } : { color: 'green' }}>
                                    {order.order_receiver_amount < 0 ?
                                        order.order_receiver_amount * -1 :
                                        Number(order.order_receiver_amount) % 1 !== 0 ?
                                            Number(order.order_receiver_amount).toFixed(2) :
                                            order.order_receiver_amount} tg.
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    {getParsedDateTime(moment(new Date(order.created_at)).format())}
                                </Grid.Column>
                                {/* <Grid.Column width={2}
                                             style={order.status === 'DECLINED' ? {color: 'red'} : order.status === 'COMPLETED' ? {color: 'green'} : {color: 'grey'}}>
                                    {order.status}
                                </Grid.Column> */}
                                <Grid.Column width={3} textAlign='center'>
                                    <Button size='mini' inverted color='blue'
                                        disabled={order.status === 'DECLINED'}
                                        onClick={() => this.showHideConfirm(true, order.id)}>
                                        <Icon name='undo' />
                                        Return
                                    </Button>
                                    <Button size='mini' inverted color='orange'
                                        onClick={() => this.openCloseOrderDetails(order.id)}>
                                        <Icon name='eye' />
                                        View
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        ))}
                    </Grid>
                </InfiniteScroll>
                <Modal
                    centered={false} size='small'
                    open={open}
                    onClose={this.openCloseOrderDetails.bind(this, '')}
                >
                    <Modal.Content>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width='8' style={{ color: 'grey' }}>
                                    Created date: {getParsedDateTime(moment(new Date(selectedOrder.created_at)).format())}
                                </Grid.Column>
                                <Grid.Column width='8' textAlign='right' style={{ color: 'grey' }}>
                                    Order number: {selectedOrder.order_number}
                                </Grid.Column>
                                <Grid.Column width='8' style={{ marginTop: '10px' }}>
                                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        {selectedOrder.sender ? selectedOrder.sender.first_name : ''} {selectedOrder.sender ? selectedOrder.sender.last_name : ''}
                                    </span>
                                </Grid.Column>
                                <Grid.Column width='8' textAlign='right' style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        {selectedOrder.sender ? selectedOrder.sender.phone_mask : ''}
                                    </span>
                                </Grid.Column>
                                <Grid.Column width='16' style={{ marginTop: '10px' }}>
                                    <Label>Order type: {selectedOrder.type}</Label>
                                </Grid.Column>
                                <Grid.Column width='16' style={{ marginTop: '10px' }}>
                                    <Header as='h4' style={{ color: 'grey' }}>Message:</Header>
                                    <p>{selectedOrder.comment}</p>
                                </Grid.Column>
                                <Grid.Column width='8' style={{ marginTop: '10px' }}>
                                    <Header as='h2'>Total amount</Header>
                                </Grid.Column>
                                <Grid.Column width='8' textAlign='right' style={{ marginTop: '10px' }}>
                                    <Header as='h2' style={{ color: 'green' }}>{selectedOrder.order_total_amount}</Header>
                                </Grid.Column>
                                <Grid.Column width='16' style={{ marginTop: '10px' }}>
                                    <Divider />
                                </Grid.Column>
                                <Grid.Column width='4' style={{ marginTop: '10px' }}>
                                    <Header as='h3' style={{ color: 'grey' }}>
                                        Cashback {selectedOrder.rule ? selectedOrder.rule.cashback_percentage : ''}%
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width='4' style={{ marginTop: '10px' }}>
                                    <Header as='h3' style={{ color: 'grey' }}>
                                        Commission {selectedOrder.rule ? selectedOrder.rule.commission_percentage : ''}%
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width='8' style={{ marginTop: '10px' }} textAlign='right'>
                                    <Header as='h3' style={{ color: 'grey' }}>
                                        Total
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width='4' style={{ marginTop: '10px' }}>
                                    <Header as='h2'>
                                        {selectedOrder.order_cashback_amount}
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width='4' style={{ marginTop: '10px' }}>
                                    <Header as='h2'>
                                        {selectedOrder.order_commission_amount}
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width='8' style={{ marginTop: '10px' }} textAlign='right'>
                                    <Header as='h2'>
                                        {selectedOrder.order_receiver_amount}
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width='9' style={{ marginTop: '10px' }} textAlign='right'>
                                    {orderStatus &&
                                        <Button primary size='small' onClick={() => this.showHideConfirm(true, selectedOrder.id)}>
                                            Return
                                        </Button>
                                    }
                                </Grid.Column>
                                <Grid.Column width='7' style={{ marginTop: '10px' }} textAlign='right'>
                                    <Button color='orange' size='small' onClick={this.openCloseOrderDetails.bind(this, '')}>
                                        Close
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                </Modal>
                <Confirm
                    open={confirmOpen}
                    content='Вы уверены что хотите вернуть?'
                    cancelButton='Отменить'
                    confirmButton="Да"
                    onCancel={() => this.showHideConfirm(false, '')}
                    onConfirm={() => this.revertTransaction(orderId)}
                />
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
            </div>
        );
    }
}

OrderList.propTypes = {
    getCityList: PropTypes.func,
    cityList: PropTypes.array,
    getDropdownBranchList: PropTypes.func,
    dropdownBranchList: PropTypes.array,
    getOrderList: PropTypes.func,
    orderList: PropTypes.array,
    requestInProcess: PropTypes.bool,
    lastItemId: PropTypes.string,
    emptyOrderList: PropTypes.func,
    revertTransaction: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCityList: (url) => dispatch(getCityList(url)),
        getDropdownBranchList: (url) => dispatch(getDropdownBranchList(url)),
        getOrderList: (url, type) => dispatch(getOrderList(url, type)),
        emptyOrderList: () => dispatch(emptyOrderList()),
        revertTransaction: (url, orderId) => dispatch(revertTransaction(url, orderId))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        cityList: state.cityList,
        dropdownBranchList: state.dropdownBranchList,
        orderList: state.orderList,
        lastItemId: state.lastItemId
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
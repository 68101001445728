import React from 'react'
import { Message, Icon } from 'semantic-ui-react'
import { isEmpty } from '../../services/common';

const MessageComponent = (props) => {
  const { positive, hidden, messageTitle, messageBody } = props;

  return (
    <Message positive={positive} negative={!positive} hidden={hidden} floating style={{ padding: '5px' }}>
      {!isEmpty(messageTitle) && <Message.Header>{messageTitle}</Message.Header>}
      <p>
        <Icon name={positive ? 'check' : 'info'} /> {messageBody}
      </p>
    </Message>
  )
}

export default MessageComponent;
import React, { Fragment } from 'react'
import { Grid, Header, Form, Icon, Table, Input, Dimmer, Loader } from 'semantic-ui-react'

const StatisticData = (props) => {
  const {
    totalUsers, totalTransactionCount, totalTransactionAmount, sortingValue,
    sortTableByColumn, cityId, cityListOptions, numberWithCommas,
    clientCount, transactionCount, transactionAmount, clearDates,
    isTotalUsersLoaded, isTotalTransactionsLoaded, isTotalTransactionAmountLoaded,
    isBranchTransactionStatsLoaded, isLoyalClientStatsLoaded
  } = props;

  return (
    <Grid style={{ margin: '0' }}>
      <Grid.Row columns={1}>
        <Grid.Column textAlign='center'>
          <Header as='h1'>Statistics with some filters</Header>
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10, marginBottom: 20 }}>
            <Form.Field inline>
              <label>from:</label>
              <Input onChange={props.onChange} type='date' name='startDate' value={props.startDate} />
            </Form.Field>
            <Form.Field inline>
              <label>up to:</label>
              <Input onChange={props.onChange} type='date' name='endDate' value={props.endDate} />
            </Form.Field>
            <Form.Select name='cityId'
              options={cityListOptions}
              label='Город:'
              value={cityId || ''}
              onChange={props.onSelectChange}
            />
            <Form.Button type='button' onClick={clearDates}>Clear filter</Form.Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      <div style={{ display: "flex", gap: "20px", width: "100%", justifyContent: "space-around", fontFamily: "Verdana" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div style={{ display: "flex", gap: "30px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
              {isTotalUsersLoaded ?
                <Fragment>
                  <span style={{ fontSize: "44px", color: "#41ad41" }}>{numberWithCommas(totalUsers)}</span>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>Total users</span>
                </Fragment> :
                <Dimmer active={!isTotalUsersLoaded} inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
              }
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
              {isTotalTransactionsLoaded ?
                <Fragment>
                  <span style={{ fontSize: "44px", color: "#41ad41" }}>{numberWithCommas(totalTransactionCount)}</span>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>Total completed Transactions</span>
                </Fragment> :
                <Dimmer active={!isTotalTransactionsLoaded} inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
              }
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
            {isTotalTransactionAmountLoaded ?
              <Fragment>
                <span style={{ fontSize: "44px", color: "#41ad41" }}>{numberWithCommas(totalTransactionAmount)}</span>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>Total Transactions amount</span>
              </Fragment> :
              <Dimmer active={!isTotalTransactionAmountLoaded} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>
            }
          </div>
        </div>
        {isLoyalClientStatsLoaded ?
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", gap: "30px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
                <span style={{ fontSize: "44px", color: "#41ad41" }}>{numberWithCommas(clientCount)}</span>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>Лояльные клиенты</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
                <span style={{ fontSize: "44px", color: "#41ad41" }}>{numberWithCommas(transactionCount)}</span>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>Кол-во повторных покупок</span>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
              <span style={{ fontSize: "44px", color: "#41ad41" }}>{numberWithCommas(transactionAmount)}</span>
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>Сумма покупок лояльных клиентов</span>
            </div>
          </div> :
          <Dimmer active={!isLoyalClientStatsLoaded} inverted>
            <Loader inverted content='Loading' />
          </Dimmer>
        }
      </div>
      <Grid.Row columns={1}>
        {isBranchTransactionStatsLoaded ?
          <Grid.Column>
            <Table celled striped color='blue'>
              <Table.Header style={{ fontSize: '16px' }}>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    Branch
                    <Icon
                      name="sort"
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortTableByColumn(Math.abs(sortingValue) === 5 ? sortingValue * -1 : 5)}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Transactions made
                    <Icon
                      name="sort"
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortTableByColumn(Math.abs(sortingValue) === 1 ? sortingValue * -1 : -1)}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Transactions amount
                    <Icon
                      name="sort"
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortTableByColumn(Math.abs(sortingValue) === 2 ? sortingValue * -1 : -2)}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Cashbacks
                    <Icon
                      name="sort"
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortTableByColumn(Math.abs(sortingValue) === 3 ? sortingValue * -1 : -3)}
                    />
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell>
                                    Branch received
                                    <Icon
                                        name="sort"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => sortTableByColumn(Math.abs(sortingValue) === 4 ? sortingValue * -1 : -4)}
                                    />
                                </Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>

              {props.branchTransactionStatsList.length > 0 ?
                <Table.Body>
                  {props.branchTransactionStatsList.map((item, index) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        <span
                          style={{ cursor: 'pointer', fontWeight: 'bold' }}
                          onClick={() => props.handleModalToggle(item.id)}
                        >
                          {item.branch_name}
                        </span>
                      </Table.Cell>
                      <Table.Cell>{numberWithCommas(item.transactions)}</Table.Cell>
                      <Table.Cell>{numberWithCommas(item.amount)}</Table.Cell>
                      <Table.Cell>{numberWithCommas(item.cashback_amount)}</Table.Cell>
                      {/* <Table.Cell>{numberWithCommas(item.receiver_amount)}</Table.Cell> */}
                    </Table.Row>
                  ))}
                </Table.Body> :
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ textAlign: 'center' }} colSpan='12'>Пусто</Table.Cell>
                  </Table.Row>
                </Table.Body>
              }
            </Table>
          </Grid.Column> :
          <Dimmer active={!isTotalUsersLoaded} inverted>
            <Loader inverted content='Loading' />
          </Dimmer>
        }
      </Grid.Row>
    </Grid >
  )
}

export default StatisticData;
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Table, Button, Select } from "semantic-ui-react";

import { dynamicGetMethod } from '../../actions/branchAction';
import { getCategoryList } from '../../actions/categoryAction';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";

class CrossMarketing extends Component {
    state = {
        crossMarketingList: [],
        total_clients: "",
        new_clients: "",
        loyal_clients: "",
        activePage: 1,
        boundaryRange: 1,
        siblingRange: 1,
        showEllipsis: true,
        showFirstAndLastNav: true,
        showPreviousAndNextNav: true,
        totalPages: 1,
        cityId: sessionStorage.getItem('cityId') ? sessionStorage.getItem('cityId') : '1',
        categoryId: null,
        from: "",
        to: "",
        categoryListOptions: [],
    }

    componentDidMount() {
        const { cityId } = this.state;
        this.loadData();
        this.props.getCategoryList('/api/v1/categories?city_id=' + cityId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.categoryList !== this.props.categoryList) {
            const { categoryList } = this.props;
            let categoryListOptions = [];
            categoryList.forEach(element => {
                var obj = {
                    key: element.id,
                    value: element.id,
                    text: element.name
                }
                categoryListOptions.push(obj);
            });
            this.setState({ categoryListOptions });
        }
    }

    loadData = () => {
        const { branchId } = this.props;
        const { activePage, categoryId, from, to } = this.state;
        const skip = (activePage - 1) * 20;
        const categoryFilter = categoryId ? `&category_id=${categoryId}` : '';
        const fromFilter = from ? `&from=${from}` : '';
        const toFilter = to ? `&to=${to}` : '';
        this.props.dynamicGetMethod(`/api/v1/branches/${branchId}/cross-marketing/analytic?skip=${skip}${categoryFilter}${fromFilter}${toFilter}`)
            .then(result => {
                if (result) {
                    if (result.status === 200) {
                        // console.log(result.data.data);
                        const { results, total } = result.data.data;
                        const allItems = results.count;
                        this.setState({
                            crossMarketingList: results.branches ? results.branches : [],
                            total_clients: total.total_clients_count,
                            new_clients: total.new_clients_count,
                            loyal_clients: total.loyalty_clients_count,
                            totalPages: Math.ceil(allItems / 20)
                        });
                    }
                }
            });
    }

    onCategoryChange = (e, data) => {
        const { name, value } = data;

        this.setState({
            [name]: value
        }, () => {
            this.loadData();
        });
    }

    handleDatesChange = (e, data) => {
        const { value } = data;
        if (value && value.length === 1) {
            this.setState({ 
                from: moment(value[0]).format("DD-MM-YYYY") 
            }, () => {
                this.loadData();
            });
        }
        else if (value && value.length === 2) {
            this.setState({ 
                to: moment(value[1]).format("DD-MM-YYYY") 
            }, () => {
                this.loadData();
            });
        } else {
            this.setState({ 
                from: null 
            }, () => {
                this.setState({
                    to: null
                }, () => {
                    this.loadData();
                })
            })
        }
    }

    render() {
        const { categoryId, categoryListOptions, crossMarketingList, total_clients, new_clients, loyal_clients } = this.state;

        return (
            <Grid.Row width='1'>
                <Grid.Column width={16}>
                    <Header as='h3'>Статистика клиентов</Header>
                </Grid.Column>
                <Grid.Column width={4} style={{ margin: "10px 0" }}>
                    <Select
                        style={{ width: '100%' }}
                        name='categoryId'
                        value={categoryId}
                        options={categoryListOptions}
                        onChange={this.onCategoryChange}
                        clearable
                        placeholder="Выберите категорию..."
                    />
                </Grid.Column>
                <Grid.Column width={4} style={{ margin: "10px 0" }}>
                    <SemanticDatepicker
                        style={{ width: '100%' }}
                        onChange={this.handleDatesChange}
                        type="range"
                        placeholder="Выберите период"
                        locale="ru-RU"
                        clearable
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <span>Итого кросс-клиентов: </span>
                    <span style={{ fontWeight: "bold" }}>
                        {total_clients}
                    </span>
                </Grid.Column>
                <Grid.Column width={16}>
                    <span>Новые: </span>
                    <span style={{ fontWeight: "bold", color: "green" }}>
                        {new_clients}
                    </span>
                </Grid.Column>
                <Grid.Column width={16}>
                    <span>Лояльные: </span>
                    <span style={{ fontWeight: "bold", color: "purple" }}>
                        {loyal_clients}
                    </span>
                </Grid.Column>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>№</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>
                                Наименование
                            </Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>
                                Категория
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign="center">
                                Бонусы
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='3' textAlign="center">
                                Кол-во клиентов
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='3' textAlign="center">
                                Выручка
                            </Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>
                                Город
                            </Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>
                                Инфо
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Нов.</Table.HeaderCell>
                            <Table.HeaderCell>Лояльн.</Table.HeaderCell>
                            <Table.HeaderCell>Нов.</Table.HeaderCell>
                            <Table.HeaderCell>Лояльн.</Table.HeaderCell>
                            <Table.HeaderCell>Всего</Table.HeaderCell>
                            <Table.HeaderCell>Нов.</Table.HeaderCell>
                            <Table.HeaderCell>Лояльн.</Table.HeaderCell>
                            <Table.HeaderCell>Общая выручка</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {crossMarketingList.length > 0 ?
                            crossMarketingList.map((cm, index) => {
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>{index + 1}</Table.Cell>
                                        <Table.Cell>{cm.branch_name}</Table.Cell>
                                        <Table.Cell>{cm.category_name}</Table.Cell>
                                        <Table.Cell>{cm.bonus_for_new_client}</Table.Cell>
                                        <Table.Cell>{cm.bonus_for_loyalty_client}</Table.Cell>
                                        <Table.Cell>{cm.new_clients_count}</Table.Cell>
                                        <Table.Cell>{cm.loyalty_clients_count}</Table.Cell>
                                        <Table.Cell>{cm.total_clients_count}</Table.Cell>
                                        <Table.Cell>{cm.new_clients_amount}</Table.Cell>
                                        <Table.Cell>{cm.loyalty_clients_amount}</Table.Cell>
                                        <Table.Cell>{cm.total_clients_amount}</Table.Cell>
                                        <Table.Cell>{cm.city_name}</Table.Cell>
                                        <Table.Cell>
                                            <Button compact color="blue">
                                                Подробнее
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }) :
                            <Table.Row>
                                <Table.Cell style={{ textAlign: 'center' }} colSpan='13'>Пусто</Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Grid.Row>
        );
    }
}

CrossMarketing.propTypes = {
    dynamicGetMethod: PropTypes.func,
    requestInProcess: PropTypes.bool,
    categoryList: PropTypes.array
};

const mapDispatchToProps = (dispatch) => {
    return {
        dynamicGetMethod: (url) => dispatch(dynamicGetMethod(url)),
        getCategoryList: (url) => dispatch(getCategoryList(url))
    };
};

const mapStateToProps = (state) => {
    return {
        categoryList: state.categoryList,
        requestInProcess: state.requestInProcess
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrossMarketing);
import axios from 'axios';
import { requestHasErrored, requestInProcess } from './commonAction';
import {timeDiff, getNewToken} from '../services/common';
var JSONbigString = require('json-bigint')({"storeAsString": true});

export function getCityList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data_1 => JSONbigString.parse(data_1) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(getCityListSuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getCityListSuccess(list) {
    return {
        type: 'GET_CITY_LIST_SUCCESS',
        list
    };
}

export function deleteCity(url, id) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(deleteCitySuccess(id));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function deleteCitySuccess(cityId) {
    return {
        type: 'DELETE_CITY_SUCCESS',
        cityId
    };
}

export function addCity(url, data) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.statusText === "Created") {
                // console.log(response);
                dispatch(addCitySuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function addCitySuccess(city) {
    return {
        type: 'ADD_CITY_SUCCESS',
        city
    };
}

export function editCity(url, data, cityId) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.put(url, data, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                const city = {
                    id: cityId,
                    name: data.name,
                    lat: data.latitude,
                    long: data.longitude
                };
                dispatch(editCitySuccess(city));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function editCitySuccess(city) {
    return {
        type: 'EDIT_CITY_SUCCESS',
        city
    };
}
export function cityList(state = [], action) {
    switch (action.type) {
        case 'GET_CITY_LIST_SUCCESS':
            return action.list;
        case 'DELETE_CITY_SUCCESS':
            return state.filter(item => item.id !== action.cityId);
        case 'ADD_CITY_SUCCESS':
            return [...state, action.city];
        case 'EDIT_CITY_SUCCESS':
            const index = state.findIndex(city => city.id === action.city.id);
            return [...state.slice(0, index), action.city, ...state.slice(index + 1)];
        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDealList, postDeal, putDeal, emptyDealList, handleRemove, updateDealDetails } from '../../actions/dealAction';
import { getBranchListForDropdown } from '../../actions/branchAction';
import { getCityList } from '../../actions/cityAction';
import { Grid, Header, Button, Icon, Image, Dimmer, Loader, Modal, Form, Confirm, Input } from 'semantic-ui-react';

class DealList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dealList: [],
            dealId: '',
            dimmerId: '',
            dealName: '',
            dealDescription: '',
            dealActive: '',
            branchId: '',
            type: '',
            url: '',
            branchListOptions: [],
            cityId: sessionStorage.getItem('cityId') ? sessionStorage.getItem('cityId') : '1',
            cityListOptions: [],
            modalOpen: false,
            confirmOpen: false,
            iconModalOpen: false,
            file: {},
            editIsOn: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.updateBanner = this.updateBanner.bind(this);
    }

    componentDidMount(){
        this.props.getCityList('/api/v1/cities');
        const { cityId } = this.state;
        this.props.getDealList(`/api/v1/deals?city_id=` + cityId);
        this.props.getBranchListForDropdown('/api/v1/branches');
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if(prevProps.dealList !== this.props.dealList){
            this.setState({dealList: this.props.dealList})
        }

        if(prevProps.branchList !== this.props.branchList){
            const { branchList } = this.props;
            var branchListOptions = [];
            branchList.forEach(element => {
                var obj = {
                    key: element.id,
                    value: element.id,
                    text: element.name
                }
                branchListOptions.push(obj);
            });
            this.setState({
                branchListOptions
            });
        }

        if(prevProps.cityList !== this.props.cityList){
            var cityListOptions = [];
            this.props.cityList.forEach(el => {
                const obj = {
                    key: el.id,
                    value: el.id.toString(),
                    text: el.name
                };
                cityListOptions.push(obj);
            });
            this.setState({cityListOptions});
        }
    }

    componentWillUnmount(){
        //need to empty the array from reducer when leaving the component
        this.props.emptyDealList();
        sessionStorage.removeItem('cityId');
    }

    onChange(e) {
        const value = e.target.value;
        const name = e.target.name;
        // console.log(value, name);
        this.setState({
            [name]: value
        });
    }

    onSelectChange = (e, data) => {
        const { name, value } = data;
        
        if(name === 'cityId'){
            sessionStorage.setItem('cityId', value);
            this.props.getDealList(`/api/v1/deals?city_id=` + value);
        }
        this.setState({
            [name]: value
        });
    }

    onSearchChange(e, value) {
        this.props.getBranchListForDropdown('/api/v1/branches?query=' + value.searchQuery);
    }

    onFileChange = (e) => {
        // console.log(e.target.files[0]);
        this.setState({file: e.target.files[0]});
    }

    //directs to the branch details page
    goToBranch(id){
        this.props.history.push('/branch/' + id);
    }

    //for toggling the Modal
    handleModalToggle = () => this.setState({ modalOpen: !this.state.modalOpen });
    handleEditToggle = (dealId) => {
        if(dealId !== ''){
            const selectedDeal = this.state.dealList.find(d => d.id === dealId);
            this.setState({
                dealName: selectedDeal.name,
                dealDescription: selectedDeal.description,
                dealId, 
                branchId: selectedDeal.branch_id,
                dealActive: selectedDeal.active
            });
        }
        else{
            this.setState({
                dealName: '',
                dealDescription: '',
                dealId: '',
                dealActive: ''
            });
        }
        this.setState({ editIsOn: !this.state.editIsOn });
    }
    //for toggling the Dimmer
    handleDimmerToggle = (id) => this.setState({ dimmerId: id, editIsOn: false }, function(){
        if(id !== '') this.setState({ dealId: id });
    });
    handleIconModalToggle = () => this.setState({ iconModalOpen: !this.state.iconModalOpen }, function(){
        //when cancel is pressed set file to an empty object
        if(!this.state.iconModalOpen)
            this.setState({ file: {} });
    })

    //creating new deal
    createDeal(){
        const { dealName, dealDescription, branchId, type, url } = this.state;
        const branch = {'branch_id': branchId};
        const socialNet = {'url': url};

        var data = {
          'name': dealName,
          'description': dealDescription,
          'branch_id': branchId,
          'active': true,
          'type': type
        };

        data.payload = type === 'BRANCH' ? branch : socialNet;

        this.props.postDeal('/api/v1/deals', data)
        .then(function(res) {
            if(res){
                this.setState({
                    modalOpen: false
                });
            }
            else{
                alert("Could not create a new deal, please try again!")
            }
        }.bind(this));
    }

    //editing the existed deal
    updateDeal(dealId, name, description, active){

        var data = new FormData();
        data.append('name', name);
        data.append('description', description);
        data.append('active', active);

        this.props.putDeal('/api/v1/deals/' + dealId, data, dealId)
        .then(function(res) {
            if(res){
                this.setState({
                    modalOpen: false,
                    dealName: '',
                    dealDescription: '',
                    dealActive: ''
                });
            }
            else{
                alert("Could not edit the deal, please try again!")
            }
        }.bind(this));
    }

    // calls confirmation window for deleting the deal
    handelConfirmation = (dealId) => {
        this.setState({ confirmOpen: !this.state.confirmOpen, dealId });
    }

    //removes the deal
    deleteDeal(dealId) {
        this.props.handleRemove('/api/v1/deals/' + dealId, dealId)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    confirmOpen: false
                });
            }
        }.bind(this),function(error){
            if(error){
                this.setState({
                    confirmOpen: false
                }, function(){
                    alert(error.data.message);
                });
            }
        }.bind(this));
    }

    //updates banner by deal id
    updateBanner(dealId) {
        const {file} = this.state;
        var data = new FormData();
        data.append('icon', file);
        this.props.updateDealDetails('/api/v1/deals/' + dealId + '/icon', data)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    iconModalOpen: false,
                    dealId: ''
                });
            }
        }.bind(this));
    }

    render() {
        const { dealList, modalOpen, dealId, dimmerId, dealName, dealDescription, 
                dealActive, editIsOn, iconModalOpen, branchId, 
                branchListOptions, confirmOpen, type, url, cityListOptions, cityId } = this.state;

        const content = (
            <div>
                <Button color='yellow' onClick={this.handleIconModalToggle}>Update</Button>
            </div>
        )

        const typeOptions = [
            {key: 0, value: 'BRANCH', text: 'branch'},
            {key: 1, value: 'URL', text: 'url'},
        ]
        
        return (
            <div style={{paddingLeft: '5px', height: '100%'}}>
                <Grid style={{margin: '0'}}>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Form.Select name='cityId'
                                options={cityListOptions} 
                                label='Город:'
                                value={cityId || ''}
                                onChange={this.onSelectChange}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h3'>Deal List</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Button color='teal' floated='right' compact
                                    onClick={this.handleModalToggle.bind(this)}>
                                <Icon name='add' />
                                Add new
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1} color='blue'>№</Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Banner
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>  
                            Deal Name
                        </Grid.Column>
                        <Grid.Column width={5} color='blue'>
                            Description
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>
                            Action
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid celled style={{marginTop: '0'}}>
                    {dealList.map(function(deal, index) {
                        const active = dimmerId === deal.id ? true : false;
                        return (
                            <Grid.Row key={deal.id} verticalAlign='middle'>
                                <Grid.Column width={1}>{index + 1}</Grid.Column>
                                <Grid.Column width={2} textAlign='center'>
                                    <Dimmer.Dimmable
                                        as={Image} className='deal-banner'
                                        dimmed={active} dimmer={{ active, content }}
                                        onMouseEnter={this.handleDimmerToggle.bind(this, deal.id)}
                                        onMouseLeave={this.handleDimmerToggle.bind(this, '')}
                                        verticalAlign='middle' centered
                                        src={deal.banner === '' ? require('../../assets/img/no_image.png') : deal.banner} 
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    {editIsOn && dealId === deal.id ?
                                        <Form.Input 
                                            fluid  required
                                            value={dealName}
                                            name="dealName"
                                            onChange={this.onChange}
                                        /> :
                                        deal.name
                                    }
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    {editIsOn && dealId === deal.id ?
                                        <Form.TextArea 
                                            style={{width: '100%'}}
                                            rows='5' required
                                            value={dealDescription}
                                            name="dealDescription"
                                            onChange={this.onChange}
                                        /> :
                                        deal.description
                                    }
                                </Grid.Column>
                                <Grid.Column width={4} className='tableActions'>
                                    {editIsOn && dealId === deal.id ?
                                        <React.Fragment>
                                            <Button size='mini' inverted color='yellow'
                                                    onClick={this.handleEditToggle.bind(this, '')}>
                                                <Icon name='cancel' />
                                                Cancel
                                            </Button>
                                            <Button size='mini' inverted color='blue'
                                                    onClick={this.updateDeal.bind(this, deal.id, dealName, dealDescription, dealActive)}>
                                                <Icon name='edit' />
                                                Update
                                            </Button>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <Button size='mini' inverted color='blue'
                                                    onClick={this.goToBranch.bind(this, deal.branch_id)}>
                                                <Icon name='eye' />
                                                View
                                            </Button>
                                            <Button size='mini' inverted color='yellow'
                                                    onClick={this.handleEditToggle.bind(this, deal.id)}>
                                                <Icon name='edit' />
                                                Edit
                                            </Button>
                                            <Button size='mini' inverted color='red'
                                                    onClick={this.handelConfirmation.bind(this, deal.id)}>
                                                <Icon name='trash' />
                                                Delete
                                            </Button>
                                        </React.Fragment>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }.bind(this))}
                </Grid>
                <Modal
                    centered={false} size='mini'
                    open={modalOpen}
                    onClose={this.handleModalToggle.bind(this)}
                >
                    <Modal.Content>
                        <Form size='mini' onSubmit={this.createDeal.bind(this)}>
                            <Grid>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Form.Input 
                                            fluid label='Название' 
                                            placeholder='название...' required
                                            value={dealName}
                                            name="dealName"
                                            onChange={this.onChange}
                                        />
                                        <Form.TextArea 
                                            label='Описание' rows='5'
                                            placeholder='описание...' required
                                            value={dealDescription}
                                            name="dealDescription"
                                            onChange={this.onChange}
                                        />
                                        <Form.Select fluid required
                                            label='Type' name='type'
                                            options={typeOptions}
                                            placeholder='Select type...' value={type}
                                            onChange={this.onSelectChange}
                                        />
                                        {type === "URL" && 
                                            <Form.Input 
                                                fluid label='Social network' 
                                                placeholder='social net...' required
                                                value={url}
                                                name="url"
                                                onChange={this.onChange}
                                            />
                                        }
                                        <Form.Select fluid required
                                            label='Филиал' search name='branchId'
                                            options={branchListOptions}
                                            placeholder='Филиал...' value={branchId}
                                            onChange={this.onSelectChange}
                                            onSearchChange={this.onSearchChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Button color='green' size='mini'
                                                type='submit' floated='right'>
                                            <Icon name='add'/> 
                                                Create
                                        </Button>
                                        <Button color='orange' size='mini' 
                                                type='button' floated='right'
                                                onClick={this.handleModalToggle.bind(this)}>
                                            <Icon name='cancel' /> Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal
                    centered={false} size='mini'
                    open={iconModalOpen}
                    onClose={this.handleIconModalToggle}
                >
                    <Modal.Content>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Input 
                                        fluid type='file'
                                        onChange = {(e) => this.onFileChange(e)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row width={16}>
                                <Grid.Column>
                                    <Button color='green' size='mini'
                                            floated='right' onClick={this.updateBanner.bind(this, dealId)}>
                                        <Icon name='upload'/> 
                                            Upload
                                    </Button>
                                    <Button color='orange' size='mini' floated='right'
                                            onClick={this.handleIconModalToggle}>
                                        <Icon name='cancel' /> Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                </Modal>
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
                <Confirm
                    open={confirmOpen}
                    content='Вы действительно хотите удалить?'
                    cancelButton='Отмена'
                    confirmButton="Удалить"
                    onCancel={this.handelConfirmation.bind(this, '')}
                    onConfirm={this.deleteDeal.bind(this, dealId)}
                />
            </div>
        );
    }
}

DealList.propTypes = {
    getDealList: PropTypes.func,
    dealList: PropTypes.array,
    lastItemId: PropTypes.string,
    requestInProcess: PropTypes.bool,
    getBranchListForDropdown: PropTypes.func,
    branchList: PropTypes.array,
    postDeal: PropTypes.func,
    putDeal: PropTypes.func,
    emptyDealList: PropTypes.func,
    handleRemove: PropTypes.func,
    updateDealDetails: PropTypes.func,
    getCityList: PropTypes.func,
    cityList: PropTypes.array
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDealList: (url) => dispatch(getDealList(url)),
        getBranchListForDropdown: (url) => dispatch(getBranchListForDropdown(url)),
        postDeal: (url, data) => dispatch(postDeal(url, data)),
        putDeal: (url, data, dealId) => dispatch(putDeal(url, data, dealId)),
        emptyDealList: () => dispatch(emptyDealList()),
        handleRemove: (url, dealId) => dispatch(handleRemove(url, dealId)),
        updateDealDetails: (url, data) => dispatch(updateDealDetails(url, data)),
        getCityList: (url) => dispatch(getCityList(url))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        dealList: state.dealList,
        lastItemId: state.lastItemId,
        branchList: state.branchList,
        cityList: state.cityList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealList);

import _ from 'lodash';

export function categoryList(state = [], action) {
    switch (action.type) {
        case 'GET_CATEGORY_LIST_SUCCESS':
            return action.list;
        case 'ADD_CATEGORY_SUCCESS':
            return [...state, action.category];
        case 'DELETE_CATEGORY_SUCCESS':
            return state.filter(category => category.id !== action.categoryId); 
        case 'UPDATE_CATEGORY_SUCCESS':
            var index = state.findIndex(x => x.id === action.category.id);
            return [...state.slice(0, index), action.category, ...state.slice(index + 1)];
        default:
            return state;
    }
}

export function subCategoryList(state = [], action) {
    switch (action.type) {
        case 'GET_SUBCATEGORY_LIST_SUCCESS':
            return action.list;
        case 'ADD_SUBCATEGORY_SUCCESS':
            var index = state.findIndex(x => x.parent_id === action.parent_id);
            var newCategory= {
                parent_id: action.parent_id
            }
            if(_.isEmpty(action.category)){
                newCategory.list = [];
            }
            else{
                const newList = [...state[index].list, action.category];
                newCategory.list = newList;
            }
            return [...state.slice(0, index), newCategory, ...state.slice(index + 1)];
        case 'DELETE_SUBCATEGORY_SUCCESS':
            index = state.findIndex(x => x.parent_id === action.parent_id);
            var updatedList = state[index].list.filter(category => category.id !== action.categoryId);
            newCategory = {
                parent_id: action.parent_id,
                list: updatedList
            }
            return [...state.slice(0, index), newCategory, ...state.slice(index + 1)];
        case 'UPDATE_SUBCATEGORY_SUCCESS':
            index = state.findIndex(x => x.parent_id === action.parent_id);
            var indexOfSubCategory = state[index].list.findIndex(x => x.id === action.category.id);
            updatedList = [...state[index].list.slice(0, indexOfSubCategory), action.category, ...state[index].list.slice(indexOfSubCategory + 1)];
            newCategory = {
                parent_id: action.parent_id,
                list: updatedList
            }
            return [...state.slice(0, index), newCategory, ...state.slice(index + 1)];
        default:
            return state;
    }
}

//this is for Branch page
export function allcategoryList(state = [], action) {
  switch (action.type) {
      case 'GET_ALL_CATEGORY_LIST_SUCCESS':
          return action.list;
      default:
          return state;
  }
}
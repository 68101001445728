import React, { Fragment } from 'react';
import { Grid, Form, Header, Input, Button, Label, Message } from 'semantic-ui-react';
import BranchOneTimePassword from '../stateful/BranchOneTimePassword';

const autoPaymentOptions = [
    { key: 0, value: 604800000, text: 'Every week' },
    { key: 1, value: 2592000000, text: 'Every month' },
    { key: 2, value: 31556952000, text: 'Every year' }
]

const ContactData = (props) => {
    const { rule } = props;
    // if(Object.keys(rule).length === 0 && rule.constructor === Object)
    if (rule === undefined)
        return "Загрузка...";

    return (
        <React.Fragment>
            <Form onSubmit={props.updateContract}>
                <Grid style={props.parent === 'branch' ? { borderBottom: 'solid 1px lightgrey' } : null}>
                    <Grid.Row columns={1} style={{ marginTop: '10px' }}>
                        <Grid.Column textAlign='center'>
                            <Header as='h3' style={{ color: '#00b5ad' }}>Legal name:</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='center' style={{ marginTop: '10px' }}>
                            {props.editIsOn ?
                                <Input
                                    name='legal_name'
                                    value={props.legal_name}
                                    onChange={props.onChange}
                                /> :
                                rule.legal_name
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column textAlign='center'>
                            <Header as='h4' style={{ color: '#308ef3' }}>Rules</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h4' style={{ color: '#308ef3' }}>Requisites</Header>
                        </Grid.Column>
                    </Grid.Row>
                    {props.editIsOn ?
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Header as='h5'>Комиссия (qr): <span style={{ color: 'red' }}>*</span></Header>
                                            <Input
                                                type='number' required
                                                name='commission_percentage'
                                                value={props.commission_percentage}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Header as='h5'>Кэшбек (qr): <span style={{ color: 'red' }}>*</span></Header>
                                            <Input
                                                type='number' required
                                                name='cashback_percentage'
                                                value={props.cashback_percentage}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Комиссия (сертификат/меню): <span style={{ color: 'red' }}>*</span></Header>
                                            <Input
                                                type='number' required
                                                name='extra_commission_percentage'
                                                value={props.extra_commission_percentage}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Кэшбек (сертификат/меню): <span style={{ color: 'red' }}>*</span></Header>
                                            <Input
                                                type='number' required
                                                name='certificate_cashback_percentage'
                                                value={props.certificate_cashback_percentage}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Order Min Amount: <span style={{ color: 'red' }}>*</span></Header>
                                            <Input
                                                type='number' required
                                                name='order_min_amount'
                                                value={props.order_min_amount}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Order Max Amount: <span style={{ color: 'red' }}>*</span></Header>
                                            <Input
                                                type='number' required
                                                name='order_max_amount'
                                                value={props.order_max_amount}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Header as='h5'>Bank name:</Header>
                                            <Input
                                                name='bank_name'
                                                value={props.bank_name}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Header as='h5'>BIN:</Header>
                                            <Input
                                                name='bin'
                                                value={props.bin}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>BIK:</Header>
                                            <Input
                                                name='bik'
                                                value={props.bik}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>IIK:</Header>
                                            <Input
                                                name='iik'
                                                value={props.iik}
                                                onChange={props.onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row> :
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Header as='h5'>Комиссия (qr):</Header>
                                            <p>{rule.commission_percentage === 0 ? 'was not set' : rule.commission_percentage}</p>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Header as='h5'>Кэшбек (qr):</Header>
                                            <p>{rule.cashback_percentage === 0 ? 'was not set' : rule.cashback_percentage}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Комиссия (сертификат/меню):</Header>
                                            <p>{rule.extra_commission_percentage === 0 ? 'was not set' : rule.extra_commission_percentage}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Кэшбек (сертификат/меню):</Header>
                                            <p>{rule.certificate_cashback_percentage === 0 ? 'was not set' : rule.certificate_cashback_percentage}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Order Min Amount:</Header>
                                            <p>{rule.order_min_amount === 0 ? 'was not set' : rule.order_min_amount}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Order Max Amount:</Header>
                                            <p>{rule.order_max_amount === 0 ? 'was not set' : rule.order_max_amount}</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Header as='h5'>Bank name:</Header>
                                            <p>{rule.bank === "" ? 'was not set' : rule.bank}</p>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Header as='h5'>BIN:</Header>
                                            <p>{rule.bin === "" ? 'was not set' : rule.bin}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>BIK:</Header>
                                            <p>{rule.bik === "" ? 'was not set' : rule.bik}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>IIK:</Header>
                                            <p>{rule.iik === "" ? 'was not set' : rule.iik}</p>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginTop: '10px' }}>
                                            <Header as='h5'>Balance:</Header>
                                            <p>{props.branch.balance}</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    {props.parent === 'branch' &&
                        <Grid.Row>
                            <Grid.Column>
                                {props.editIsOn ?
                                    <Button.Group floated='right' compact>
                                        <Button onClick={props.toggleEdit} type='button'>
                                            Cancel
                                        </Button>
                                        <Button.Or text='|' />
                                        <Button positive type='submit'>
                                            Save
                                        </Button>
                                    </Button.Group> :
                                    <React.Fragment>
                                        {sessionStorage.getItem('role') === 'admin' &&
                                            <Button compact color='orange'
                                                floated='right' type='button'
                                                onClick={props.toggleEdit}>
                                                Edit
                                            </Button>}
                                    </React.Fragment>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </Form>
            {props.parent === 'branch' &&
                <Grid style={{ paddingTop: '10px' }}>
                    <Grid.Row style={{ borderBottom: 'solid 1px lightgrey' }} verticalAlign="middle">
                        <Grid.Column width={3}>
                            <Header as='h5'>Group id:</Header>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {props.editGroupId ?
                                <Input
                                    name='groupId'
                                    value={props.groupId}
                                    onChange={props.onChange}
                                    style={{ width: '100%' }}
                                /> :
                                <span style={{ display: 'block' }}>{props.groupId}</span>
                            }
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {props.editGroupId ?
                                <Button.Group size='mini'>
                                    <Button color='orange' type='button' onClick={props.editGroupIdToggle}>
                                        Cancel
                                    </Button>
                                    <Button.Or text='|' />
                                    <Button positive type='button' onClick={props.updateGroupId}>
                                        Update
                                    </Button>
                                </Button.Group> :
                                <Button size='mini'
                                    type='button' primary
                                    onClick={props.editGroupIdToggle}>
                                    Change group
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <BranchOneTimePassword />
                    <Grid.Row style={{ borderBottom: 'solid 1px lightgrey' }} verticalAlign="middle">
                        <Grid.Column width={3}>
                            <Header as='h5'>Categories:</Header>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {props.editCategory ?
                                <Fragment>
                                    <Form.Select fluid required
                                        label='Категория'
                                        options={props.categoryListOptions}
                                        name='categoryId'
                                        placeholder='Categories...'
                                        value={props.categoryId}
                                        onChange={props.onSelectChange}
                                    />
                                    <Form.Select fluid required
                                        label='Подкатегории'
                                        options={props.subCategOptions}
                                        multiple name='subcategory'
                                        placeholder='Sub categories...'
                                        value={props.subCategIdList}
                                        onChange={props.onSelectChange}
                                    />
                                </Fragment> :
                                <Fragment>
                                    {props.categories.length > 0 &&
                                        <div>
                                            {props.categories.map((category, index) => (
                                                <Label key={index} style={{ marginTop: '3px' }}
                                                    color={category.parentId === 0 ? 'green' : 'grey'}>
                                                    {category.name}
                                                </Label>
                                            ))}
                                        </div>
                                    }
                                </Fragment>
                            }
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {props.editCategory ?
                                <Button.Group size='mini'>
                                    <Button color='orange' type='button' onClick={props.editCategoryToggle}>
                                        Cancel
                                    </Button>
                                    <Button.Or text='|' />
                                    <Button positive type='button' onClick={props.updateBranchCategory}>
                                        Update
                                    </Button>
                                </Button.Group> :
                                <Button size='mini'
                                    type='button' primary
                                    onClick={props.editCategoryToggle}>
                                    Change categories
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ borderBottom: 'solid 1px lightgrey' }} verticalAlign="middle">
                        <Grid.Column width={3}>
                            <Header as='h5'>For Direct:</Header>
                        </Grid.Column>
                        <Grid.Column width={5} style={{ borderRight: '1px solid lightgray' }}>
                            {(props.directCurrentCustomFieldText === "" || props.directCustomFieldEditIsOn) ?
                                <Form size='mini' onSubmit={() => props.addCustomField("DIRECT")}>
                                    <Form.Group>
                                        <Form.TextArea
                                            placeholder='Custom field text'
                                            name='direct_custom_field' required
                                            value={props.direct_custom_field}
                                            onChange={props.onChange}
                                        />
                                        <Form.Button size='mini' primary content='Add custom field text' type='submit' />
                                    </Form.Group>
                                </Form> :
                                <Fragment>
                                    <span style={{ display: 'block' }}>{props.direct_custom_field}</span>
                                    <Button size='mini'
                                        type='button' color='orange'
                                        onClick={() => props.toggleEditCustomField('direct')}>
                                        Edit
                                    </Button>
                                </Fragment>
                            }
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Header as='h5'>For Online:</Header>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {(props.onlineCurrentCustomFieldText === "" || props.onlineCustomFieldEditIsOn) ?
                                <Form size='mini' onSubmit={() => props.addCustomField("ONLINE")}>
                                    <Form.Group>
                                        <Form.TextArea
                                            placeholder='Custom field text'
                                            name='online_custom_field' required
                                            value={props.online_custom_field}
                                            onChange={props.onChange}
                                        />
                                        <Form.Button size='mini' primary content='Add custom field text' type='submit' />
                                    </Form.Group>
                                </Form> :
                                <Fragment>
                                    <span style={{ display: 'block' }}>{props.online_custom_field}</span>
                                    <Button size='mini'
                                        type='button' color='orange'
                                        onClick={() => props.toggleEditCustomField('online')}>
                                        Edit
                                    </Button>
                                </Fragment>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ borderBottom: 'solid 1px lightgrey' }} verticalAlign="middle">
                        <Grid.Column width={3}>
                            <Header as='h5'>First purchase:</Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            {!props.elevatedCashbackAvailable ?
                                <Form size='mini'>
                                    <Form.Input
                                        label='Cashback percentage(%)' placeholder='50' type='number'
                                        name="firstPurchase" value={props.firstPurchase}
                                        onChange={props.onChange}
                                    />
                                </Form> :
                                <p>Cashback percentage: {props.firstPurchase}%</p>
                            }
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {!props.elevatedCashbackAvailable ?
                                <Button compact color="green" type="button"
                                    onClick={props.set_activateElevatedCashback}>
                                    Activate
                                </Button> :
                                <Button compact color="red" type="button"
                                    onClick={props.deactivateElevatedCashback}>
                                    Deactivate
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ borderBottom: 'solid 1px lightgrey' }} verticalAlign="middle">
                        <Grid.Column width={3}>
                            <Header as='h5'>Corporate cashback:</Header>
                        </Grid.Column>
                        <Grid.Column width={2} style={{ borderRight: '1px solid lightgray' }}>
                            {!props.corporateCashbackAvailable ?
                                <Button compact color='green' type='button'
                                    onClick={() => props.activateDeactivateCorporateCashback(true)}>
                                    Activate
                                </Button> :
                                <Button compact color="red" type="button"
                                    onClick={() => props.activateDeactivateCorporateCashback(false)}>
                                    Deactivate
                                </Button>
                            }
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Header as='h5'>Private cashback:</Header>
                        </Grid.Column>
                        <Grid.Column width={2} style={{ borderRight: '1px solid lightgray' }}>
                            {!props.privateCashbackAvailable ?
                                <Button compact color='green' type='button'
                                    onClick={() => props.activateDeactivatePrivateCashback(true)}>
                                    Activate
                                </Button> :
                                <Button compact color="red" type="button"
                                    onClick={() => props.activateDeactivatePrivateCashback(false)}>
                                    Deactivate
                                </Button>
                            }
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Header as='h5'>Cash Payment:</Header>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {!props.cashPaymentAvailable ?
                                <Button compact color="green" type="button"
                                    onClick={() => props.activateDeactivateCashPayment(true)}>
                                    Activate
                                </Button> :
                                <Button compact color="red" type="button"
                                    onClick={() => props.activateDeactivateCashPayment(false)}>
                                    Deactivate
                                </Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={3}>
                            <Header as='h5'>Balance top up:</Header>
                        </Grid.Column>
                        <Grid.Column width={2} style={{ borderRight: '1px solid lightgray' }}>
                            {props.cashPaymentAvailable ?
                                <Fragment>
                                    <Form size='mini'>
                                        <Form.Input
                                            label='Amount(₸)'
                                            placeholder='500'
                                            type='number'
                                            name="balance"
                                            value={props.balance}
                                            style={{ marginBottom: '5px' }}
                                            onChange={props.onChange}
                                            required
                                        />
                                    </Form>
                                    <Button
                                        compact
                                        color="green"
                                        type="button"
                                        onClick={props.topUpBalance}
                                        disabled={!props.balance}
                                    >
                                        Top up
                                    </Button>
                                    {props.topUpMsgVisible && <Message positive>
                                        <p>Top up was successful</p>
                                    </Message>}
                                </Fragment> :
                                <p>Balance top up is only available when cash payment is activated</p>
                            }
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Header as='h5'>Auto Payment:</Header>
                        </Grid.Column>
                        <Grid.Column width={3} style={{ borderRight: '1px solid lightgray' }}>
                            {props.autoPaymentEnabled ?
                                <Button compact color="red" type="button"
                                    onClick={() => props.activateDeactivateAutoPayment(false)}>
                                    Deactivate
                                </Button> :
                                <Fragment>
                                    <Form size='mini'>
                                        <Form.Select
                                            label='Interval' placeholder='Select...'
                                            name="autoPaymentInterval"
                                            value={props.autoPaymentInterval}
                                            options={autoPaymentOptions}
                                            style={{ marginBottom: '5px' }}
                                            onChange={props.onSelectChange}
                                            required
                                        />
                                    </Form>
                                    <Button
                                        compact style={{ marginBottom: '10px' }}
                                        color="green"
                                        type="button"
                                        onClick={() => props.activateDeactivateAutoPayment(true)}
                                        disabled={!props.autoPaymentInterval}
                                    >
                                        Activate
                                    </Button>
                                </Fragment>
                            }
                        </Grid.Column>

                        <Grid.Column width={2}>
                            <Header as='h5'>SMS:</Header>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {props.smsEnabled ?
                                <Button compact color="red" type="button"
                                    onClick={() => props.activateDeactivateSMS(false)}>
                                    Deactivate
                                </Button> :
                                <Fragment>
                                    <Form size='mini'>
                                        <Form.Input
                                            label='Limit'
                                            placeholder='0'
                                            type='number'
                                            name="smsLimit"
                                            value={props.limit}
                                            style={{ marginBottom: '5px' }}
                                            onChange={props.onChange}
                                        />
                                    </Form>
                                    <Button
                                        compact
                                        color="green"
                                        type="button"
                                        onClick={() => props.activateDeactivateSMS(true)}
                                    >
                                        Activate
                                    </Button>
                                </Fragment>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </React.Fragment>
    )
}
export default ContactData;
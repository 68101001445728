export function currentPage(state = '/', action) {
    switch (action.type) {
        case 'PAGE_HAS_CHANGED':
            return action.pageName;
        default:
            return state;
    }
}

export function requestHasErrored(state = false, action) {
    switch (action.type) {
        case 'REQUEST_HAS_ERRORED':
            return action.hasErrored;
        default:
            return state;
    }
}

export function requestInProcess(state = false, action) {
    switch (action.type) {
        case 'REQUEST_IN_PROCESS':
            return action.isLoading;
        default:
            return state;
    }
}

export function lastItemId(state = '', action) {
    switch (action.type) {
        case 'ITEM_ID_HAS_CHANGED':
            return action.itemId;
        default:
            return state;
    }
}
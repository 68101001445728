import axios from 'axios';
import { requestHasErrored, requestInProcess } from './commonAction';
import {timeDiff, getNewToken} from '../services/common';
var JSONbigString = require('json-bigint')({"storeAsString": true});

export function getSaleList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data_1 => JSONbigString.parse(data_1) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(getSaleListSuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getSaleListSuccess(list) {
    return {
        type: 'GET_SALE_LIST_SUCCESS',
        list
    };
}

export function deleteSale(url, id) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(deleteSaleSuccess(id));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function deleteSaleSuccess(saleId) {
    return {
        type: 'DELETE_SALE_SUCCESS',
        saleId
    };
}

export function createUpdateSale(url, data, type) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = type === "post" ? await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data_1 => JSONbigString.parse(data_1) }) :
                await axios.put(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data_1 => JSONbigString.parse(data_1) });
            if (type === "post" && response.status === 201) {
                dispatch(addSaleSuccess(response.data.data));
                dispatch(requestInProcess(false));
            } else if(type === "put" && response.status === 200) {
                dispatch(updateSaleSuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function addSaleSuccess(sale) {
    return {
        type: 'ADD_SALE_SUCCESS',
        sale
    };
}

export function updateSaleSuccess(sale) {
    return {
        type: 'UPDATE_SALE_SUCCESS',
        sale
    };
}
import React from 'react';
import { Grid, Input, Header, Button, Form, Label, Icon, Checkbox } from 'semantic-ui-react';
import MapComponent from '../stateful/MapComponent';

const WorkHourData = (props) => {
    const { workHourList, phoneList } = props;
    // if(Object.keys(rule).length === 0 && rule.constructor === Object)
    if(workHourList === undefined)
        return "Загрузка...";

    return (
        <Grid>
            <Grid.Row divided>
                <Grid.Column width={7}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Row centered><Header>Address:</Header></Grid.Row>
                            <Grid.Row>
                                {props.editIsOn ?
                                    <Grid.Column>
                                        <Grid>
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Input 
                                                        name='address' fluid
                                                        value={props.address}
                                                        onChange={props.onChange}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column style={{margin: '5px'}}>
                                                    <MapComponent lat={props.lat} lng={props.lon} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid> 
                                    </Grid.Column> :
                                    <Grid.Column>
                                        {props.address}
                                    </Grid.Column>
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid>
                            <Grid.Row centered><Header>Contacts:</Header></Grid.Row>
                            {phoneList.length > 0 &&
                                <Grid.Row>
                                    {phoneList.map(function(phone, index) {
                                        var num = index + 1;
                                        var phoneNum = props.phone1;
                                        switch(num) {
                                            case 1: phoneNum = props.phone1;
                                                break;
                                            case 2: phoneNum = props.phone2;
                                                break;
                                            case 3: phoneNum = props.phone3;
                                                break;
                                            default: phoneNum = props.phone1;
                                        }
                                        return (
                                            <React.Fragment key={index}>
                                                <Grid.Column width={4} verticalAlign='middle'>{`phone #${num}`}</Grid.Column>
                                                <Grid.Column width={props.editIsOn ? 10 : 12}>
                                                    {props.editIsOn ?
                                                        <Form>
                                                            <Form.Field size='mini'>
                                                                <input 
                                                                    type='text' placeholder='+77776665544'
                                                                    name={`phone${num}`} value={phoneNum}
                                                                    onChange={props.onChange}
                                                                />
                                                                {!props.phoneIsValid && props.phoneOrder === num &&
                                                                    <Label basic color='red' pointing>
                                                                        Format is incorrect
                                                                    </Label>
                                                                }
                                                            </Form.Field> 
                                                        </Form>: phone
                                                    }
                                                </Grid.Column>
                                                {props.editIsOn &&
                                                    <Grid.Column width={2} verticalAlign='middle'>
                                                        <Button basic color='red' size='mini' onClick={props.deletePhone.bind(this, index)}><Icon name='cancel'/></Button>
                                                    </Grid.Column>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </Grid.Row>
                            }
                            {props.editIsOn && props.phoneList.length < 3 &&
                                <Input type='text' placeholder='+77776665544' action size='mini'>
                                    <input name='phone' value={props.phone} onChange={props.onChange}/>
                                    {!props.phoneIsValid && props.phoneOrder === 0 &&
                                        <Label basic color='red' pointing='left'>
                                            Format is incorrect
                                        </Label>
                                    }
                                    <Button onClick={props.addPhone} primary>Add</Button>
                                </Input>
                            }
                        </Grid>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={9}>
                    <Grid>
                        <Grid.Row centered><Header>Working hours:</Header></Grid.Row>
                        <Grid.Row style={{padding: '0'}}>
                            <Grid.Column verticalAlign='middle' width={2}></Grid.Column>
                            <Grid.Column textAlign='center' width={5}></Grid.Column>
                            <Grid.Column textAlign='center' width={2}>allday</Grid.Column>
                            <Grid.Column textAlign='center' width={2}>dayoff</Grid.Column>
                            <Grid.Column textAlign='center' width={5}>lunch time</Grid.Column>
                        </Grid.Row>
                        {workHourList.length > 0 &&
                            <Grid.Row divided>
                                {workHourList.map(function(workHour) {
                                    var dayName = '';
                                    switch(workHour.day){
                                        case 1: 
                                            dayName = 'Monday'; break;
                                        case 2: 
                                            dayName = 'Tuesday'; break;
                                        case 3: 
                                            dayName = 'Wednesday'; break;
                                        case 4: 
                                            dayName = 'Thursday'; break;
                                        case 5: 
                                            dayName = 'Friday'; break;
                                        case 6: 
                                            dayName = 'Saturday'; break;
                                        case 7:
                                            dayName = 'Sunday';
                                            break;
                                        default: 
                                            dayName = 'no such day';
                                    };
                                    return (
                                        <React.Fragment key={workHour.day}>
                                            <Grid.Column verticalAlign='middle' width={2}>
                                                {dayName}
                                            </Grid.Column>
                                            <Grid.Column textAlign='center' verticalAlign='middle' width={5}>
                                                {props.editIsOn ?
                                                    <Form>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column width={7}>
                                                                    <Form.Field size='mini'>
                                                                        <input 
                                                                            type='text' placeholder='00:00'
                                                                            name={`workHourStart${workHour.day}`}
                                                                            value={workHour.start}
                                                                            onChange={props.onChange}
                                                                        />
                                                                        {!props.startIsValid && props.startWorkHourDay === workHour.day &&
                                                                            <Label basic color='red' pointing>
                                                                                Format is: "00:00"
                                                                            </Label>
                                                                        }
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column width={2} verticalAlign='middle'>-</Grid.Column>
                                                                <Grid.Column width={7}>
                                                                    <Form.Field size='mini'>
                                                                        <input 
                                                                            type='text' placeholder='00:00'
                                                                            name={`workHourEnd${workHour.day}`}
                                                                            value={workHour.end}
                                                                            onChange={props.onChange}
                                                                        />
                                                                        {!props.endIsValid && props.endWorkHourDay === workHour.day &&
                                                                            <Label basic color='red' pointing>
                                                                                Format is: "00:00"
                                                                            </Label>
                                                                        }
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Form> :
                                                    workHour.dayoff ? 'выходной' : workHour.allday ? 'круглосуточно' : workHour.start + ' - ' + workHour.end
                                                }
                                            </Grid.Column>
                                            <Grid.Column textAlign='center' width={2} verticalAlign='middle'>
                                                {props.editIsOn ?
                                                    <Form>
                                                        <Form.Field
                                                            control={Checkbox} 
                                                            name={`allday${workHour.day}`} 
                                                            checked={workHour.allday} 
                                                            onChange={props.onCheckboxChange}
                                                        />
                                                    </Form> :
                                                    workHour.allday ? 
                                                        <Icon color='green' name='check square' /> : 
                                                        <Icon color='red' name='cancel' />
                                                }
                                            </Grid.Column>
                                            <Grid.Column textAlign='center' width={2} verticalAlign='middle'>
                                                {props.editIsOn ?
                                                    <Form>
                                                        <Form.Field
                                                            control={Checkbox} 
                                                            name={`dayoff${workHour.day}`} 
                                                            checked={workHour.dayoff} 
                                                            onChange={props.onCheckboxChange}
                                                        />
                                                    </Form> :
                                                    workHour.dayoff ? 
                                                        <Icon color='green' name='check square' /> : 
                                                        <Icon color='red' name='cancel' />
                                                }
                                            </Grid.Column>
                                            <Grid.Column textAlign='center' width={5}>
                                                {props.editIsOn ?
                                                    <Form>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <Grid.Column width={7}>
                                                                    <Form.Field size='mini'>
                                                                        <input 
                                                                            type='text' placeholder='00:00'
                                                                            name={`lunchHourStart${workHour.day}`}
                                                                            value={workHour.lunch ? workHour.lunch.start : ''}
                                                                            onChange={props.onChange}
                                                                        />
                                                                        {!props.lunchStartIsValid && props.startLunchHourDay === workHour.day &&
                                                                            <Label basic color='red' pointing>
                                                                                Format is: "00:00"
                                                                            </Label>
                                                                        }
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column width={2} verticalAlign='middle'>-</Grid.Column>
                                                                <Grid.Column width={7}>
                                                                    <Form.Field size='mini'>
                                                                        <input 
                                                                            type='text' placeholder='00:00'
                                                                            name={`lunchHourEnd${workHour.day}`}
                                                                            value={workHour.lunch ? workHour.lunch.end : ''}
                                                                            onChange={props.onChange}
                                                                        />
                                                                        {!props.lunchEndIsValid && props.endLunchHourDay === workHour.day &&
                                                                            <Label basic color='red' pointing>
                                                                                Format is: "00:00"
                                                                            </Label>
                                                                        }
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Form> :
                                                    workHour.lunch ? workHour.lunch.start + ' - ' + workHour.lunch.end : ''
                                                }
                                            </Grid.Column>
                                        </React.Fragment>
                                    )
                                })}
                            </Grid.Row>
                        }
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {props.editIsOn ?
                        <Button.Group floated='right' compact>
                            <Button onClick={props.toggleEdit}
                                >Cancel
                            </Button>
                            <Button.Or text='|' />
                            <Button positive onClick={props.updateAddressPhoneWorkHour}>
                                Save
                            </Button>
                        </Button.Group> :
                        <Button compact color='orange'
                                floated='right'
                                onClick={props.toggleEdit}>
                            Edit
                        </Button>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
export default WorkHourData;
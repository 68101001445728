import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Grid, Header } from "semantic-ui-react";

import { dynamicGetMethod } from "../../actions/branchAction";

const BranchOneTimePassword = () => {
  const [oneTimePassword, setOneTimePassword] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dynamicGetMethod('/api/v1/management/otp-password'))
      .then((res) => {
        if (res.status === 200) {
          setOneTimePassword(res.data.data.password);
        }
      });
  }, []);

  return (
    <Grid.Row style={{ borderBottom: 'solid 1px lightgrey' }} verticalAlign="middle">
      <Grid.Column width={3}>
        <Header as='h5'>One time password:</Header>
      </Grid.Column>
      <Grid.Column width={9}>
        {oneTimePassword}
      </Grid.Column>
    </Grid.Row>
  );
}

export default BranchOneTimePassword;
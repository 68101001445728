import React from 'react';
import {Grid, Table, Image, Form, Button, Icon} from 'semantic-ui-react';

const QRcodeData = (props) => {

    return (
        <Grid>
            <Grid.Row style={{paddingBottom: '0'}}>
                <Grid.Column textAlign='center'>
                    <Button compact style={{marginBottom: '10px'}} type='button'
                            color={props.directAvailable ? 'red' : 'green'}
                            onClick={props.directAvailable ? props.activateDeactivateDirect.bind(this, false) : props.activateDeactivateDirect.bind(this, true)}>
                        {props.directAvailable ? 'Deactivate QR' : 'Activate QR'}
                    </Button>
                </Grid.Column>
            </Grid.Row>
            {props.directAvailable &&
                <Grid.Row columns={1}>
                    {props.qr === '' ?
                        <Grid.Column textAlign='center'>
                            <Button compact primary onClick={props.generateQRcode} type='button'>
                                Generate QR code
                            </Button>
                        </Grid.Column> :
                        <React.Fragment>
                            <Grid.Column textAlign='center'>
                                <Image
                                    src={props.qr.url}
                                    verticalAlign='middle' centered
                                    style={{padding: '10px', height: '150px'}}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign='center'  style={{paddingBottom: '5px'}}>
                                {props.createDirectExtraFieldIsOn ? 
                                    <Form size='mini' onSubmit={props.createExtraField}>
                                        <Form.Group>
                                            <Form.Input
                                                placeholder='Hint text'
                                                name='hint_text' required
                                                value={props.hint_text ? props.hint_text : ''}
                                                onChange={props.onChange}
                                            />
                                            <Form.Input
                                                placeholder='Regex type'
                                                name='regex_type'
                                                value={props.regex_type ? props.regex_type : ''}
                                                onChange={props.onChange}
                                            />
                                            <Form.Input
                                                placeholder='Keyword'
                                                name='keyword' required
                                                value={props.keyword ? props.keyword : ''}
                                                onChange={props.onChange}
                                            />
                                            <Form.Button 
                                                compact color='orange' 
                                                content='Cancel' type='button' 
                                                onClick={props.toggleCreateExtraField}
                                            />
                                            <Form.Button compact primary content='Create' type='submit' />
                                        </Form.Group>
                                    </Form> :
                                    <Button compact floated='left' color='teal' type='button'
                                        onClick={props.toggleCreateExtraField}
                                    >
                                        Add extra field
                                    </Button>
                                }
                            </Grid.Column>
                            <Grid.Column>
                                <Table celled striped color='blue'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>№</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Hint text (placeholder)
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Regex type
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Key (input label)
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Action
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {props.extra_fields.length > 0 &&
                                        <Table.Body>
                                            {props.extra_fields.map((field, index) => (
                                                <Table.Row key={field.id}>
                                                    <Table.Cell width={1}>
                                                        {index + 1}
                                                    </Table.Cell>
                                                    <Table.Cell width={5}>
                                                        {field.hint_text}
                                                    </Table.Cell>
                                                    <Table.Cell width={4}>
                                                        {field.regex_type}
                                                    </Table.Cell>
                                                    <Table.Cell width={4}>
                                                        {field.key}
                                                    </Table.Cell>
                                                    <Table.Cell width={2}>
                                                        <Button inverted compact color='red' type='button'
                                                                onClick={() => props.deleteExtraField(field.id)}>
                                                            <Icon name='trash' />
                                                            Delete
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))} 
                                        </Table.Body>
                                    }
                                    {!props.extra_fields.length > 0 &&
                                        <Table.Body> 
                                            <Table.Row> 
                                                <Table.Cell style={{textAlign: 'center'}} colSpan='12'>Пусто</Table.Cell>
                                            </Table.Row> 
                                        </Table.Body>
                                    }
                                </Table>
                            </Grid.Column>
                        </React.Fragment>
                    }
                </Grid.Row>
            }
        </Grid>
    )
};
export default QRcodeData;
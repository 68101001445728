import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTotalStats } from '../../actions/branchAction';
import { Grid, Header, Select } from 'semantic-ui-react';

class BranchAnalitics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reportType: '4',
            report: {}
        };

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount(){
        this.showReport();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.promoter !== this.props.promoter){
            const {promoter} = this.props;
            this.setState({
                registeredUser: promoter,
                userId: promoter.id
            });
        }
    }

    onSelectChange(e, data) {
        const value = data.value;
        const name = data.name;
        
        this.setState({
            [name]: value
        }, function(){
            if(name === 'reportType') this.showReport();
        });
    }
    
    showReport(){
        const {branchId} = this.props.match.params;
        const {reportType} = this.state;
        this.props.getTotalStats('/api/v1/branches/' + branchId + '/statistics?type=' + reportType)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    report: res.data.data
                });
            }
        }.bind(this));
    }

    render() {
        const {report, reportType} = this.state;
        const reportTypeOptions = [
            {key: 0, value: '1', text: 'Daily report'},
            {key: 1, value: '2', text: 'Weekly report'},
            {key: 2, value: '3', text: 'Monthly report'},
            {key: 3, value: '4', text: 'Yearly report'}
        ];
        return (
            <Grid.Row>
                <Grid.Column width={6}></Grid.Column>
                <Grid.Column textAlign='center' width={4}>
                    <Select 
                        style={{marginBottom: '10px'}}
                        name='reportType' value={reportType}
                        options={reportTypeOptions}
                        onChange={this.onSelectChange}
                    />
                </Grid.Column>
                <Grid.Column width={6}></Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Payment total:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.payment_total ? Math.round(report.payment_total).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Payment count:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.payment_count ? Math.round(report.payment_count).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Payment from card:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.payment_from_card ? Math.round(report.payment_from_card).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Payment balance:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.payment_balance ? Math.round(report.payment_balance).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Payment from certificate:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.payment_from_certificate ? Math.round(report.payment_from_certificate).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Payment certificate count:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.payment_certificate_count ? Math.round(report.payment_certificate_count).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Commission amount:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.commission_amount ? Math.round(report.commission_amount).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Receiver income:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.receiver_income ? Math.round(report.receiver_income).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Cashback amount:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.cashback_amount ? Math.round(report.cashback_amount).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Visitors:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.visitors ? Math.round(report.visitors).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Average check:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.average_check ? Math.round(report.average_check).toFixed(2) : ''}</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8} textAlign='right'>
                    <Header as='h4'>Conversion rate:</Header>
                </Grid.Column>
                <Grid.Column style={{marginTop: '10px'}} width={8}>
                    <Header as='h5'>{report.conversion_rate ? Math.round(report.conversion_rate).toFixed(2) : ''}</Header>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

BranchAnalitics.propTypes = {
    requestInProcess: PropTypes.bool
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTotalStats: (url) => dispatch(getTotalStats(url))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchAnalitics);
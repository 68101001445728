export function orderList(state = [], action) {
    switch (action.type) {
        case 'GET_ORDER_LIST_SCROLL':
            return state.concat(action.list);
        case 'GET_ORDER_LIST':
            return action.list;
        case 'ADD_ORDER_SUCCESS':
            return [action.order, ...state]
        case 'EMPTY_ORDER_LIST':
            return [];
        case 'DELETE_ORDER_SUCCESS':
            return state.filter(item => item.id !== action.orderId);
        case 'REVERT_ORDER_SUCCESS':
            const index = state.findIndex(order => order.id === action.orderId);
            var foundOrder = state.find(order => order.id === action.orderId);
            foundOrder.status = 'DECLINED';
            return [...state.slice(0, index), foundOrder, ...state.slice(index + 1)];
        default:
            return state;
    }
}

export function order(state = {}, action) {
    switch (action.type) {
        case 'GET_ORDER_INFO_SUCCESS':
            return action.order;
        default:
            return state;
    }
}
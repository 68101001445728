export function saleList(state = [], action) {
    switch (action.type) {
        case 'GET_SALE_LIST_SUCCESS':
            return action.list;
        case 'DELETE_SALE_SUCCESS':
            return state.filter(item => item.id !== action.saleId);
        case 'ADD_SALE_SUCCESS':
            return [...state, action.sale];
        case 'UPDATE_SALE_SUCCESS':
            const index = state.findIndex(sale => sale.id === action.sale.id);
            return [...state.slice(0, index), action.sale, ...state.slice(index + 1)];
        default:
            return state;
    }
}
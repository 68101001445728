import React, { Fragment } from 'react';
import { Grid, Input, Button, Table, Icon, Dropdown } from 'semantic-ui-react';
import {isEmpty} from '../../services/common';

const MenuData = (props) => {
    const { delivery, pickup } = props;
    let deliverenceExists = (!isEmpty(delivery) || !isEmpty(pickup)) ? true : false;
    let bothExists = (!isEmpty(delivery) && !isEmpty(pickup)) ? true : false;
    
    return (
        <Grid.Row columns={1}>
            <Grid.Column textAlign='center'>
                {!bothExists && props.menuAvailable && 
                    <Dropdown text='Добавить вид получения' labeled button className='icon mini'
                            style={{position: 'absolute', left: '15px', background: '#009C95', color: '#fff'}}>
                        <Dropdown.Menu>
                            {isEmpty(delivery) &&
                                <Dropdown.Item onClick={() => props.createOrSaveDeliverence('create', 'delivery')}>Доставка</Dropdown.Item>
                            }
                            {isEmpty(pickup) &&
                                <Dropdown.Item onClick={() => props.createOrSaveDeliverence('create', 'pickup')}>Самовывоз</Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <Button compact style={{marginBottom: '10px'}}
                        color={props.menuAvailable ? 'red' : 'green'}
                        onClick={props.menuAvailable ? 
                            props.activateDeactivateMenu.bind(this, false) : 
                                props.activateDeactivateMenu.bind(this, true)}>
                    {props.menuAvailable ? 'Deactivate menu' : 'Activate menu'}
                </Button>
                {props.menuAvailable && deliverenceExists &&
                    <Fragment>
                        {props.createIsOn ? 
                            <Input size='mini' type='text' 
                                    style={{float: 'right'}}
                                    placeholder='Menu name' action>
                                <input 
                                    value={props.menuName}
                                    name="menuName"
                                    onChange={props.onChange}
                                />
                                <Button.Group size='mini'>
                                    <Button color='orange' onClick={props.toggleCreate}>
                                        Cancel
                                    </Button>
                                    <Button.Or text='|' />
                                    <Button positive onClick={props.createMenu}>
                                        Save
                                    </Button>
                                </Button.Group>
                            </Input> :
                            <Button compact floated='right' color='teal'
                                    onClick={props.toggleCreate}>
                                Add menu
                            </Button>
                        }
                    </Fragment>
                }
            </Grid.Column>
            {deliverenceExists && props.menuAvailable &&
                <Fragment>
                    <Grid.Column>
                        <span style={{fontSize: '16px', fontWeight: 'bold', padding: '5px'}}>
                            Виды получения товаров
                        </span>
                    </Grid.Column>
                    <Grid.Column style={{marginBottom: '15px'}}>
                        <Table celled striped color='brown'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width='3'>
                                        Название
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width='3'>
                                        Стоимость внутри города
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width='3'>
                                        Стоимость меж. городов
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width='3'>
                                        Время (в минутах)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width='4'>
                                        Действие
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {deliverenceExists && 
                                <Table.Body>
                                    {!isEmpty(delivery) &&
                                        <Table.Row>
                                            <Table.Cell>Доставка</Table.Cell>
                                            <Table.Cell>
                                                {props.deliveryIsOn ?
                                                    <Input size='mini' type='number'
                                                        value={delivery.price_in_city}
                                                        name="delivery_price_in_city"
                                                        onChange={props.onChange} 
                                                    /> :
                                                    delivery.price_in_city
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {props.deliveryIsOn ?
                                                    <Input size='mini' type='number'
                                                        value={delivery.price_out_city}
                                                        name="delivery_price_out_city"
                                                        onChange={props.onChange} 
                                                    /> :
                                                    delivery.price_out_city
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {props.deliveryIsOn ?
                                                    <Input size='mini' type='number'
                                                        value={delivery.time !== '' ? delivery.time/60000 : ''}
                                                        name="delivery_time"
                                                        onChange={props.onChange} 
                                                    /> :
                                                    delivery.time !== '' ? delivery.time/60000 : ''
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {props.deliveryIsOn ?
                                                    <Fragment>
                                                        <Button inverted compact color='orange'
                                                                onClick={props.toggleDeliveryEdit}>
                                                            <Icon name='cancel' />
                                                            Отмена
                                                        </Button>
                                                        <Button inverted compact color='green'
                                                                onClick={() => props.createOrSaveDeliverence('save', 'delivery')}>
                                                            <Icon name='save' />
                                                            Сохранить
                                                        </Button>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <Button inverted compact color='yellow'
                                                                onClick={props.toggleDeliveryEdit}>
                                                            <Icon name='pencil' />
                                                            Изменить
                                                        </Button>
                                                        <Button inverted compact color='red'
                                                                onClick={() => props.deleteDeliverence('delivery')}>
                                                            <Icon name='pencil' />
                                                            Удалить
                                                        </Button>
                                                    </Fragment>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    }
                                    {!isEmpty(pickup) &&
                                        <Table.Row>
                                            <Table.Cell>Самовывоз</Table.Cell>
                                            <Table.Cell>
                                                {props.pickupIsOn ?
                                                    <Input size='mini' type='number'
                                                        value={pickup.price_in_city}
                                                        name="pickup_price_in_city"
                                                        onChange={props.onChange} 
                                                    /> :
                                                    pickup.price_in_city
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {props.pickupIsOn ?
                                                    <Input size='mini' type='number'
                                                        value={pickup.price_out_city}
                                                        name="pickup_price_out_city"
                                                        onChange={props.onChange} 
                                                    /> :
                                                    pickup.price_out_city
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {props.pickupIsOn ?
                                                    <Input size='mini' type='number'
                                                        value={pickup.time !== '' ? pickup.time/60000 : ''}
                                                        name="pickup_time"
                                                        onChange={props.onChange} 
                                                    /> :
                                                    pickup.time !== '' ? pickup.time/60000 : ''
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {props.pickupIsOn ?
                                                    <Fragment>
                                                        <Button inverted compact color='orange'
                                                                onClick={props.togglePickupEdit}>
                                                            <Icon name='cancel' />
                                                            Отмена
                                                        </Button>
                                                        <Button inverted compact color='green'
                                                                onClick={() => props.createOrSaveDeliverence('save', 'pickup')}>
                                                            <Icon name='save' />
                                                            Сохранить
                                                        </Button>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <Button inverted compact color='yellow'
                                                                onClick={props.togglePickupEdit}>
                                                            <Icon name='pencil' />
                                                            Изменить
                                                        </Button>
                                                        <Button inverted compact color='red'
                                                                onClick={() => props.deleteDeliverence('pickup')}>
                                                            <Icon name='pencil' />
                                                            Удалить
                                                        </Button>
                                                    </Fragment>     
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    }
                                </Table.Body>
                            }
                        </Table>
                    </Grid.Column>
                </Fragment>
            }
            {props.menuAvailable && deliverenceExists &&
                <Fragment>
                    <Grid.Column></Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        <Table celled striped color='blue'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>№</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Menu name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {props.menuList.length > 0 &&
                                <Table.Body>
                                    {props.menuList.map((menu, index) => (
                                        <Table.Row key={menu.id}>
                                            <Table.Cell width={2}>
                                                {index + 1}
                                            </Table.Cell>
                                            <Table.Cell width={9}>
                                                {props.editIsOn && props.currentMenuId === menu.id ?
                                                    <Input
                                                        value={props.menuName}
                                                        name="menuName"
                                                        onChange={props.onChange} 
                                                    /> : menu.name
                                                }
                                            </Table.Cell>
                                            <Table.Cell width={5}>
                                                {props.editIsOn && props.currentMenuId === menu.id ?
                                                    <Fragment>
                                                        <Button inverted compact color='orange'
                                                                onClick={props.toggleEdit.bind(this, "", "", false)}>
                                                            <Icon name='cancel' />
                                                            Cancel
                                                        </Button>
                                                        <Button inverted compact color='green'
                                                                onClick={props.updateMenu}>
                                                            <Icon name='save' />
                                                            Save
                                                        </Button>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <Button inverted compact color='blue'
                                                                onClick={props.showHideProducts.bind(this, menu.id)}>
                                                            <Icon name='eye' />
                                                            Products
                                                        </Button>
                                                        <Button inverted compact color='yellow'
                                                                onClick={props.toggleEdit.bind(this, menu.id, menu.name, true)}>
                                                            <Icon name='pencil' />
                                                            Edit
                                                        </Button>
                                                        <Button inverted compact color='red'
                                                                onClick={props.removeMenu.bind(this, menu.id)}>
                                                            <Icon name='trash' />
                                                            Delete
                                                        </Button>
                                                    </Fragment>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    ))} 
                                </Table.Body>
                            }
                            {!props.menuList.length > 0 &&
                                <Table.Body> 
                                    <Table.Row> 
                                        <Table.Cell style={{textAlign: 'center'}} colSpan='12'>Пусто</Table.Cell>
                                    </Table.Row> 
                                </Table.Body>
                            }
                        </Table>
                    </Grid.Column>
                </Fragment>
            }
        </Grid.Row>
    )
}
export default MenuData;
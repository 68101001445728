import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateToken } from '../../actions/authAction';
import { Menu, Button, Icon } from 'semantic-ui-react'
import '../../assets/css/sidemenu.css';

class Sidemenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: '/cities'
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const role = sessionStorage.getItem("role");
    const activePage = this.props.location.pathname;
    this.setState({ activePage: activePage === '/' ? role === 'admin' ? '/cities' : role === 'accountant' ? '/withdrawals' : '/statistics' : activePage });
  }

  onChange(e) {
    const val = e.target.value;
    const name = e.target.name;

    // console.log(val, name);
    this.setState({
      [name]: val
    });
  }

  handleTabClick(e, pageName) {
    //console.log(e.target);
    this.setState({ activePage: pageName });
  }

  //logs the user out of the system
  logout() {
    //just need to clear sessionStorage
    sessionStorage.clear();
    //and update token to null
    this.props.updateToken(sessionStorage.getItem('tokenInfo'));
    //then goto root
    this.props.history.replace('/');
  }

  render() {
    const { activePage } = this.state;

    return (
      <Menu vertical style={{ height: '100%', backgroundColor: '#4fd45a' }}>
        {sessionStorage.getItem("role") === "admin" &&
          <React.Fragment>
            <Menu.Item as={Link} replace to='/cities'
              name='/cities' active={activePage === '/cities'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Cities
            </Menu.Item>

            <Menu.Item as={Link} replace to='/categories'
              name='/categories' active={activePage === '/categories'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Categories
            </Menu.Item>
          </React.Fragment>}

        {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "sales") &&
          <React.Fragment>
            <Menu.Item as={Link} replace to='/branches'
              name='/branches' active={activePage === '/branches'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Branches
            </Menu.Item>
          </React.Fragment>}

        {(sessionStorage.getItem("role") === "accountant" || sessionStorage.getItem("role") === "admin") &&
          <React.Fragment>
            <Menu.Item as={Link} replace to='/withdrawals'
              name='/withdrawals' active={activePage === '/withdrawals'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Withdrawals
            </Menu.Item>
            <Menu.Item as={Link} replace to='/orders'
              name='/orders' active={activePage === '/orders'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Orders
            </Menu.Item>
          </React.Fragment>
        }

        {sessionStorage.getItem("role") === "admin" &&
          <React.Fragment>
            <Menu.Item as={Link} replace to='/users'
              name='/users' active={activePage === '/users'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Users
            </Menu.Item>

            <Menu.Item as={Link} replace to='/business-users'
              name='/business-users' active={activePage === '/business-users'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Business users
            </Menu.Item>

            <Menu.Item as={Link} replace to='/deals'
              name='/deals' active={activePage === '/deals'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Deals
            </Menu.Item>

            <Menu.Item as={Link} replace to='/services'
              name='/services' active={activePage === '/services'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Additional services
            </Menu.Item>

            <Menu.Item as={Link} replace to='/sales'
              name='/sales' active={activePage === '/sales'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Sales
            </Menu.Item>

            <Menu.Item as={Link} replace to='/promoter'
              name='/promoter' active={activePage === '/promoter'}
              onClick={(e, { name }) => this.handleTabClick(e, name)}>
              Promoter
            </Menu.Item>
          </React.Fragment>}

        {(sessionStorage.getItem("role") !== "accountant" && sessionStorage.getItem("role") !== "sales") &&
          <Menu.Item as={Link} replace to='/stats'
            name='/stats' active={activePage === '/stats'}
            onClick={(e, { name }) => this.handleTabClick(e, name)}>
            Statistics
          </Menu.Item>}

        <Menu.Item>
          <Button animated style={{ width: '100%' }} onClick={this.logout.bind(this)} color='facebook'>
            <Button.Content hidden>Log out</Button.Content>
            <Button.Content visible>
              <Icon name='log out' />
            </Button.Content>
          </Button>
        </Menu.Item>
      </Menu>
    );
  }
}

Sidemenu.propTypes = {
  updateToken: PropTypes.func,
  requestInProcess: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateToken: (token) => dispatch(updateToken(token))
  };
};

const mapStateToProps = (state) => {
  return {
    requestInProcess: state.requestInProcess
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
import axios from 'axios';
import {timeDiff, getNewToken} from '../services/common';

export function changePage(pageName){
    return {
        type: 'PAGE_HAS_CHANGED',
        pageName
    }
}

export function requestHasErrored(bool) {
    return {
        type: 'REQUEST_HAS_ERRORED',
        hasErrored: bool
    };
}

export function requestInProcess(bool) {
    return {
        type: 'REQUEST_IN_PROCESS',
        isLoading: bool
    };
}

export function updateItemId(itemId){
    return {
        type: 'ITEM_ID_HAS_CHANGED',
        itemId
    }
}

export function handleGetMethod(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(requestInProcess(false));
                return response;
            }
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSaleList, createUpdateSale, deleteSale } from '../../actions/saleAction';
import { getBranchList } from '../../actions/branchAction';
import { Grid, Header, Button, Icon, Dimmer, Loader, Modal, Form, Confirm, Dropdown, Label, Table } from 'semantic-ui-react';
import DatePickerBasic from '../helpers/DatePicker';
import moment from 'moment';

class SaleList extends Component {
    state = {
        saleList: [],
        saleId: '',
        branch: {
            id: '',
            branch_name: '',
            bonus: '',
            expired_at: '',
            group_id: '',
        },
        branchId: '',
        branchList: [],
        bonus: '',
        bonuses: [],
        confirmOpen: false,
        modalOpen: false,
        infoModalOpen: false,
        height: 512,
        expire_date: moment(new Date()).startOf('day').toDate()
    };

    componentDidMount() {
        this.updateWindowDimensions();
        this.props.getSaleList('/api/v1/sales');
        this.props.getBranchList('/api/v1/branches');
    }

    updateWindowDimensions() {
        this.setState({ height: window.outerHeight - 230 });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.saleList !== this.props.saleList) {
            this.setState({ saleList: this.props.saleList })
        }

        if (prevProps.branchList !== this.props.branchList) {
            const { branchList } = this.props;
            const branchListOptions = [];
            branchList.forEach(element => {
                const obj = {
                    key: element.id,
                    value: element.id,
                    text: element.name
                }
                branchListOptions.push(obj);
            });
            this.setState({
                branchList,
                branchListOptions
            });
        }
    }

    onChange = (e) => {
        const { value, name } = e.target;
        this.setState({
            [name]: value
        });
    }

    onSelectChange = (e, data) => {
        const { name, value, checked } = data;
        const { branchList } = this.state;

        if (name === 'branchId') {
            const chosenBranch = branchList.find(x => x.id === value);
            const { private_cashback } = chosenBranch.configuration;
            const branch = {
                id: chosenBranch.id,
                branch_name: chosenBranch.name,
                bonus: '',
                expired_at: private_cashback ? private_cashback.expired_at : null,
                group_id: chosenBranch.group_id,
            };
            this.setState({ branch });
        }
        this.setState({
            [name]: data.type === 'radio' ? checked : value
        });
    }

    onSearchChange = (e, value) => {
        this.props.getBranchList('/api/v1/branches?query=' + value.searchQuery);
    }

    onDateChange = (e, data) => {
        const { name, value } = data;
        this.setState({ [name]: value });
    }

    filterDate = (date) => {
        const today = moment(new Date()).startOf('day');
        return date >= today;
    }

    //for toggling the Modal
    handleModalToggle = (saleId, date) => {
        if (saleId !== '') {
            const { saleList } = this.state;
            const foundSale = saleList.find(x => x.id === saleId);
            this.setState({
                saleId,
                expire_date: date === 0 ? null : new Date(date),
                saleName: foundSale.title,
                saleDescription: foundSale.description,
                saleActive: foundSale.active,
                bonuses: foundSale.payload.bonuses.branches,
                modalOpen: true
            });
        } else {
            this.setState({
                saleId,
                saleName: '',
                saleDescription: '',
                saleActive: false,
                bonuses: [],
                modalOpen: !this.state.modalOpen
            });
        }
    }

    toggleModalInfo = (saleId) => {
        if (saleId !== '') {
            const { saleList } = this.state;
            const foundSale = saleList.find(x => x.id === saleId);
            this.setState({
                bonuses: foundSale.payload.bonuses.branches,
                infoModalOpen: true
            });
        }
        this.setState({ infoModalOpen: !this.state.infoModalOpen });
    }

    //creating new sale
    createUpdateSale = () => {
        this.setState({ createSaleLoader: true })
        const { saleId, saleName, saleDescription, saleActive, expire_date, bonuses } = this.state;
        const type = saleId === "" ? "post" : "put";
        let url;

        const data = {
            "title": saleName,
            "description": saleDescription,
            "active": saleActive,
            "type": "REGISTRATION_BONUS",
            "payload": {
                "branches": bonuses
            }
        }
        if (type === "put") {
            url = '/api/v1/sales/' + saleId;
            data["id"] = saleId;
            data["expire_date"] = new Date(expire_date).getTime();
        } else {
            url = '/api/v1/sales';
            data["expired_at"] = new Date(expire_date).getTime();
        }

        this.props.createUpdateSale(url, data, type)
            .then(res => {
                if (res.status === 201 || res.status === 200) {
                    this.setState({
                        modalOpen: false,
                    });
                }
            }).catch((err) => {
                console.log(err);
                // we need to show a message
            }).finally(() => {
                this.setState({
                    createSaleLoader: false
                });
            });
    }

    // calls confirmation window for deleting the sale
    handelConfirmation = (saleId) => {
        this.setState({ confirmOpen: !this.state.confirmOpen, saleId });
    }

    //removes the sale
    deleteSale(saleId) {
        this.props.deleteSale('/api/v1/sales/' + saleId, saleId)
            .then(function (res) {
                if (res.status === 200) {
                    this.setState({
                        confirmOpen: false
                    });
                }
            }.bind(this), function (error) {
                if (error) {
                    this.setState({
                        confirmOpen: false
                    }, function () {
                        alert(error.data.message);
                    });
                }
            }.bind(this));
    }

    addBonus = () => {
        const { branch, bonus, bonuses } = this.state;
        if (bonus === '') return;
        branch.bonus = Number(bonus);
        const updatedList = [...bonuses];
        updatedList.push(branch);
        this.setState({
            bonuses: updatedList,
            branchId: '',
            bonus: '',
        });
    }

    //for adding the bonus to state list
    deleteBonus(id) {
        this.setState({
            bonuses: this.state.bonuses.filter(x => x.id !== id)
        })
    }

    render() {
        const { saleList, modalOpen, saleId, confirmOpen, createSaleLoader,
            saleName, saleDescription, branchListOptions, branchId,
            saleActive, bonus, bonuses, expire_date, infoModalOpen } = this.state;

        const labelStyle = {
            margin: "0 0 .28571429rem 0",
            fontWeight: 700,
            fontSize: '.92857143em',
        }

        return (
            <div style={{ paddingLeft: '5px', height: '100%' }}>
                <Grid style={{ margin: '0' }}>
                    <Grid.Row columns='equal'>
                        <Grid.Column></Grid.Column>
                        <Grid.Column textAlign='center' verticalAlign='middle'>
                            <Header as='h3'>Бонусы / Акции</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Button color='teal' floated='right' compact
                                onClick={() => this.handleModalToggle('')}
                                disabled={saleList.length > 0 ? true : false}
                            >
                                <Icon name='add' />
                                Добавить
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1} color='blue'>№</Grid.Column>
                        <Grid.Column width={5} color='blue'>
                            Название
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>
                            Срок
                        </Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Количество человек
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>
                            Действия
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid celled style={{ marginTop: '0' }}>
                    {saleList.length > 0 ?
                        <Grid.Row verticalAlign='middle'>
                            {saleList.map((sale, index) => (
                                <Fragment key={sale.id}>
                                    <Grid.Column width={1}>{index + 1}</Grid.Column>
                                    <Grid.Column width={5}>{sale.title}</Grid.Column>
                                    <Grid.Column width={4}>
                                        {sale.expired_at === 0 ? "Срок не выбран" : moment(sale.expired_at).format('DD-MM-YYYY')}
                                    </Grid.Column>
                                    <Grid.Column width={2}>{sale.users_count}</Grid.Column>
                                    <Grid.Column width={4} className='tableActions'>
                                        <Button size='mini' inverted color='green' onClick={() => this.toggleModalInfo(sale.id)}>
                                            <Icon name='eye' />
                                            Открыть
                                        </Button>
                                        <Button size='mini' inverted color='blue' onClick={() => this.handleModalToggle(sale.id, sale.expired_at)}>
                                            <Icon name='edit' />
                                            Изменить
                                        </Button>
                                        <Button size='mini' inverted color='red' onClick={() => this.handelConfirmation(sale.id)}>
                                            <Icon name='trash' />
                                            Удалить
                                        </Button>
                                    </Grid.Column>
                                </Fragment>
                            ))}
                        </Grid.Row> :
                        <Grid.Row columns='equal'>
                            <Grid.Column textAlign='center'>
                                Нет данных
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
                <Modal
                    centered={false} size='mini'
                    open={modalOpen}
                    onClose={() => this.handleModalToggle('')}
                >
                    <Modal.Content>
                        <Form size='mini' onSubmit={this.createUpdateSale}>
                            <Grid>
                                {bonuses.length > 0 &&
                                    <Grid.Row width={16} columns={1}>
                                        <Grid.Column style={{ fontWeight: 600, marginBottom: 5 }}>Добавленные филиалы: </Grid.Column>
                                        <Grid.Column>
                                            {bonuses.map((item, index) => (
                                                <Label key={index} style={{ marginTop: '5px' }} color='teal'>
                                                    {item.branch_name}, {item.bonus}
                                                    <Icon name='delete' onClick={() => this.deleteBonus(item.id)} />
                                                </Label>
                                            ))}
                                        </Grid.Column>
                                    </Grid.Row>}
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Form.Input
                                            fluid label='Название акции'
                                            placeholder='Название' required
                                            value={saleName || ''}
                                            name="saleName"
                                            onChange={this.onChange}
                                        />
                                        <Form.TextArea
                                            label='Описание акции'
                                            placeholder='Описание'
                                            value={saleDescription || ''}
                                            name="saleDescription"
                                            onChange={this.onChange}
                                        />
                                        <p style={labelStyle}>Срок</p>
                                        <Form.Field className="field-expired_date">
                                            <DatePickerBasic
                                                name="expire_date"
                                                value={expire_date}
                                                onDateChange={this.onDateChange}
                                                filterDate={this.filterDate}
                                            />
                                        </Form.Field>
                                        <span style={labelStyle}>Активировать</span>
                                        <Form.Radio
                                            toggle
                                            checked={saleActive}
                                            name="saleActive"
                                            onChange={this.onSelectChange}
                                        />
                                        <p style={labelStyle}>Филиал <span style={{ color: 'red' }}>*</span></p>
                                        <Dropdown
                                            search placeholder='Select branch...' closeOnChange
                                            options={branchListOptions || []} selection
                                            name="branchId" value={branchId} required
                                            onChange={this.onSelectChange} fluid
                                            onSearchChange={this.onSearchChange}
                                            style={{ margin: '0 0 1em' }}
                                        />
                                        {branchId !== '' &&
                                            <Form.Input
                                                action={{
                                                    size: 'mini',
                                                    content: 'Добавить филиал',
                                                    color: 'blue',
                                                    onClick: this.addBonus,
                                                    type: 'button'
                                                }}
                                                placeholder='Введите сумму'
                                                fluid label='Бонус' required
                                                value={bonus || ''} name="bonus"
                                                onChange={this.onChange}
                                            />
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Button color='green' size='mini'
                                            disabled={bonuses.length <= 0 ? true : false}
                                            type='submit' floated='right'
                                        >
                                            <Icon name={saleId !== '' ? 'refresh' : 'add'} />
                                            {saleId !== '' ? 'Обновить' : 'Создать'}
                                        </Button>
                                        <Button color='orange' size='mini'
                                            type='button' floated='right'
                                            onClick={() => this.handleModalToggle('')}>
                                            <Icon name='cancel' /> Отмена
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {createSaleLoader &&
                                <Dimmer active={createSaleLoader} inverted>
                                    <Loader inverted content='Loading' />
                                </Dimmer>
                            }
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal
                    centered={false}
                    open={infoModalOpen}
                    onClose={() => this.toggleModalInfo('')}
                >
                    <Modal.Content>
                        <Table celled striped color='blue'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>№</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Название филиала
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Количество бонусов
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {bonuses.length > 0 &&
                                <Table.Body>
                                    {bonuses.map((item, index) => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell>{item.branch_name}</Table.Cell>
                                            <Table.Cell>{item.bonus}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            }
                            {!bonuses.length > 0 &&
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell style={{ textAlign: 'center' }} colSpan='12'>Пусто</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            }
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => this.toggleModalInfo('')}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
                <Confirm
                    open={confirmOpen}
                    content='Вы действительно хотите удалить?'
                    cancelButton='Отмена'
                    confirmButton="Удалить"
                    onCancel={() => this.handelConfirmation('')}
                    onConfirm={() => this.deleteSale(saleId)}
                />
            </div>
        );
    }
}

SaleList.propTypes = {
    getSaleList: PropTypes.func,
    saleList: PropTypes.array,
    getBranchList: PropTypes.func,
    branchList: PropTypes.array,
    requestInProcess: PropTypes.bool,
    createUpdateSale: PropTypes.func,
    deleteSale: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSaleList: (url) => dispatch(getSaleList(url)),
        getBranchList: (url) => dispatch(getBranchList(url)),
        createUpdateSale: (url, data, type) => dispatch(createUpdateSale(url, data, type)),
        deleteSale: (url, saleId) => dispatch(deleteSale(url, saleId)),
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        saleList: state.saleList,
        branchList: state.branchList,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleList);
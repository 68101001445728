import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Button, Grid, Header, Icon, Image, Input, Label, Modal, Table } from "semantic-ui-react";
import moment from 'moment';

import { getUserItems, updateBonusBalance } from "../../actions/userAction";
import { getParsedDateTime } from '../../services/common';

const User = (props) => {
	const [bonusList, setBonusList] = useState([]);
	const [branchList, setBranchList] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [branchId, setBranchId] = useState('');
	const [balanceValue, setBalanceValue] = useState('');
	const [errorText, setErrorText] = useState(null);
	const dispatch = useDispatch();
	const { history } = props;

	useEffect(() => {
		const { userId } = props.match.params;
		dispatch(getUserItems('/api/v1/user/bonuses?user_id=' + userId))
			.then((res) => {
				// console.log(res.data);
				setBonusList(res.data.data);
			});

		dispatch(getUserItems('/api/v1/user/branches?user_id=' + userId))
			.then((res) => {
				// console.log(res.data);
				setBranchList(res.data.data);
			});
	}, []);

	const onChangeHandler = (event) => {
		setBalanceValue(event.target.value);
 	};

	const updateBonus = () => {
		const { userId } = props.match.params;
		const data = {
			user_id: userId,
			branch_id: branchId,
			amount: Number(balanceValue)
		}

		dispatch(updateBonusBalance('/api/v1/user/bonuses/update', data))
		.then((res) => {
			// console.log(res);
			if(res.data.message === "Success") {
				let foundBranch = bonusList.find(x => x.id === branchId);
				const index = bonusList.findIndex(bonus => bonus.id === branchId);
				foundBranch.balance = foundBranch.balance - balanceValue;
        setBonusList([...bonusList.slice(0, index), foundBranch, ...bonusList.slice(index + 1)]);
			}
			setModalOpen(false);
			setBalanceValue('');
			setErrorText(null);
		})
		.catch(error => {
			// console.log(error);
			if(error.status === 400) {
				setErrorText(error.data.message);
			}
		});
	}

	return (
		<>
			<Grid style={{ margin: 0 }}>
				<Grid.Row>
					<Grid.Column width={16} style={{ display: "flex" }}>
						<Button compact onClick={history.goBack}>Back</Button>
						<Header style={{ display: "flex", margin: "auto" }}>User's bonuses</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={1}>№</Table.HeaderCell>
									<Table.HeaderCell width={4}>Name</Table.HeaderCell>
									<Table.HeaderCell width={4}>Icon</Table.HeaderCell>
									<Table.HeaderCell width={4}>Balance</Table.HeaderCell>
									<Table.HeaderCell width={3}>Action</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{bonusList.length > 0 ?
								<Table.Body>
									{bonusList.map((bonus, index) => (
										<Table.Row key={bonus.id}>
											<Table.Cell>{index + 1}</Table.Cell>
											<Table.Cell>{bonus.name}</Table.Cell>
											<Table.Cell>
												<Image
													size="tiny"
													src={bonus.icon}
												/>
											</Table.Cell>
											<Table.Cell>{bonus.balance}</Table.Cell>
											<Table.Cell>
												<Button
													compact color='yellow'
													onClick={() => {
														setBranchId(bonus.id);
														setModalOpen(true);
													}}
												>
													<Icon name='credit card' />
													Update balance
												</Button>
											</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
								:
								<Table.Body>
									<Table.Row>
										<Table.Cell style={{ textAlign: 'center' }} colSpan='4'>Empty</Table.Cell>
									</Table.Row>
								</Table.Body>
							}
						</Table>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16} style={{ display: "flex" }}>
						<Header style={{ display: "flex", margin: "auto" }}>User transaction branches</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={1}>№</Table.HeaderCell>
									<Table.HeaderCell width={3}>Branch name</Table.HeaderCell>
									<Table.HeaderCell width={2}>Client type</Table.HeaderCell>
									<Table.HeaderCell width={2}>First order</Table.HeaderCell>
									<Table.HeaderCell width={2}>Last order</Table.HeaderCell>
									<Table.HeaderCell width={2}>Total amount</Table.HeaderCell>
									<Table.HeaderCell width={2}>Total bonuses</Table.HeaderCell>
									<Table.HeaderCell width={2}>Transactions count</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{branchList.length > 0 ?
								<Table.Body>
									{branchList.map((branch, index) => (
										<Table.Row key={`${branch.name}_${index}`}>
											<Table.Cell>{index + 1}</Table.Cell>
											<Table.Cell>{branch.branch_name}</Table.Cell>
											<Table.Cell>{branch.client_type}</Table.Cell>
											<Table.Cell>
												{getParsedDateTime(moment(new Date(branch.first_order)).format())}
											</Table.Cell>
											<Table.Cell>
												{getParsedDateTime(moment(new Date(branch.last_order)).format())}	
											</Table.Cell>
											<Table.Cell>{branch.total_amount}</Table.Cell>
											<Table.Cell>{branch.total_bonuses}</Table.Cell>
											<Table.Cell>{branch.transactions_count}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
								:
								<Table.Body>
									<Table.Row>
										<Table.Cell style={{ textAlign: 'center' }} colSpan='4'>Empty</Table.Cell>
									</Table.Row>
								</Table.Body>
							}
						</Table>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{modalOpen &&
				<Modal
					centered={false}
					open={modalOpen}
					onClose={() => setModalOpen(false)}
				>
					<Modal.Header>
						Update bonus balance
					</Modal.Header>
					<Modal.Content>
						<Grid>
							<Grid.Column width={4}>
								<Input 
									type="number"
									name="balance"
									placeholder="Enter amount"
									onChange={(e) => onChangeHandler(e)}
									value={balanceValue}
									min={0}
								/>
							</Grid.Column>
							{errorText &&
								<Grid.Column width={8} verticalAlign="middle">								
									<Label basic color="red">
										{errorText}
									</Label>
								</Grid.Column>
							}
						</Grid>
					</Modal.Content>
					<Modal.Actions>
						<Button color='yellow' onClick={() => setModalOpen(false)}>
							Cancel
						</Button>
						<Button
							content="Update balance"
							labelPosition='right'
							icon='checkmark'
							onClick={() => updateBonus()}
							positive
						/>
					</Modal.Actions>
				</Modal>
			}
		</>
	);
}

export default User;
import axios from 'axios';
import { requestHasErrored, requestInProcess, updateItemId } from './commonAction';
import {timeDiff, getNewToken} from '../services/common';
var JSONbigString = require('json-bigint')({"storeAsString": true});

export function getOrderList(url, type) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                const arr = response.data.data;
                var arrLength = arr.length;
                var lastItemId = arrLength > 0 ? typeof arr[arrLength - 1].id === 'number' ? arr[arrLength - 1].id.toString() : arr[arrLength - 1].id : '';
                dispatch(updateItemId(lastItemId));
                dispatch(requestInProcess(false));
                dispatch(getOrderListSuccess(arr, type));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getOrderListSuccess(list, getType) {
    return {
        type: getType === 'scroll' ? 'GET_ORDER_LIST_SCROLL' : 'GET_ORDER_LIST',
        list
    };
}

export function emptyOrderList() {
    return {
        type: 'EMPTY_ORDER_LIST'
    };
}

export function getOrderInfo(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(requestInProcess(false));
                dispatch(getOrderInfoSuccess(response.data.data));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getOrderInfoSuccess(order) {
    return {
        type: 'GET_ORDER_INFO_SUCCESS',
        order
    };
}

export function updateStatus(url, data) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function revertTransaction(url, orderId) {
  return async (dispatch) => {
      dispatch(requestInProcess(true));
      var token = sessionStorage.getItem('tokenInfo');
      //always check token validation
      if(timeDiff()){
          await getNewToken(token).then(function(res) {
              token = res.data.data.access_token;
          });
      }
      
      try {
          const response = await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` } });
          if (response.status === 200) {
              // console.log(response);
              dispatch(requestInProcess(false));
              dispatch(revertTransactionSuccess(orderId));
          }
          return response;
      }
      catch (error) {
          dispatch(requestInProcess(false));
          dispatch(requestHasErrored(true));
          // console.log(error);
          return Promise.reject(error.response);
      }      
  };
}

export function revertTransactionSuccess(orderId){
    return {
        type: 'REVERT_ORDER_SUCCESS',
        orderId
    };
}
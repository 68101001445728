import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StatisticData from '../stateless/StatisticData';
import { getTotalStats } from '../../actions/branchAction';
import { getCityList } from '../../actions/cityAction';
import { getReverseDate } from '../../services/common';
import StatsBranchInfo from './StatsBranchInfo';

class Statistic extends Component {
  state = {
    totalUsers: 0,
    isTotalUsersLoaded: false,
    totalTransactionCount: 0,
    isTotalTransactionsLoaded: false,
    totalTransactionAmount: 0,
    isTotalTransactionAmountLoaded: false,
    branchTransactionStatsList: [],
    isBranchTransactionStatsLoaded: false,
    startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    sortingValue: 0,
    clientCount: 0,
    transactionCount: 0,
    transactionAmount: 0,
    isLoyalClientStatsLoaded: false,
    cityId: '0',
    cityListOptions: [],
    branchId: null
  };

  componentDidMount() {
    this.props.getCityList('/api/v1/cities');
    this.getAllTotals();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cityList !== this.props.cityList) {
      var cityListOptions = [
        {
          key: 0,
          value: '0',
          text: 'Все города'
        }
      ];
      this.props.cityList.forEach(el => {
        const obj = {
          key: el.id,
          value: el.id.toString(),
          text: el.name
        };
        cityListOptions.push(obj);
      });
      this.setState({ cityListOptions });
    }
  }

  getAllTotals = () => {
    const { startDate, endDate, sortingValue, cityId } = this.state;
    let filter =
      `?to=${getReverseDate(endDate)}${startDate ?
        `&from=${getReverseDate(startDate)}` : ''}${cityId !== '0' ?
          `&city_id=${cityId}` : ''}${sortingValue !== 0 ?
            `&sort_filter_type=${sortingValue}` : ''}`;
    this.setState({
      isTotalUsersLoaded: false,
      isTotalTransactionsLoaded: false,
      isTotalTransactionAmountLoaded: false,
      isBranchTransactionStatsLoaded: false,
      isLoyalClientStatsLoaded: false
    }, () => {
      this.props.getTotalStats('/api/v1/analytics/total_users' + filter)
        .then(function (res) {
          if (res.data.message === "Success") {
            this.setState({
              totalUsers: res.data.data
            }, () => {
              this.setState({ isTotalUsersLoaded: true });
            })
          }
        }.bind(this));

      this.props.getTotalStats('/api/v1/analytics/total_transactions_count' + filter)
        .then(function (res) {
          if (res.data.message === "Success") {
            this.setState({
              totalTransactionCount: res.data.data
            }, () => {
              this.setState({ isTotalTransactionsLoaded: true })
            })
          }
        }.bind(this));

      this.props.getTotalStats('/api/v1/analytics/total_transactions_amount' + filter)
        .then(function (res) {
          if (res.data.message === "Success") {
            this.setState({
              totalTransactionAmount: res.data.data
            }, () => {
              this.setState({ isTotalTransactionAmountLoaded: true })
            })
          }
        }.bind(this));

      this.props.getTotalStats('/api/v1/analytics/returned_users' + filter)
        .then(function (res) {
          if (res.data.message === "Success") {
            const { count, transaction_count, transaction_amount } = res.data.data;
            this.setState({
              clientCount: count,
              transactionCount: transaction_count,
              transactionAmount: transaction_amount
            }, () => {
              this.setState({ isLoyalClientStatsLoaded: true })
            })
          }
        }.bind(this));

      this.props.getTotalStats('/api/v1/analytics/branches_transaction_amount' + filter)
        .then(function (res) {
          if (res.data.message === "Success") {
            const list = res.data.data;
            this.setState({
              branchTransactionStatsList: list ? list : []
            }, () => {
              this.setState({ isBranchTransactionStatsLoaded: true })
            })
          }
        }.bind(this));
    })
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.getAllTotals();
    });
  }

  onSelectChange = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => {
      this.getAllTotals();
    });
  }

  clearDates = () => {
    this.setState({
      startDate: '',
      endDate: new Date().toISOString().split('T')[0],
      cityId: '0'
    }, () => {
      this.getAllTotals();
    })
  }

  numberWithCommas = (x) => {
    if (x || x === 0) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return "";
  }

  sortTableByColumn = (sortingValue) => {
    this.setState({ sortingValue }, () => { this.getAllTotals() });
  }

  //for toggling the Modal
  handleModalToggle = (branchId) => this.setState({ modalOpen: !this.state.modalOpen }, () => {
    this.setState({ branchId });
  });

  render() {
    return (
      <>
        <StatisticData
          {...this.state}
          onChange={this.onChange}
          onSelectChange={this.onSelectChange}
          numberWithCommas={this.numberWithCommas}
          sortTableByColumn={this.sortTableByColumn}
          clearDates={this.clearDates}
          handleModalToggle={this.handleModalToggle}
        />
        {this.state.modalOpen &&
          <StatsBranchInfo
            modalOpen={this.state.modalOpen}
            handleModalToggle={this.handleModalToggle}
            branchId={this.state.branchId}
            numberWithCommas={this.numberWithCommas}
          />
        }
      </>
    );
  }
}

Statistic.propTypes = {
  getTotalStats: PropTypes.func,
  requestInProcess: PropTypes.bool,
  getCityList: PropTypes.func,
  cityList: PropTypes.array
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTotalStats: (url) => dispatch(getTotalStats(url)),
    getCityList: (url) => dispatch(getCityList(url)),
  };
};

const mapStateToProps = (state) => {
  return {
    requestInProcess: state.requestInProcess,
    cityList: state.cityList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);
import axios from 'axios';

export function timeDiff() {
  const expireTime = sessionStorage.getItem("tokenExpireTime");
  if (new Date(expireTime) < new Date())
    return true;
  else
    return false;
}

export function getNewToken(oldToken) {
  // console.log('token expired');
  const config = {
    headers: {
      "Authorization": `Bearer ${oldToken}`
    }
  }
  return axios.post("/api/v1/auth/refresh_token", {}, config)
    .then(function (response) {
      if (response.status === 200) {
        // console.log(response);
        sessionStorage.setItem("tokenExpireTime", response.data.data.expire);
        sessionStorage.setItem("tokenInfo", response.data.data.access_token);
      }
      return response;
    }, function (error) {
      // console.log(error);
      return Promise.reject(error.response);
    }
    )
}

export function getParsedDate(date) {  //1585437308717, 1585414534142
  if (date != null && date !== 0) {
    date = String(date).split('T');
    var days = String(date[0]).split('-');
    return days[2] + "." + days[1] + "." + days[0];
  }

  return '';
}

export function getParsedDateTime(date) {
  if (date != null) {
    var d = new Date(date);
    var hour = addZero(d.getHours());
    var minute = addZero(d.getMinutes());
    date = String(date).split('T');
    var days = String(date[0]).split('-');
    return days[2] + "." + days[1] + "." + days[0] + " | " + hour + ":" + minute;
  }

  return '';
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export function getReverseDate(date) {
  date = String(date).split('-');
  return date[2] + "-" + date[1] + "-" + date[0];
}

export function isEmpty(value) {
  if ((Object.keys(value).length === 0 && value.constructor === Object) ||
    value === "" || value === null || value === undefined ||
    value.length === 0)
    return true;
  return false;
}
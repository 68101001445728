import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserList, emptyUserList, emptyBUserList, searchList, grantStarToUser, revokeStarFromUser } from '../../actions/userAction';
import { Grid, Button, Icon, Input, Dimmer, Loader, Popup, Modal, Form } from 'semantic-ui-react'
import InfiniteScroll from "react-infinite-scroll-component";
import _ from 'lodash';

class UserList extends Component {
  state = {
    userList: [],
    hasMore: true,
    phone: '',
    height: 512,
    userId: '',
    modalOpen: false,
    price: '',
    description: '',
  };

  searchUsersByPhone = (phone) => {
    this.props.searchList('/api/v1/promoters/find/user?limit=20&phone=' + phone)
      .then(res => {
        if (res.status === 200) {
          this.setState({ hasMore: res.data.data.length < 20 ? false : true });
        }
      });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    const searchParams = new URLSearchParams(this.props.location.search);
    const phone = searchParams.get('phone');
    if (phone) {
      this.setState({ phone });
      this.searchUsersByPhone(phone);
    }
    else this.props.getUserList('/api/v1/user');
  }

  updateWindowDimensions() {
    this.setState({ height: window.outerHeight - 200 });
  }

  componentDidUpdate(prevProps) {
    //console.log(prevProps);
    if (prevProps.userList !== this.props.userList) {
      this.setState({ userList: this.props.userList })
    }
  }

  componentWillUnmount() {
    this.props.emptyUserList();
    this.props.emptyBUserList();
  }

  onChange = (e) => {
    const { value, name } = e.target;

    // console.log(val, name);
    this.setState({
      [name]: value
    }, () => {
      if (name === 'phone') {
        const params = new URLSearchParams({ [name]: value });
        this.props.history.replace({ search: params.toString() });
        if (value === '') {
          this.props.emptyUserList();
          this.props.getUserList('/api/v1/user');
        }
        else {
          this.searchUsersByPhone(value);
        }
      }
    });
  }

  fetchMoreData = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    let query = '';
    if (searchParams.size > 0) query = searchParams.toString();
    this.props.getUserList(`/api/v1/user?limit=10&${query}&before=${this.props.lastItemId}`)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            const arrLength = res.data.data.length;
            if (arrLength === 0) {
              this.setState({ hasMore: false });
              return;
            }
          }
        }
      });
  };

  handleModalToggle = (id) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      userId: id
    })
  }

  grantRevokeStar = (userId, grantOrRevoke) => {
    const { price, description } = this.state;
    if (grantOrRevoke === 'grant') {
      const data = {
        role: 'simple'
      }
      if (price !== '') data.price = Number(price);
      if (description !== '') data.description = description;

      this.props.grantStarToUser('/api/v1/stars/' + userId + '/grant', data)
        .then(res => {
          if (res.status === 200) this.setState({ modalOpen: false });
        });
    } else {
      this.props.revokeStarFromUser('/api/v1/stars/' + userId + '/revoke');
    }
  }

  //directs to the user info page
  goToUserInfo(id) {
    this.props.history.push('/user/' + id);
  }

  render() {
    const { userList, phone, height, userId, modalOpen, price, description } = this.state;
    return (
      <div style={{ paddingLeft: '5px', height: '100%' }}>
        <Grid style={{ margin: '0' }}>
          <Grid.Row color='blue' verticalAlign='middle'>
            <Grid.Column width={1}>№</Grid.Column>
            <Grid.Column width={2}>
              User Name
            </Grid.Column>
            <Grid.Column width={2}>
              Phone
              <Input size='mini'
                placeholder='7 701 222 3344'
                name='phone'
                value={phone}
                onChange={this.onChange}
                style={{ width: "100%" }}
              />
            </Grid.Column>
            <Grid.Column width={1}>
              Gender
            </Grid.Column>
            <Grid.Column width={2}>
              Amount
            </Grid.Column>
            <Grid.Column width={2}>
              Transactions
            </Grid.Column>
            <Grid.Column width={2}>
              Cashback amount
            </Grid.Column>
            <Grid.Column width={2}>
              Influencer
            </Grid.Column>
            <Grid.Column width={2}>
              Action
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <InfiniteScroll
          dataLength={userList.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
          height={height}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more data to load!</b>
            </p>
          }
        >
          <Grid celled style={{ marginTop: '0' }}>
            {userList.map((user, index) => {
              const isInfluencer = _.has(user, 'star') ? user.star.type === 'blocked' ? false : true : false;
              return (
                <Grid.Row key={user.id}>
                  <Grid.Column width={1}>{index + 1}</Grid.Column>
                  <Grid.Column width={2}>{user.first_name} {user.last_name}</Grid.Column>
                  <Grid.Column width={2}>{user.phone}</Grid.Column>
                  <Grid.Column width={1}>{user.gender}</Grid.Column>
                  <Grid.Column width={2}>{user.amount}</Grid.Column>
                  <Grid.Column width={2}>{user.transactions}</Grid.Column>
                  <Grid.Column width={2}>{user.cashback_amount}</Grid.Column>
                  <Grid.Column width={2}>
                    <Popup
                      content={isInfluencer ? 'Revoke' : 'Grant star'}
                      trigger={<Icon size='large' color='green'
                        name={isInfluencer ? 'check square' : 'square outline'}
                        style={{ cursor: 'pointer' }}
                        onClick={isInfluencer ? () => this.grantRevokeStar(user.id, 'revoke') : () => this.handleModalToggle(user.id)}
                      />}
                    />
                  </Grid.Column>
                  <Grid.Column width={2} className='tableActions'>
                    <Button
                      className='rowEditBtn'
                      compact color='blue'
                      onClick={() => this.goToUserInfo(user.id)}
                    >
                      <Icon name='eye' />
                      Open
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              )
            })}
          </Grid>
        </InfiniteScroll>
        {modalOpen &&
          <Modal
            centered={false} size='mini'
            open={modalOpen}
            onClose={() => this.handleModalToggle('')}
          >
            <Modal.Content>
              <Form size='mini' onSubmit={() => this.grantRevokeStar(userId, 'grant')}>
                <Grid>
                  <Grid.Row width={16}>
                    <Grid.Column>
                      <Form.Input
                        fluid label='Price' type='number'
                        placeholder='0'
                        value={price} name="price"
                        onChange={this.onChange}
                      />
                      <Form.TextArea
                        label='Description' rows='5'
                        placeholder='description...'
                        value={description}
                        name="description"
                        onChange={this.onChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row width={16}>
                    <Grid.Column>
                      <Button color='green' size='mini'
                        type='submit' floated='right'>
                        <Icon name='add' />
                        Grant star
                      </Button>
                      <Button color='orange' size='mini'
                        type='button' floated='right'
                        onClick={() => this.handleModalToggle('')}>
                        <Icon name='cancel' /> Cancel
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Content>
          </Modal>
        }
        <Dimmer active={this.props.requestInProcess} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      </div>
    );
  }
}

UserList.propTypes = {
  getUserList: PropTypes.func,
  userList: PropTypes.array,
  requestInProcess: PropTypes.bool,
  lastItemId: PropTypes.string,
  searchList: PropTypes.func,
  emptyUserList: PropTypes.func,
  emptyBUserList: PropTypes.func,
  grantStarToUser: PropTypes.func,
  revokeStarFromUser: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (url) => dispatch(getUserList(url)),
    searchList: (url) => dispatch(searchList(url)),
    emptyUserList: () => dispatch(emptyUserList()),
    emptyBUserList: () => dispatch(emptyBUserList()),
    grantStarToUser: (url, data) => dispatch(grantStarToUser(url, data)),
    revokeStarFromUser: (url) => dispatch(revokeStarFromUser(url))
  };
};

const mapStateToProps = (state) => {
  return {
    requestInProcess: state.requestInProcess,
    userList: state.userList,
    lastItemId: state.lastItemId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
export function menuList(state = [], action) {
    switch (action.type) {
        case 'GET_MENU_LIST_SUCCESS':
            return action.list;
        case 'ADD_MENU_TO_LIST':
            return [...state, action.menu];
        case 'UPDATE_MENU_SUCCESS':
            const index = state.findIndex(x => x.id === action.menu.id);
            return [...state.slice(0, index), action.menu, ...state.slice(index + 1)];
        case 'DELETE_MENU_SUCCESS':
            return state.filter(item => item.id !== action.menuId);
        default:
            return state;
    }
}
import React from 'react';
import {Grid, Button, Table, Input, Icon} from 'semantic-ui-react';

const GradationData = (props) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    <Button compact style={{marginBottom: '10px'}}
                            color={props.gradationAvailable ? 'red' : 'green'}
                            onClick={props.gradationAvailable ? props.activateDeactivateGradation.bind(this, false) : props.activateDeactivateGradation.bind(this, true)}>
                        {props.gradationAvailable ? 'Deactivate Grad. cashback' : 'Activate Grad. cashback'}
                    </Button>
                </Grid.Column>
            </Grid.Row>
            {props.gradationAvailable &&
                <Grid.Row width='1'>
                    <Grid.Column width='4'>New cashback</Grid.Column>
                    <Grid.Column width='4'>Amount</Grid.Column>
                    <Grid.Column width='8'></Grid.Column>
                    <Grid.Column width='4'>
                        <Input 
                            name='newCashback'
                            value={props.newCashback}
                            type='number'
                            onChange={props.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width='4'>
                        <Input 
                            name='amount'
                            value={props.amount}
                            type='number'
                            onChange={props.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width='8'>
                        <Button size='mini' primary onClick={props.addExtraGradationCashback}>Add</Button>
                    </Grid.Column>
                    <Table celled striped color='blue'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>
                                    Extra cashback
                                </Table.HeaderCell>
                                <Table.HeaderCell>  
                                    Amount
                                </Table.HeaderCell>
                                <Table.HeaderCell>  
                                    Action
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        {props.gradationCashbackList.length > 0 &&
                            <Table.Body>
                                {props.gradationCashbackList.map((gradation, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>{index + 1}</Table.Cell>
                                        <Table.Cell>{gradation.extra_cashback}</Table.Cell>
                                        <Table.Cell>{gradation.amount}</Table.Cell>
                                        <Table.Cell>
                                            <Button className='rowDeleteBtn' compact color='red'
                                                onClick={props.deleteExtraGradationCashback.bind(this, gradation.id)}>
                                            <Icon name='trash' />
                                            Delete
                                        </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))} 
                            </Table.Body>
                        }
                        {!props.gradationCashbackList.length > 0 &&
                            <Table.Body> 
                                <Table.Row> 
                                    <Table.Cell style={{textAlign: 'center'}} colSpan='12'>Пусто</Table.Cell>
                                </Table.Row> 
                            </Table.Body>
                        }
                    </Table>
                </Grid.Row>
            }
        </Grid>
    )
};
export default GradationData;
import React from 'react';
import { Grid, Button, Image } from 'semantic-ui-react';

const WalletData = (props) => {
  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Button compact style={{ marginBottom: '10px' }}
            color={'green'}
            onClick={() => props.generateWalletQr()}
          >
            Получить Wallet QR
          </Button>
        </Grid.Column>
        {props.walletQr &&
          <Grid.Column>
            <Image
              style={{ height: '250px', width: '250px' }}
              src={props.walletQr}
            />
          </Grid.Column>
        }
      </Grid.Row>
    </Grid>
  )
};
export default WalletData;
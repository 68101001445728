import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getOrderList, emptyOrderList} from '../../actions/orderAction';
import {Grid, Header, Button, Icon, Dimmer, Loader} from 'semantic-ui-react';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import {getParsedDateTime} from '../../services/common';

class WithdrawalList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderList: [],
            hasMore: true,
            orderId: '',
            height: 512
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        this.props.getOrderList('/api/v1/orders?type=withdraw&limit=20');
    }

    updateWindowDimensions() {
        this.setState({ height: window.outerHeight - 230 });
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if (prevProps.orderList !== this.props.orderList) {
            this.setState({orderList: this.props.orderList})
        }
    }

    componentWillUnmount() {
        //need to empty the array from reducer when leaving the component
        this.props.emptyOrderList();
    }

    onChange(e) {
        const value = e.target.value;
        const name = e.target.name;
        // console.log(value, name);
        this.setState({
            [name]: value
        });
    }

    //directs to the order details page
    goToOrder(orderId, branchId) {
        sessionStorage.setItem('orderBranchId', branchId)
        this.props.history.push('/withdrawal/' + orderId);
    }

    //load more orderes
    fetchMoreData = () => {
        this.props.getOrderList('/api/v1/orders?type=withdraw&limit=20&before=' + this.props.lastItemId)
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        var arrLength = res.data.data.length;
                        if (arrLength === 0) {
                            this.setState({hasMore: false});
                            return;
                        }
                    }
                }
            });
    };

    render() {
        const {orderList, height} = this.state;

        return (
            <div style={{paddingLeft: '5px', height: '100%'}}>
                <Grid style={{margin: '0'}}>
                    <Grid.Row columns='equal'>
                        <Grid.Column></Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h3'>Withdrawal List</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Button color='teal' floated='right' compact
                                    onClick={this.handleModalToggle}>
                                <Icon name='add'/>
                                Add new
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1} color='blue'>№</Grid.Column>
                        <Grid.Column width={3} color='blue'>
                            Branch Name
                        </Grid.Column>
                        <Grid.Column width={4} color='blue'>
                            Address
                        </Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Order amount
                        </Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Order date | time
                        </Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Order status
                        </Grid.Column>
                        <Grid.Column width={2} color='blue'>
                            Action
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <InfiniteScroll
                    dataLength={orderList.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<h4>Loading...</h4>}
                    height={height}
                    endMessage={
                        <p style={{textAlign: "center"}}>
                            <b>No more data to load!</b>
                        </p>
                    }
                >
                    <Grid celled style={{marginTop: '0'}}>
                        {orderList.map((order, index) => (
                            <Grid.Row key={order.id} verticalAlign='middle'>
                                <Grid.Column width={1}>{index + 1}</Grid.Column>
                                <Grid.Column width={3}>
                                    {order.recipient.branch_name}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    {order.recipient.branch_address}
                                </Grid.Column>
                                <Grid.Column width={2}
                                             style={order.order_receiver_amount < 0 ? {color: 'red'} : {color: 'green'}}>
                                    {order.order_receiver_amount < 0 ? order.order_receiver_amount * -1 : order.order_receiver_amount} tg.
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    {getParsedDateTime(moment(new Date(order.created_at)).format())}
                                </Grid.Column>
                                <Grid.Column width={2}
                                             style={order.status === 'DECLINED' ? {color: 'red'} : order.status === 'COMPLETED' ? {color: 'green'} : {color: 'grey'}}>
                                    {order.status}
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Button size='mini' inverted color='blue'
                                            onClick={this.goToOrder.bind(this, order.id, order.recipient.branch_id)}>
                                        <Icon name='eye'/>
                                        View
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        ))}
                    </Grid>
                </InfiniteScroll>
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading'/>
                </Dimmer>
            </div>
        );
    }
}

WithdrawalList.propTypes = {
    getOrderList: PropTypes.func,
    orderList: PropTypes.array,
    requestInProcess: PropTypes.bool,
    lastItemId: PropTypes.string,
    emptyOrderList: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrderList: (url) => dispatch(getOrderList(url)),
        emptyOrderList: () => dispatch(emptyOrderList())
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        orderList: state.orderList,
        lastItemId: state.lastItemId
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalList);
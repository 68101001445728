import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const DatePickerBasic = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {props.label && <span>{props.label}</span>}
      <SemanticDatepicker
        className='datepicker-basic'
        placeholder={props.name === "expire_date" ? 'Срок' : 'дд.мм.гггг'}
        format="DD-MM-YYYY"
        name={props.name}
        value={props.value}
        filterDate={props.filterDate}
        locale="ru-RU" onChange={props.onDateChange}
      />
    </div>
  );
};

export default DatePickerBasic;
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { requestHasErrored, requestInProcess } from './commonAction';

export function updateToken(token) {
    return {
        type: 'TOKEN_UPDATE_SUCCESS',
        data: token
    };
}

export function login(url, data) {
    return (dispatch) => {
        dispatch(requestInProcess(true));

        return axios.post(url, data)
            .then(function (response) {
                if (response.status === 200) {
                    // console.log(response);
                    var decoded = jwt_decode(response.data.data.access_token);
                    // console.log(decoded.role); //decoded.role returns role name
                    sessionStorage.setItem("tokenInfo", response.data.data.access_token);
                    sessionStorage.setItem("tokenExpireTime", response.data.data.expire);
                    sessionStorage.setItem("role", decoded.role);
                    dispatch(updateToken(response.data.data.access_token));
                }
                return response;
            }).catch(error => {
                // console.log(error.response);
                dispatch(requestHasErrored(true));
                return Promise.reject(error.response);
            }).finally(() => {
                dispatch(requestInProcess(false));
            })
    };
}
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Button, Icon, Image, Dimmer, Loader, Modal, Form, Confirm, Input, Pagination, Popup } from 'semantic-ui-react';
import moment from 'moment';

import { getBranchList, createBranch, emptyBranchList, handleRemove, cloneBranch } from '../../actions/branchAction';
import { getCategoryList } from '../../actions/categoryAction';
import { getCityList } from '../../actions/cityAction';
import { getParsedDate } from '../../services/common';

class BranchList extends Component {
  state = {
    branchList: [],
    show_disabled: sessionStorage.getItem('showDisabled') ? sessionStorage.getItem('showDisabled') : 'true',
    bin: '',
    branchId: '',
    branchName: '',
    phone: '',
    phoneList: [],
    cityId: '',
    categoryId: '',
    categoryListOptions: [],
    cityListOptions: [],
    subCategOptions: [],
    subCategIdList: [],
    confirmOpen: false,
    query: sessionStorage.getItem('query') ? sessionStorage.getItem('query') : '',
    height: 512,
    activePage: sessionStorage.getItem("branchActivePage")
      ? Number(sessionStorage.getItem("branchActivePage"))
      : 1,
    boundaryRange: 1,
    siblingRange: 1,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
    totalPages: 1,
    sortingValue: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    this.props.getCityList('/api/v1/cities');
    const { show_disabled, activePage } = this.state;
    const cityId = sessionStorage.getItem('cityId') ? sessionStorage.getItem('cityId') : '1'
    this.loadData(show_disabled, cityId, activePage);
  }

  updateWindowDimensions() {
    this.setState({ height: window.outerHeight - 230 });
  }

  componentDidUpdate(prevProps) {
    //console.log(prevProps);
    if (prevProps.branchList !== this.props.branchList) {
      this.setState({ branchList: this.props.branchList })
    }

    if (prevProps.categoryList !== this.props.categoryList) {
      const { categoryList } = this.props;
      var categoryListOptions = [];
      categoryList.forEach(element => {
        var obj = {
          key: element.id,
          value: element.id,
          text: element.name
        }
        categoryListOptions.push(obj);
      });
      this.setState({ categoryListOptions });
    }

    if (prevProps.cityList !== this.props.cityList) {
      var cityListOptions = [];
      this.props.cityList.forEach(el => {
        const obj = {
          key: el.id,
          value: el.id.toString(),
          text: el.name
        };
        cityListOptions.push(obj);
      });
      this.setState({ cityListOptions });
    }
  }

  componentWillUnmount() {
    //need to empty the array from reducer when leaving the component
    this.props.emptyBranchList();
  }

  loadData = (show_disabled, cityId, activePage) => {
    const { query, sortingValue } = this.state;
    const callCategories = this.state.cityId !== cityId ? true : false;
    if (callCategories) this.props.getCategoryList('/api/v1/categories?city_id=' + cityId);
    sessionStorage.setItem('showDisabled', show_disabled);
    sessionStorage.setItem('cityId', cityId);
    sessionStorage.setItem("branchActivePage", activePage);
    this.setState({ show_disabled, cityId, activePage });
    const skip = (activePage - 1) * 20;
    const addQuery = query ? `&query=${query}${sortingValue !== 0 ? `&sort_filter_type=${sortingValue}` : ''}` : `${sortingValue !== 0 ? `&sort_filter_type=${sortingValue}` : ''}`;
    this.props.getBranchList('/api/v1/branches?skip=' + skip + '&show_disabled=' + show_disabled + '&city_id=' + cityId + addQuery)
      .then(res => {
        if (res.status === 200) {
          const { count } = res.data.data;
          this.setState({ totalPages: Math.ceil(count / 20) })
        }
      });
  }

  sortTableByColumn = (sortingValue) => {
    const {show_disabled, cityId, activePage} = this.state;
    this.setState({ sortingValue }, () => { this.loadData(show_disabled, cityId, activePage) });
  }

  onChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    this.setState({
      [name]: value
    }, () => {
      if (name === 'query') {
        sessionStorage.setItem('query', value);
        const { show_disabled, cityId } = this.state;
        this.loadData(show_disabled, cityId, 1);
      }
    });
  }

  onSelectChange = (e, data) => {
    const { name, value } = data;

    if (name === 'categoryId') {
      const subCategList = this.props.subCategoryList.filter(x => x.parent_id === value);
      var subCategOptions = [];
      subCategList[0].list.forEach(el => {
        const obj = {
          key: el.id,
          value: el.id,
          text: el.name
        };
        subCategOptions.push(obj);
      });
      this.setState({ subCategOptions });
    }
    else if (name === 'subcategory') {
      this.setState({
        subCategIdList: value
      });
    }
    else if (name === 'cityId') {
      this.props.getCategoryList('/api/v1/categories?city_id=' + value);
      this.setState({ categoryId: '' }, () => {
        this.loadData(this.state.show_disabled, value, 1);
      });
    }
    this.setState({
      [name]: value
    });
  }

  //directs to the branch details page
  goToBranch(id) {
    this.props.history.push('/branch/' + id);
  }

  //for toggling the Modal
  handleModalToggle = () => this.setState({ modalOpen: !this.state.modalOpen });

  handlePaginationChange = (e, { activePage }) => {
    this.loadData(this.state.show_disabled, this.state.cityId, activePage);
  };

  //to put phone numbers as an array
  separatePhoneNum() {
    var { phone, phoneList } = this.state;
    if (phone.includes(",")) {
      var devidedParts = phone.split(",");
      devidedParts.forEach(element => {
        phoneList.push(element.trim());
      });
    }
    else {
      phoneList.push(phone.trim());
    }

    this.setState({ phoneList });
  }

  //creating new branch
  createBranch() {
    this.setState({ createBranchLoader: true })
    const { bin, branchName, categoryId, phoneList, cityId, subCategIdList } = this.state;
    this.separatePhoneNum(); //have to call before data is created

    subCategIdList.unshift(categoryId);
    var data = {
      category_id: categoryId,
      organization_id: bin,
      phones: phoneList,
      city_id: typeof cityId === 'number' ? cityId.toString() : cityId,
      categories: subCategIdList,
      name: branchName
    }

    this.props.createBranch('/api/v1/branches', data)
      .then(res => {
        if (res) {
          this.setState({
            modalOpen: false,
            createBranchLoader: false
          });
        }
        else {
          this.setState({
            createBranchLoader: false
          });
        }
      });
  }

  //creates a copy of the branch
  handleCloneBranch(branchId) {
    const selectedBranch = this.state.branchList.find(b => b.id === branchId);
    const branch = {
      name: selectedBranch.name,
      address: selectedBranch.address,
      icon: ''
    }
    this.props.cloneBranch('/api/v1/branches/' + branchId + '/clone', branch);
  }

  // calls confirmation window for deleting the branch
  handelConfirmation = (branchId) => {
    this.setState({ confirmOpen: !this.state.confirmOpen, branchId });
  }

  //removes the branch
  deleteBranch(branchId) {
    this.props.handleRemove('/api/v1/branches/' + branchId, branchId)
      .then(function (res) {
        if (res.status === 200) {
          this.setState({
            confirmOpen: false
          });
        }
      }.bind(this), function (error) {
        if (error) {
          this.setState({
            confirmOpen: false
          }, function () {
            alert(error.data.message);
          });
        }
      }.bind(this));
  }

  switchBranchType = (bool) => {
    this.setState({ query: '' }, () => {
      const { cityId } = this.state;
      this.loadData(bool, cityId, 1);
    })
  }

  render() {
    const { branchList, modalOpen, bin, branchId, branchName, phone, cityId, categoryId,
      categoryListOptions, subCategOptions, subCategIdList, cityListOptions, confirmOpen,
      query, show_disabled, createBranchLoader, activePage, boundaryRange, siblingRange,
      showEllipsis, showFirstAndLastNav, showPreviousAndNextNav, totalPages, sortingValue } = this.state;

    return (
      <div style={{ paddingLeft: '5px', height: '100%' }}>
        <Grid style={{ margin: '0' }}>
          <Grid.Row columns='equal'>
            <Grid.Column>
              <Input size='mini'
                placeholder='search by name'
                name='query'
                value={query}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column textAlign='center' verticalAlign='middle'>
              <Header as='h3'>{show_disabled === 'true' ? 'All branches' : 'Active branches'}</Header>
            </Grid.Column>
            <Grid.Column>
              <Button color='teal' floated='right' compact
                onClick={this.handleModalToggle}>
                <Icon name='add' />
                Add new
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal' style={{ paddingTop: '0' }}>
            <Grid.Column textAlign='center'>
              <Button color='blue' inverted={show_disabled === 'false'}
                onClick={() => this.switchBranchType('true')}
                style={{ width: '150px', borderRadius: '5px' }}>
                All
              </Button>
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <Form.Select name='cityId'
                options={cityListOptions}
                label='Город:'
                value={cityId || ''}
                onChange={this.onSelectChange}
              />
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <Button color='blue' inverted={show_disabled === 'true'}
                onClick={() => this.switchBranchType('false')}
                style={{ width: '150px', borderRadius: '5px' }}>
                Active
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={1} color='blue'>№</Grid.Column>
            <Grid.Column width={2} color='blue'>
              Icon
            </Grid.Column>
            <Grid.Column width={3} color='blue'>
              Branch Name
            </Grid.Column>
            <Grid.Column width={4} color='blue'>
              Address
            </Grid.Column>
            <Grid.Column width={3} color='blue'>
              Subscription expire date
              <Icon
                name="sort"
                style={{ cursor: 'pointer' }}
                onClick={() => this.sortTableByColumn(Math.abs(sortingValue) === 1 ? sortingValue * -1 : 1)}
              />
            </Grid.Column>
            <Grid.Column width={3} color='blue'>
              Action
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid celled style={{ marginTop: '0' }}>
          {branchList.length > 0 ?
            <Fragment>
              <Grid.Row verticalAlign='middle'>
                {branchList.map((branch, index) => (
                  <Fragment key={branch.id}>
                    <Grid.Column width={1}>{(activePage - 1) * 20 + (index + 1)}</Grid.Column>
                    <Grid.Column width={2} textAlign='center'>
                      <Image
                        style={{ height: '80px', width: '80px' }}
                        avatar size='tiny'
                        src={branch.icon === '' ? require('../../assets/img/no_image.png') : branch.icon}
                      />
                    </Grid.Column>
                    <Grid.Column width={3}>{branch.name}</Grid.Column>
                    <Grid.Column width={4}>{branch.address}</Grid.Column>
                    <Grid.Column width={3}>{getParsedDate(moment(new Date(branch.subscription.expired_at)).format())}</Grid.Column>
                    <Grid.Column width={3} className='tableActions'>
                      <Popup content='View' trigger={<Button color='blue' icon='eye' onClick={() => this.goToBranch(branch.id)} />} />
                      <Popup content='Clone' trigger={<Button color='green' icon='copy' onClick={() => this.handleCloneBranch(branch.id)} />} />
                      <Popup content='Delete' trigger={<Button color='red' icon='trash' onClick={() => this.handelConfirmation(branch.id)} />} />
                    </Grid.Column>
                  </Fragment>
                ))}
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='center'>
                  <Pagination
                    activePage={activePage}
                    boundaryRange={boundaryRange}
                    onPageChange={this.handlePaginationChange}
                    size="mini"
                    siblingRange={siblingRange}
                    totalPages={totalPages}
                    // Heads up! All items are powered by shorthands, if you want to hide one of them, just pass `null` as value
                    ellipsisItem={showEllipsis ? undefined : null}
                    firstItem={showFirstAndLastNav ? undefined : null}
                    lastItem={showFirstAndLastNav ? undefined : null}
                    prevItem={showPreviousAndNextNav ? undefined : null}
                    nextItem={showPreviousAndNextNav ? undefined : null}
                  />
                </Grid.Column>
              </Grid.Row>
            </Fragment> :
            <Grid.Row columns='equal'>
              <Grid.Column textAlign='center'>
                Нет данных
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
        <Modal
          centered={false} size='mini'
          open={modalOpen}
          onClose={this.handleModalToggle}
        >
          <Modal.Content>
            <Form size='mini' onSubmit={this.createBranch.bind(this)}>
              <Grid>
                <Grid.Row width={16}>
                  <Grid.Column>
                    <Form.Input
                      fluid label='БИН'
                      placeholder='123456789012' required
                      value={bin}
                      name="bin"
                      onChange={this.onChange}
                    />
                    <Form.Input
                      fluid label='Название филиала'
                      placeholder='Name...' required
                      value={branchName}
                      name="branchName"
                      onChange={this.onChange}
                    />
                    <Form.Input
                      fluid label='Телефоны'
                      placeholder='+77773335577, +77782345678...' required
                      value={phone}
                      name="phone"
                      onChange={this.onChange}
                    />
                    <Form.Select fluid required
                      label='Город'
                      options={cityListOptions}
                      name='cityId'
                      placeholder='Choose city...'
                      value={cityId}
                      onChange={this.onSelectChange}
                    />
                    <Form.Select fluid required
                      label='Категория'
                      options={categoryListOptions}
                      name='categoryId'
                      placeholder='Categories...'
                      value={categoryId}
                      onChange={this.onSelectChange}
                    />
                    {categoryId !== '' &&
                      <Form.Select fluid required
                        label='Подкатегории'
                        options={subCategOptions}
                        multiple name='subcategory'
                        placeholder='Sub categories...'
                        value={subCategIdList}
                        onChange={this.onSelectChange}
                      />
                    }
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row width={16}>
                  <Grid.Column>
                    <Button color='green' size='mini'
                      type='submit' floated='right'>
                      <Icon name='add' />
                      Create
                    </Button>
                    <Button color='orange' size='mini'
                      type='button' floated='right'
                      onClick={this.handleModalToggle}>
                      <Icon name='cancel' /> Cancel
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {createBranchLoader &&
                <Dimmer active={createBranchLoader} inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
              }
            </Form>
          </Modal.Content>
        </Modal>
        <Dimmer active={this.props.requestInProcess} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
        <Confirm
          open={confirmOpen}
          content='Вы действительно хотите удалить?'
          cancelButton='Отмена'
          confirmButton="Удалить"
          onCancel={this.handelConfirmation.bind(this, '')}
          onConfirm={this.deleteBranch.bind(this, branchId)}
        />
      </div>
    );
  }
}

BranchList.propTypes = {
  getBranchList: PropTypes.func,
  branchList: PropTypes.array,
  lastItemId: PropTypes.string,
  requestInProcess: PropTypes.bool,
  getCategoryList: PropTypes.func,
  categoryList: PropTypes.array,
  subCategoryList: PropTypes.array,
  getCityList: PropTypes.func,
  cityList: PropTypes.array,
  createBranch: PropTypes.func,
  emptyBranchList: PropTypes.func,
  handleRemove: PropTypes.func,
  cloneBranch: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: (url) => dispatch(getBranchList(url)),
    getCategoryList: (url) => dispatch(getCategoryList(url)),
    getCityList: (url) => dispatch(getCityList(url)),
    createBranch: (url, data) => dispatch(createBranch(url, data)),
    emptyBranchList: () => dispatch(emptyBranchList()),
    handleRemove: (url, branchId) => dispatch(handleRemove(url, branchId)),
    cloneBranch: (url, branch) => dispatch(cloneBranch(url, branch))
  };
};

const mapStateToProps = (state) => {
  return {
    requestInProcess: state.requestInProcess,
    branchList: state.branchList,
    lastItemId: state.lastItemId,
    categoryList: state.categoryList,
    subCategoryList: state.subCategoryList,
    cityList: state.cityList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchList);
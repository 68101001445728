import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBranchDetails } from '../../actions/branchAction';
import { getOrderInfo, updateStatus } from '../../actions/orderAction';
import { Grid, Button, Header, Image, Dimmer, Loader, Form, Icon } from 'semantic-ui-react';
import Message from '../stateless/Message';
import ContractData from '../stateless/ContractData';
import moment from 'moment';
import {getParsedDateTime} from '../../services/common';

class Order extends Component {
    constructor(props) {
        super(props);

        this.state = {
            branch: {},
            order: {},
            status: '',
            editIsOn: false,
            declineIsOn: false,
            reason: '',
            //for contract
            rule: {},
            //for Message
            positive: true,
            hidden: true,
            messageTitle: '',
            messageBody: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        const branchId = sessionStorage.getItem('orderBranchId');
        const {orderId} = this.props.match.params;
        this.props.getBranchDetails('/api/v1/branches/' + branchId + '/full');
        this.props.getOrderInfo('/api/v1/orders/' + orderId);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.branch !== this.props.branch){
            const {branch} = this.props;
            this.setState({
                branch,
                rule: branch.rule
            });
        }

        if(prevProps.order !== this.props.order){
            const {order} = this.props;
            this.setState({
                order,
                status: order.status
            });
        }
    }

    onChange(e) {
        const val = e.target.value;
        const name = e.target.name;
        // console.log(val, name);
        this.setState({
            [name]: val
        });
    }

    handleDeclineToggle = () => this.setState({ declineIsOn: !this.state.declineIsOn });

    updateOrderStatus(type){
        const {orderId} = this.props.match.params;
        var data = {
            type: type.toUpperCase()
        }
        if(type === 'decline')
            data.reason = this.state.reason;
        this.props.updateStatus('/api/v1/orders/' + orderId + '/withdraw/complete', data)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    status: type === 'complete' ? "COMPLETED" : "DECLINED",
                    positive: true,
                    hidden: false,
                    messageTitle:  type === 'complete' ? "COMPLETED" : "DECLINED",
                    messageBody: "Statuc was successfully updated."
                });

                setTimeout(() => {
                    this.setState({ hidden: true })
                }, 4000)
            }
        }.bind(this),function(error){
            this.setState({
                positive: false,
                hidden: false,
                messageTitle:  'Status was not updated.',
                messageBody: error.data.message
            })

            setTimeout(() => {
                this.setState({ hidden: true })
            }, 4000)
        }.bind(this))
    }

    render() {
        const { branch, order, status, declineIsOn, reason } = this.state;
        const {goBack} = this.props.history;

        return (
            <React.Fragment>
                <Grid style={{margin: '0'}}>
                    <Grid.Row columns={1}>
                        <Grid.Column style={{marginBottom: '10px'}}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={1} verticalAlign='middle'>
                                        <Button size='mini' onClick={goBack} color='vk'>Back</Button>
                                    </Grid.Column>
                                    <Grid.Column width={6} textAlign='right' verticalAlign='middle'>
                                        <Header as='h3'>{branch.name}</Header>
                                        <p>{branch.address}</p>
                                    </Grid.Column>
                                    <Grid.Column width={2} verticalAlign='middle'>
                                        <Image 
                                            style={{width: '5em', height: '5em', cursor: 'pointer'}} 
                                            avatar src={branch.icon === "" ? require('../../assets/img/no_image.png') : branch.icon}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2} verticalAlign='middle'>
                                        <Header as='h4'>Balance:</Header>
                                        <p style={{color: 'green'}}>
                                            {branch.balance ? +(Math.round(branch.balance + "e+2")  + "e-2") : ''}
                                        </p>
                                    </Grid.Column>
                                    <Grid.Column width={2} verticalAlign='middle'>
                                        {status === "PENDING" ? 
                                            <React.Fragment>
                                                <Header as='h4'>Withdraw amount:</Header>
                                                <p style={order.order_receiver_amount < 0 ? {color: 'red'} : {color: 'green'}}>
                                                    {order.order_receiver_amount < 0 ? order.order_receiver_amount * -1 : order.order_receiver_amount}
                                                </p>
                                            </React.Fragment> :
                                            <p style={status === "DECLINED" ? {color: 'red', fontWeight: 'bold'} : {color: 'green', fontWeight: 'bold'}}>
                                                {status}
                                            </p>
                                        }
                                    </Grid.Column>
                                    <Grid.Column width={3} verticalAlign='middle'>
                                        <Header as='h4'>Withdraw date | time:</Header>
                                        <p style={{color: 'green'}}>
                                            {order.created_at ? getParsedDateTime(moment(new Date(order.created_at)).format()) : ''}
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Message 
                                positive={this.state.positive}
                                hidden={this.state.hidden}
                                messageTitle={this.state.messageTitle}
                                messageBody={this.state.messageBody}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ContractData parent='order' {...this.state} />
                            {status === "PENDING" && 
                                <React.Fragment>
                                    {declineIsOn ? 
                                        <Form size='mini' onSubmit={this.updateOrderStatus.bind(this, 'decline')}>
                                            <Grid>
                                                <Grid.Row width={16}>
                                                    <Grid.Column>
                                                        <Form.TextArea 
                                                            label='Reason' rows='5'
                                                            placeholder='reason...' required
                                                            value={reason}
                                                            name="reason"
                                                            onChange={this.onChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row width={16}>
                                                    <Grid.Column>
                                                        <Button color='red' size='mini'
                                                                type='submit' floated='right'>
                                                            <Icon name='add'/> 
                                                                Decline
                                                        </Button>
                                                        <Button color='yellow' size='mini' 
                                                                type='button' floated='right'
                                                                onClick={this.handleDeclineToggle.bind(this)}>
                                                            <Icon name='cancel' /> Cancel
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Form> :
                                        <React.Fragment>
                                            <Button color='green' onClick={this.updateOrderStatus.bind(this, 'complete')}>Complete</Button>
                                            <Button color='red' onClick={this.handleDeclineToggle}>Decline</Button>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
            </React.Fragment>
        );
    }
}

Order.propTypes = {
    getBranchDetails: PropTypes.func,
    branch: PropTypes.object,
    requestInProcess: PropTypes.bool,
    getOrderInfo: PropTypes.func,
    order: PropTypes.object,
    updateStatus: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBranchDetails: (url) => dispatch(getBranchDetails(url)),
        getOrderInfo: (url) => dispatch(getOrderInfo(url)),
        updateStatus: (url, data) => dispatch(updateStatus(url, data))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        branch: state.branch,
        order: state.order
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
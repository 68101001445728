import React from 'react';
import { Grid, Table, Image, Modal, Form, Icon, Button } from 'semantic-ui-react';

const ServiceData = (props) => {
    const { serviceList } = props;

    const isEmpty = function(obj){
        if(Object.keys(obj).length === 0 && obj.constructor === Object)
            return true;
        else
            return false;
    }

    return (
        <Grid style={{marginTop: '5px'}}>
            <Grid.Row>
                <Button color='teal' floated='right' onClick={props.handleServiceModalToggle}>
                    <Icon name='add' />
                    Add additional service
                </Button>
                <Table celled striped color='blue'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>
                                Additional service name
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>  
                                Icon
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>
                                Action
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {serviceList.length > 0 &&
                        <Table.Body>
                            {serviceList.map((service, index) => (
                                <Table.Row key={service.id}>
                                    <Table.Cell>{index + 1}</Table.Cell>
                                    <Table.Cell>{service.name}</Table.Cell>
                                    <Table.Cell>
                                        <Image
                                            size='mini' style={{margin: 'auto'}}
                                            src={isEmpty(service.icon) ? require('../../assets/img/no_image.png') : service.icon.url} 
                                        />
                                    </Table.Cell>
                                    <Table.Cell className='tableActions' textAlign='center'>
                                        <Button className='rowDeleteBtn' compact color='red'
                                                onClick={props.deleteService.bind(this, service.id)}>
                                            <Icon name='trash' />
                                            Delete
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))} 
                        </Table.Body>
                    }
                    {!serviceList.length > 0 &&
                        <Table.Body> 
                            <Table.Row> 
                                <Table.Cell style={{textAlign: 'center'}} colSpan='12'>Пусто</Table.Cell>
                            </Table.Row> 
                        </Table.Body>
                    }
                </Table>
            </Grid.Row>
            <Modal
                    centered={false} size='mini'
                    open={props.serviceModalOpen}
                    onClose={props.handleServiceModalToggle}
                >
                <Modal.Content>
                    <Form size='mini' onSubmit={props.addServices}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Form.Select 
                                        fluid label='Service List' 
                                        placeholder='Select service...' required
                                        value={props.serviceIdList} multiple closeOnChange
                                        name="serviceIdList" options={props.serviceListOptions}
                                        onChange={props.onSelectChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row width={16}>
                                <Grid.Column>
                                    <Button color='green' size='mini'
                                            type='submit' floated='right'>
                                        <Icon name='add'/> 
                                            Add
                                    </Button>
                                    <Button color='orange' size='mini' 
                                            type='button' floated='right'
                                            onClick={props.handleServiceModalToggle}>
                                        <Icon name='cancel' /> Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        </Grid>
    )
}
export default ServiceData;
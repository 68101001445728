import axios from 'axios';
import { requestHasErrored, requestInProcess } from './commonAction';
import {timeDiff, getNewToken} from '../services/common';
var JSONbigString = require('json-bigint')({"storeAsString": true});

export function getPromoter(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(getPromoterSuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getPromoterSuccess(promoter) {
    return {
        type: 'GET_PROMOTER_SUCCESS',
        promoter
    };
}

export function updatePromoter(url, data, reqType) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = reqType !== 'delete' ? reqType === 'put' ? await axios.put(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) }) :
                                    await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) }) : 
                                    await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(updatePromoterSuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function updatePromoterSuccess(promoter) {
    return {
        type: 'UPDATE_PROMOTER_SUCCESS',
        promoter
    };
}

export function postQr(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.post(url, {}, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(updateQrSuccess(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function updateQrSuccess(qr) {
    return {
        type: 'UPDATE_QR_SUCCESS',
        qr
    };
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../actions/authAction';
// import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            password: ''
        };

        this.onChange = this.onChange.bind(this);
        this.login = this.login.bind(this);
    }

    onChange(e) {
        const val = e.target.value;
        const name = e.target.name;

        // console.log(val, name);
        this.setState({
            [name]: val
        });
    }

    login(e) {
        //console.log("login function started");
        e.preventDefault();

        var phone = this.state.phone.trim();
        var password = this.state.password.trim();
        //const router = this.props.history;

        var data = {
            "phone": phone,
            "password": password
        };

        this.props.login('/api/v1/auth/login', data)
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        if (sessionStorage.getItem('path')) {
                            this.props.history.push(sessionStorage.getItem('path'));
                        }
                    }
                }
            })
            .catch(error => {
                if (error) {
                    if (error.status === 400) {
                        this.setState({
                            forgotPassword: true,
                            positive: false,
                            hidden: false,
                            messageTitle: 'Ошибка!',
                            messageBody: 'Вы ввели неправильный логин и/или пароль.'
                        });

                        setTimeout(() => {
                            this.setState({ hidden: true })
                        }, 4000)
                    }
                    else if (error.status === 404) {
                        this.setState({
                            positive: false,
                            hidden: false,
                            messageTitle: 'Ошибка!',
                            messageBody: 'Пожалуйста подтвердите вашу почту.'
                        });

                        setTimeout(() => {
                            this.setState({ hidden: true })
                        }, 4000)
                    }
                }
                else {
                    this.setState({
                        positive: false,
                        hidden: false,
                        messageTitle: 'Ошибка Интернета!',
                        messageBody: 'Извините, ошибка с Интернетом. Попробуйте позже!'
                    });

                    setTimeout(() => {
                        this.setState({ hidden: true })
                    }, 4000)
                }
            }
        );
    }

    render() {
        return (
            <div>
                Main page
            </div>
        );
    }
}

Main.propTypes = {
    login: PropTypes.func,
    requestInProcess: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (url, data) => dispatch(login(url, data))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
export function promoter(state = {}, action) {
    switch (action.type) {
        case 'GET_PROMOTER_SUCCESS':
            return action.promoter;
        case 'UPDATE_PROMOTER_SUCCESS':
            return action.promoter;
        case 'UPDATE_QR_SUCCESS':
            state.promoter.qr = action.qr;
            return state;
        default:
            return state;
    }
}
import axios from 'axios';
import { requestHasErrored, requestInProcess, updateItemId } from './commonAction';
import { timeDiff, getNewToken } from '../services/common';
const JSONbigString = require('json-bigint')({ "storeAsString": true });

export function getUserList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                const arr = response.data.data;
                const arrLength = arr.length;
                const lastItemId = arrLength > 0 ? typeof arr[arrLength - 1].id === 'number' ? arr[arrLength - 1].id.toString() : arr[arrLength - 1].id : '';
                dispatch(updateItemId(lastItemId));
                dispatch(getUserListSuccess(arr));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function getUserListSuccess(list) {
    return {
        type: 'GET_USER_LIST_SUCCESS',
        list
    };
}

export function emptyUserList() {
    return {
        type: 'EMPTY_USER_LIST'
    };
}

export function searchList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                const arr = response.data.data;
                const arrLength = arr.length;
                const lastItemId = arrLength > 0 ? typeof arr[arrLength - 1].id === 'number' ? arr[arrLength - 1].id.toString() : arr[arrLength - 1].id : '';
                dispatch(updateItemId(lastItemId));
                dispatch(searchListSuccess(arr));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function searchListSuccess(list) {
    return {
        type: 'SEARCH_LIST_SUCCESS',
        list
    };
}

export function grantStarToUser(url, data) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(updateUser(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function revokeStarFromUser(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(updateUser(response.data.data));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}


export function updateUser(user) {
    return {
        type: 'UPDATE_USER',
        user
    };
}

export function registerUpdateBU(url, data, userId) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = userId ?
            await axios.put(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) }) :
                await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                if (!userId) {
                    dispatch(addBusinessUserToList(response.data.data));
                }
                else {
                    dispatch(updateBusinessUser(response.data.data, userId));
                }
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function addBusinessUserToList(businessUser) {
    return {
        type: 'ADD_BUSINESS_USER_TO_LIST',
        businessUser
    };
}

export function updateBusinessUser(businessUser, userId) {
    return {
        type: 'UPDATE_BUSINESS_USER',
        businessUser, userId
    };
}

export function getBusinessUserList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                const arr = response.data.data;
                const arrLength = arr.length;
                const lastItemId = arrLength > 0 ? typeof arr[arrLength - 1].id === 'number' ? arr[arrLength - 1].id.toString() : arr[arrLength - 1].id : '';
                dispatch(updateItemId(lastItemId));
                dispatch(getBusinessUserListSuccess(arr));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function getBusinessUserListSuccess(list) {
    return {
        type: 'GET_BUSINESS_USER_LIST_SUCCESS',
        list
    };
}

export function emptyBUserList() {
    return {
        type: 'EMPTY_BUSER_LIST'
    };
}

export function getUserItems(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function updateBonusBalance(url, data) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if (timeDiff()) {
            await getNewToken(token).then(function (res) {
                token = res.data.data.access_token;
            });
        }

        try {
            const response = await axios.put(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }
    };
}

export function handleRemove(url, id, isUser = false) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        let token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                if(isUser) dispatch(deleteUserSuccess(id));
                else dispatch(deleteBusinessUserSuccess(id));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function deleteUserSuccess(userId) {
    return {
        type: 'DELETE_USER_SUCCESS',
        userId
    };
}

export function deleteBusinessUserSuccess(userId) {
    return {
        type: 'DELETE_BUSINESS_USER_SUCCESS',
        userId
    };
}
import React from 'react';
import { Grid, Image, Button, Dimmer, Modal, Input, Icon } from 'semantic-ui-react';

const BannerData = (props) => {
    const { bannerList } = props;
    // if(Object.keys(rule).length === 0 && rule.constructor === Object)
    if(bannerList === undefined)
        return "Загрузка...";

    const content = (
        <div>
            <Button color='red' onClick={props.deleteBanner.bind(this, props.banner_id)}>Remove</Button>
        </div>
    )

    return (
        <Grid>
            <Grid.Row columns={3}>
                {bannerList.length > 0 &&
                    <React.Fragment>
                        {bannerList.map(function(banner) {
                            const active = props.banner_id === banner.id ? true : false;
                            return (
                                <Grid.Column key={banner.id} textAlign='center' style={{padding: '10px'}}>
                                    <Dimmer.Dimmable
                                        as={Image}
                                        dimmed={active}
                                        dimmer={{ active, content }}
                                        onMouseEnter={props.handleDimmerToggle.bind(this, banner.id)}
                                        onMouseLeave={props.handleDimmerToggle.bind(this, '')}
                                        size='medium' verticalAlign='middle' centered 
                                        src={banner.url} 
                                    />
                                </Grid.Column>
                            )
                        })}
                    </React.Fragment>
                }
                <Grid.Column textAlign='center' style={{cursor: 'pointer'}}
                        onClick={props.handleBannerModalToggle}>
                    <Image 
                        src={require('../../assets/img/upload.jpg')} size='medium' 
                        verticalAlign='middle' centered 
                        style={{padding: '10px', height: '150px'}}/>
                </Grid.Column>
            </Grid.Row>
            <Modal
                    centered={false} size='mini'
                    open={props.bannerModalOpen}
                    onClose={props.handleBannerModalToggle}
                >
                <Modal.Content>
                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Input 
                                    fluid type='file'
                                    onChange = {(e) => props.onFileChange(e)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row width={16}>
                            <Grid.Column>
                                <Button color='green' size='mini'
                                        floated='right' onClick={props.addBanner}>
                                    <Icon name='add'/> 
                                        Add
                                </Button>
                                <Button color='orange' size='mini' floated='right'
                                        onClick={props.handleBannerModalToggle}>
                                    <Icon name='cancel' /> Cancel
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
            </Modal>
        </Grid>
    )
}
export default BannerData;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { setCoordinates } from '../../actions/mapAction';

export class MapComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cityLocation: { 
                lat: this.props.lat, 
                lng: this.props.lng 
            }, 
            loading: true 
        };
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;
                if(Object.keys(this.props.coordinates).length === 0 && this.props.coordinates.constructor === Object)
                {
                    this.setState({
                        cityLocation: { lat: latitude, lng: longitude },
                        loading: false
                    });
                }
                else{
                    const { coordinates } = this.props;
                    this.setState({
                        cityLocation: { lat: coordinates.lat, lng: coordinates.lng },
                        loading: false
                    });
                }
            },
            () => {
                this.setState({ loading: false });
            }
        );
    }

    onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const lat = Number(latLng.lat().toFixed(6));
        const lng = Number(latLng.lng().toFixed(6));
        const coor = {
            lat,
            lng
        }

        // console.log(lat, lng);
        this.props.setCoordinates(coor);
    };

    render() {
        const { loading, cityLocation } = this.state;
        const { google } = this.props;

        if (loading) {
            return null;
        }

        return (
            <Map
                google={google}
                containerStyle={{ 
                    position: 'static',
                    width: "100%",
                    height: "300px" 
                }}
                initialCenter={cityLocation}
                zoom={7}
            >
                
                <Marker
                    position={cityLocation}
                    draggable={true}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                    // name='Test'
                />
            </Map>
        );
    }
}

const WrappedMap =  GoogleApiWrapper({
    apiKey: "AIzaSyB8jBPzoKFxRXeb9TAP9BB_0m3tVZDVcHY"
})(MapComponent);

MapComponent.propTypes = {
    coordinates: PropTypes.object,
    requestInProcess: PropTypes.bool,
    setCoordinates: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCoordinates: (coor) => dispatch(setCoordinates(coor))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        coordinates: state.coordinates
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(WrappedMap);
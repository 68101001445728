export function dealList(state = [], action) {
    switch (action.type) {
        case 'GET_DEAL_LIST_SUCCESS':
            return action.list;
        case 'ADD_DEAL_SUCCESS':
            return [action.deal, ...state];
        case 'UPDATE_DEAL_SUCCESS':
            const index = state.findIndex(d => d.id === action.dealId);
            var selectedDeal = state.find(d => d.id === action.dealId);
            selectedDeal.name = action.deal.name;
            selectedDeal.description = action.deal.description;
            return [...state.slice(0, index), selectedDeal, ...state.slice(index + 1)];
        case 'EMPTY_DEAL_LIST':
            return [];
        case 'DELETE_DEAL_SUCCESS':
            return state.filter(item => item.id !== action.dealId);
        default:
            return state;
    }
}
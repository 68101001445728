import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const VoucherData = (props) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <Button compact style={{ marginBottom: '10px' }}
            color={props.voucherAvailable ? 'red' : 'green'}
            onClick={() => props.activateDeactivateVoucher(!props.voucherAvailable)}>
            {props.voucherAvailable ? 'Deactivate Voucher' : 'Activate Voucher'}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};
export default VoucherData;
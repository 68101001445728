import axios from 'axios';
import { requestHasErrored, requestInProcess } from './commonAction';
import {timeDiff, getNewToken} from '../services/common';
var JSONbigString = require('json-bigint')({"storeAsString": true});

export function getCategoryList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data_1 => JSONbigString.parse(data_1) });
            if (response.status === 200) {
                // console.log(response);
                var categories = response.data.data.categories.filter(category => category.parent_id === 0);
                var subCategList = [];
                categories.forEach(element => {
                    var subCategories = response.data.data.categories.filter(category => category.parent_id === element.id);
                    const obj = {
                        parent_id: element.id,
                        list: subCategories
                    };
                    subCategList.push(obj);
                });
                dispatch(getCategoryListSuccess(categories));
                dispatch(getSubCategoryListSuccess(subCategList));
                dispatch(getAllCategoryList(response.data.data.categories));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getCategoryListSuccess(list) {
    return {
        type: 'GET_CATEGORY_LIST_SUCCESS',
        list
    };
}

export function getSubCategoryListSuccess(list) {
    return {
        type: 'GET_SUBCATEGORY_LIST_SUCCESS',
        list
    };
}

export function getAllCategoryList(list) {
  return {
      type: 'GET_ALL_CATEGORY_LIST_SUCCESS',
      list
  };
}

export function postCategory(url, data, parent_id, isEdit) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if(isEdit){
                if (response.data.message === "Success") {
                    // console.log(response);
                    if(parent_id === 0){
                        dispatch(updateCategorySuccess(response.data.data));
                    }
                    else
                        dispatch(updateSubCategorySuccess(response.data.data, parent_id));
                    dispatch(requestInProcess(false));
                }
            }
            else{
                if (response.data.message === "Created") {
                    // console.log(response);
                    if(parent_id === 0){
                        dispatch(addCategorySuccess(response.data.data));
                        dispatch(addSubCategorySuccess({}, response.data.data.id));
                    }
                    else
                        dispatch(addSubCategorySuccess(response.data.data, parent_id));
                    dispatch(requestInProcess(false));
                }
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function addCategorySuccess(category) {
    return {
        type: 'ADD_CATEGORY_SUCCESS',
        category
    };
}

export function addSubCategorySuccess(category, parent_id) {
    return {
        type: 'ADD_SUBCATEGORY_SUCCESS',
        category, parent_id
    };
}

export function updateCategorySuccess(category) {
    return {
        type: 'UPDATE_CATEGORY_SUCCESS',
        category
    };
}

export function updateSubCategorySuccess(category, parent_id) {
    return {
        type: 'UPDATE_SUBCATEGORY_SUCCESS',
        category, parent_id
    };
}

export function uploadCategoryIcon(url, data) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function deleteCategory(url, categoryId, parent_id) {
    return async (dispatch) => {
        // dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.data.message === "Success") {
                // console.log(response);
                if(parent_id === 0)
                    dispatch(deleteCategorySuccess(categoryId));
                else
                    dispatch(deleteSubCategorySuccess(categoryId, parent_id));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function deleteCategorySuccess(categoryId) {
    return {
        type: 'DELETE_CATEGORY_SUCCESS',
        categoryId
    };
}

export function deleteSubCategorySuccess(categoryId, parent_id) {
    return {
        type: 'DELETE_SUBCATEGORY_SUCCESS',
        categoryId, parent_id
    };
}
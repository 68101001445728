import React, { Fragment } from 'react';
import { Grid, Input, Button, Modal, Confirm, TextArea, Checkbox, Select } from 'semantic-ui-react';
import Message from './Message';
import { isEmpty } from '../../services/common';

const providerOptions = [
  { key: 0, value: 'integrilla', text: 'integrilla' },
  { key: 1, value: 'revvy', text: 'revvy' },
  { key: 2, value: 'wazzup', text: 'wazzup' },
  { key: 3, value: 'waba', text: 'WABA' },
  { key: 4, value: 'green_api', text: 'green api' },
]
const gridColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 10
};

const h3Style = { margin: 0 };

const btnStyle = { width: 'fit-content' };

const inputStyle = {
  width: 300
};

const WhatsApp = (props) => {

  return (
    <Grid>
      <Grid.Row>
        {!props.whatsappMsgHidden &&
          <Grid.Column width={16}>
            <Message
              positive={props.whatsappMsgPositive}
              hidden={props.whatsappMsgHidden}
              messageTitle=""
              messageBody={props.whatsappMsgBody}
            />
          </Grid.Column>
        }
        <Grid.Column width={8} style={gridColumnStyle}>
          <h3 style={h3Style}>Создать аккаунт</h3>
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Input
                placeholder='Введите номер телефона'
                name='whatsappPhoneNumber' required
                value={props.whatsappPhoneNumber}
                onChange={props.onChange}
                style={inputStyle}
                disabled={props.whatsappEnabled}
              />
              <Select
                style={inputStyle}
                placeholder='Выберите провайдера...'
                fluid
                clearable
                closeOnChange
                options={providerOptions}
                name='whatsappProvider'
                value={props.whatsappProvider}
                onChange={props.onSelectChange}
                disabled={props.whatsappEnabled}
              />
              {props.whatsappProvider === "waba" &&
                <Fragment>
                  <Input
                    placeholder='Введите idInstance'
                    name='accountId' 
                    required
                    value={props.accountId}
                    onChange={props.onChange}
                    style={inputStyle}
                    disabled={props.whatsappEnabled}
                  />
                  <Input
                    placeholder='Введите apiTokenInstance'
                    name='wabaToken' 
                    required
                    value={props.wabaToken}
                    onChange={props.onChange}
                    style={inputStyle}
                    disabled={props.whatsappEnabled}
                  />
                </Fragment>
              }
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                {props.whatsappEnabled || !props.whatsappEnabledDefault ?
                  <Button
                    color='orange' disabled={!props.whatsappEnabledDefault}
                    onClick={() => props.toggleWhatsappEnable(false)}
                  >
                    Edit
                  </Button> :
                  <Button
                    color='yellow' disabled={!props.whatsappEnabledDefault}
                    onClick={() => props.toggleWhatsappEnable(true)}
                  >
                    Cancel
                  </Button>
                }
                <Button color='teal' onClick={() => props.activateMailing(props.isUsedForAllGroups)}
                  disabled={isEmpty(props.whatsappPhoneNumber) || props.whatsappEnabled}>
                  Save
                </Button>
              </div>
              <Button
                style={btnStyle}
                disabled={!props.whatsappEnabledDefault}
                color='teal'
                onClick={props.createIntegrillaAccount}
              >
                Создать в WhatsApp
              </Button>

            </div>
          </div>
          {!isEmpty(props.whatsappUrl) &&
            <a href={props.whatsappUrl} target="_blank" rel="noopener noreferrer">Ссылка на whatsapp</a>
          }
          <h3 style={h3Style}>Статус аккаунта</h3>
          <h4>Статус подключения аккаунта: { }</h4>
          <Button
            style={btnStyle}
            disabled={!props.whatsappEnabledDefault}
            color='teal'
            onClick={() => props.toggleWhatsappModal(true)}
          >
            Узнать
          </Button>
          <h3 style={h3Style}>Включение/выключение бета версии</h3>
          <Checkbox
            checked={props.isBeta}
            label={props.isBeta ? "Включен" : "Выключен"}
            onChange={(e, data) => props.setBetaForBranch(data.checked)}
          />
          <h3 style={h3Style}>Использовать для всей группы филиала</h3>
          <Checkbox
            checked={props.isUsedForAllGroups}
            label="Использовать"
            onChange={(e, data) => props.activateMailing(data.checked)}
          />
          <h3 style={h3Style}>Текст для WhatsApp</h3>
          <Button
            style={btnStyle}
            color={props.whatsappHeaderTextEnabled ? 'red' : 'green'}
            onClick={() => props.activateDeactivateWhatsappHeader(!props.whatsappHeaderTextEnabled)}
          >
            {props.whatsappHeaderTextEnabled ? 'Deactivate' : 'Activate'}
          </Button>
          <h3 style={h3Style}>Лимит сообщений</h3>
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Input
              placeholder='Введите значение'
              name='whatsappMailingLimit' required
              value={props.whatsappMailingLimit}
              type='number'
              onChange={props.onChange}
              style={inputStyle}
            />
            {props.limit > 0 ?
              <Button color='orange' onClick={() => props.updateMailingLimit()}>
                Обновить
              </Button> :
              <Button color='teal' onClick={() => props.activateMailing(props.isUsedForAllGroups)}>
                Установить
              </Button>
            }
          </div>
        </Grid.Column>
        <Grid.Column width={8} style={gridColumnStyle}>
          <h3 style={h3Style}>WhatsApp code</h3>
          <Button
            style={btnStyle}
            color={props.whatsappCodeAvailable ? 'red' : 'green'}
            onClick={() => props.activateDeactivateWhatsappCode(!props.whatsappCodeAvailable)}
          >
            {props.whatsappCodeAvailable ? 'Deactivate' : 'Activate'}
          </Button>
          <h3 style={h3Style}>Удаление аккаунта</h3>
          <Button
            style={btnStyle}
            disabled={!props.whatsappEnabledDefault}
            color='teal'
            onClick={() => props.toggleDeleteWhatsappAccount(true)}
          >
            Удалить
          </Button>
          <h3 style={h3Style}>Перезапуск аккаунта</h3>
          <Button
            style={btnStyle}
            disabled={!props.whatsappEnabledDefault}
            color='teal'
            onClick={() => props.toggleRestartWhatsappAccount(true)}
          >
            Перезапустить
          </Button>
          <h3 style={h3Style}>Список номеров для отправки аналитики</h3>
          <div>
            <span>{props.reportPhoneNumbers.length > 0 ? props.reportPhoneNumbers.join(', ') : 'Пусто'}</span>
          </div>
          <h3 style={h3Style}>Рекламное сообщение</h3>
          <div style={{ display: 'flex', gap: 5 }}>
            <TextArea
              placeholder='Введите текст'
              name='whatsappCommercialText' required
              rows={5}
              value={props.whatsappCommercialText}
              onChange={props.onChange}
              style={{ width: 300 }}
            />
            <Button color='teal' style={{ height: 'fit-content', marginTop: 'auto' }} onClick={props.updateCommercialText}>Сохранить</Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      {props.whatsappModalOpen &&
        <Modal
          size='small' open={props.whatsappModalOpen}
          onClose={() => props.toggleWhatsappModal(false)}
        >
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column width='16' style={{ color: 'grey' }}>
                  Сообщение: {props.whatsappMessage}
                </Grid.Column>
                <Grid.Column width='16' style={{ marginTop: '10px' }} textAlign='center'>
                  <Button color='orange' size='small' onClick={() => props.toggleWhatsappModal(false)}>
                    Закрыть
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      }
      {props.deleteConfirmOpen &&
        <Confirm
          open={props.deleteConfirmOpen}
          content='Вы действительно хотите удалить?'
          cancelButton='Нет'
          confirmButton="Да"
          onCancel={() => props.toggleDeleteWhatsappAccount(false)}
          onConfirm={() => props.deleteRestartWhatsappAccount('delete')}
        />
      }
      {props.restartConfirmOpen &&
        <Confirm
          open={props.restartConfirmOpen}
          content='Вы действительно хотите перезапустить?'
          cancelButton='Нет'
          confirmButton="Да"
          onCancel={() => props.toggleRestartWhatsappAccount(false)}
          onConfirm={() => props.deleteRestartWhatsappAccount('restart')}
        />
      }
    </Grid>
  )
};
export default WhatsApp;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCategoryList, postCategory, deleteCategory, uploadCategoryIcon } from '../../actions/categoryAction';
import { getCityList } from '../../actions/cityAction';
import { Grid, Image, Dimmer, Loader, Modal, Button, Icon, Form, Label, Popup, Input, Confirm } from 'semantic-ui-react';

class CategoryList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryList: [],
            categoryNameList: [],
            subCategoryList: [],
            subCategIsOpen: false,
            cityListOptions: [],
            categoryName: '',
            cityIdList: [],
            cityId: 1,
            categoryActive: false,
            cityList: [],
            parent_id: 0,
            isCategory: true,
            categoryId: '',
            subCategoryId: '',
            iconModalOpen: false,
            file: {},
            confirmOpen: false,
            isEdit: false
        };

        this.onChange = this.onChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount(){
        this.props.getCityList('/api/v1/cities');
        this.getCategories(1);
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if(prevProps.categoryList !== this.props.categoryList){
            const { categoryList } = this.props;
            var categoryNameList = [];
            categoryList.forEach(element => {
                var obj = {
                    categoryName: element.name,
                    isClicked: false
                }
                categoryNameList.push(obj);
            });
            this.setState({categoryList, categoryNameList})
        }

        if(prevProps.subCategoryList !== this.props.subCategoryList){
            const {categoryList, subCategoryList} = this.props;
            if(categoryList.length > 0){
                //need to know parentId, if parentId is 0 get the last category's id as parentId
                const parentId = this.state.parent_id === 0 ? categoryList[categoryList.length - 1].id : this.state.parent_id; 
                //via parentId set the index so updates the selected subCategory
                const index = subCategoryList.findIndex(x => x.parent_id === parentId);
                this.setState({subCategoryList: this.props.subCategoryList[index].list});
            }
        }

        if(prevProps.cityList !== this.props.cityList){
            var cityListOptions = [];
            this.props.cityList.forEach(el => {
                const obj = {
                    key: el.id,
                    value: el.id,
                    text: el.name
                };
                cityListOptions.push(obj);
            });
            this.setState({cityListOptions});
        }
    }

    getCategories = (cityId) => {
        this.props.getCategoryList('/api/v1/categories?city_id=' + cityId);
    }

    onChange(e) {
        const {value, name} = e.target;
        // console.log(val, name);
        this.setState({
            [name]: value
        });
    }

    onFileChange = (e) => {
        // console.log(e.target.files[0]);
        this.setState({file: e.target.files[0]});
    }

    onSelectChange(e, data) {
        // console.log('------------->', data)
        const name = data.name;
        const value = data.type === 'checkbox' ? data.checked : data.value;
        if(name === 'cities'){
            var tempList = [];
            if(value.length > 0){
                value.forEach(element => {
                    const name = data.options.find(x => x.value === element).text;
                    tempList.push(name);
                });
            }
            this.setState({
                cityIdList: value,
                cityList: tempList
            });
        }
        else{
            this.setState({
                [name]: value
            });
            if(name === 'cityId') this.getCategories(value);
        }
    }

    openSubCategory(id, parent_id, i){
        // console.log(parent_id);
        const currentSubCategList = this.props.subCategoryList.find(subCateg => subCateg.parent_id === id).list;
        // console.log(this.props.subCategoryList);
        this.state.categoryNameList.forEach(el => {
            el.isClicked= false;
        });
        var tempObj = this.state.categoryNameList[i];
        tempObj.isClicked= true;
        this.setState({
            categoryNameList: [...this.state.categoryNameList.slice(0, i), tempObj, ...this.state.categoryNameList.slice(i + 1)],
            subCategIsOpen: true,
            categoryId: id,
            subCategoryList: currentSubCategList,
            parent_id // need to set parentId
        });
    }

    selectSubCategory(id, parent_id){
        // console.log(id, parent_id, i);
        this.setState({
            subCategoryId: id,
            parent_id // need to set parentId
        });
    }

    handleModalToggle = (isCategory, isEdit) => {
        if(isEdit){
            const { categoryId, subCategoryId, parent_id, categoryList, subCategoryList } = this.state;
            // console.log('categoryId: ', categoryId, ' parentId: ', parent_id);
            const category = parent_id === 0 ? categoryList.find(x => x.id === categoryId) : subCategoryList.find(x => x.id === subCategoryId);
            // console.log(category);
            this.setState({
                categoryName: category.name,
                cityIdList: category.cities,
                categoryActive: category.active
            })
        }
        this.setState({ 
            modalOpen: !this.state.modalOpen, 
            isCategory,
            parent_id: isCategory ? this.state.parent_id : this.state.categoryId,
            isEdit
        });
    }
    handleIconModalToggle = () => this.setState({ iconModalOpen: !this.state.iconModalOpen }, function(){
        //when cancel is pressed set file to an empty object
        if(!this.state.iconModalOpen)
            this.setState({ file: {} });
    })

    createUpdateCategory(){
        const { categoryName, categoryActive, isCategory, parent_id, categoryId, subCategoryId,
                categoryList, subCategoryList, cityIdList, isEdit } = this.state;
        var data = {
            name: categoryName,
            order: isCategory ? categoryList.length + 1 : subCategoryList.length + 1,
            cities: cityIdList,
            active: isEdit ? categoryActive : true,
            parent_id: isCategory ? 0 : parent_id //for category the parent_id is 0
        }

        if(isEdit){
            const id = parent_id === 0 ? categoryId : subCategoryId;
            this.props.postCategory('/api/v1/categories/' + id, data, parent_id, isEdit)
            .then(function(res) {
                if(res.data.message === "Success"){
                    this.setState({
                        modalOpen: false,
                        categoryName: '',
                        cityIdList: []
                    })
                }
            }.bind(this));
        }
        else{
            this.props.postCategory('/api/v1/categories', data, parent_id, isEdit)
            .then(function(res) {
                if(res.data.message === "Created"){
                    this.setState({
                        modalOpen: false,
                        categoryName: '',
                        cityIdList: []
                    })
                }
            }.bind(this));
        }
    }

    // calls confirmation window for deleting the category
    handelConfirmation = (categoryId) => {
        this.setState({ confirmOpen: !this.state.confirmOpen, categoryId });
    }

    //deletes the category or subcategory
    removeCategory(){
        const {isCategory, categoryId, subCategoryId, parent_id} = this.state;
        const id = isCategory ? categoryId : subCategoryId;
        this.props.deleteCategory('/api/v1/categories/' + id, id, parent_id)
        .then(function(res) {
            if(res.data.message === "Success"){
                this.setState({
                    confirmOpen: false,
                    subCategIsOpen: parent_id === 0 ? false : true
                })
            }
        }.bind(this));
    }

    //uploads category icon
    uploadCategoryIcon() {
        const {file, categoryId, cityId} = this.state;
        var data = new FormData();
        data.append('icon', file);
        
        this.props.uploadCategoryIcon('/api/v1/categories/' + categoryId + '/icon', data)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    iconModalOpen: false,
                    file: {}
                }, function(){
                    this.props.getCategoryList('/api/v1/categories?city_id=' + cityId);
                });
            }
        }.bind(this));
    }

    render() {
        const { categoryList, categoryNameList, subCategoryList, subCategIsOpen, 
                confirmOpen, subCategoryId, isCategory, isEdit, cityListOptions, 
                modalOpen, categoryName, categoryActive, cityIdList, categoryId, 
                iconModalOpen, cityId } = this.state;
        const popupStyle = {
            borderRadius: 5,
            padding: '0.2em',
        }
        return (
            <React.Fragment>
                <Grid style={{margin: '0'}}>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Select name='cityId'
                                options={cityListOptions} 
                                label='Выберите город'
                                value={cityId || ''}
                                onChange={this.onSelectChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={8} style={{marginTop: '10px'}}>
                        {categoryList.length > 0 &&
                            <React.Fragment>
                                {categoryList.map(function(category, index) {
                                    const {isClicked} = categoryNameList[index];
                                    return (
                                        <Grid.Column key={category.id} 
                                                        textAlign='center'
                                                        style={{cursor: 'pointer'}}
                                        >
                                            <Grid.Row columns={1}>
                                                {categoryId === category.id &&
                                                    <Grid.Column>
                                                        <Grid>
                                                            <Grid.Row columns={3} textAlign='center' style={{paddingTop: '0'}}>
                                                                <Grid.Column>
                                                                    <Popup style={popupStyle}
                                                                        trigger={<Icon name='image' color='brown' onClick={this.handleIconModalToggle} />}
                                                                        content='Update icon'
                                                                        position='bottom center'
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Popup style={popupStyle}
                                                                        trigger={<Icon name='edit' color='yellow'
                                                                                    onClick={this.handleModalToggle.bind(this, true, true)} />
                                                                        }
                                                                        content='Edit category'
                                                                        position='bottom center'
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Popup style={popupStyle}
                                                                        trigger={<Icon name='cancel' color='red' onClick={this.handelConfirmation.bind(this, category.id, true)} />}
                                                                        content='Delete category'
                                                                        position='bottom center'
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Grid.Column>
                                                }
                                                <Grid.Column style={{paddingTop: '5px'}}>
                                                    <Image 
                                                        style={{width: '4em', height: '4em'}} 
                                                        onClick={this.openSubCategory.bind(this, category.id, category.parent_id, index)}
                                                        avatar src={category.icon === '' ? require('../../assets/img/no_image.png') : category.icon}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column
                                                    style={{
                                                        fontSize: isClicked ? '18px' : '16px', 
                                                        marginTop: '5px',
                                                        fontWeight: isClicked ? 'bold' : 'normal',
                                                        color: isClicked ? '#46d689' : 'black'
                                                    }}
                                                >
                                                    {category.name}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid.Column>
                                    )
                                }.bind(this))}
                            </React.Fragment>
                        }
                        <Grid.Column textAlign='center'
                                style={{cursor: 'pointer'}}
                                onClick={this.handleModalToggle.bind(this, true, false)}>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Image 
                                        style={{width: '4em', height: '4em'}} 
                                        avatar src={require('../../assets/img/add_icon.jpg')}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{fontSize: '16px', marginTop: '5px'}}>
                                    Новая категория
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                    {subCategIsOpen && 
                        <Grid.Row columns={10}>
                            <Grid.Column width={16} style={{marginBottom: '15px'}}>
                                <h3>Подкатегории</h3>
                            </Grid.Column>
                            {subCategoryList.length > 0 && 
                                <React.Fragment>
                                    {subCategoryList.map(function(category, index) {
                                        // const {isClicked} = categoryNameList[index];
                                        return (
                                            <Grid.Column key={category.id} 
                                                            textAlign='center'
                                                            onClick={this.selectSubCategory.bind(this, category.id, category.parent_id)}
                                            >
                                                <Grid.Row columns={1}>
                                                    {subCategoryId === category.id &&
                                                        <Grid.Column>
                                                            <Grid>
                                                                <Grid.Row columns={2} textAlign='center' style={{paddingTop: '0'}}>
                                                                    <Grid.Column>
                                                                        <Popup style={popupStyle}
                                                                            trigger={<Icon name='edit' 
                                                                                color='yellow' style={{cursor: 'pointer'}}
                                                                                onClick={this.handleModalToggle.bind(this, false, true)} />
                                                                            }
                                                                            content='Edit category'
                                                                            position='bottom center'
                                                                        />
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <Popup style={popupStyle}
                                                                            trigger={<Icon name='cancel' 
                                                                                color='red' style={{cursor: 'pointer'}}
                                                                                onClick={this.handelConfirmation.bind(this, category.id, false)} />
                                                                            }
                                                                            content='Delete category'
                                                                            position='bottom center'
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                    }
                                                    <Grid.Column style={{fontSize: '16px', marginTop: '5px'}}>
                                                        <Label style={{cursor: 'pointer'}} color='violet'>
                                                            {category.name}
                                                        </Label>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid.Column>
                                        )
                                    }.bind(this))}
                                </React.Fragment>
                            }
                            <Grid.Column textAlign='center'
                                            onClick={this.handleModalToggle.bind(this, false, false)}>
                                <Grid.Row columns={1}>
                                    <Grid.Column style={{fontSize: '16px', marginTop: '5px'}}>
                                        <Label style={{cursor: 'pointer'}} color='olive'>
                                            Новая подкатегория
                                        </Label>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
                <Modal
                    centered={false} size='mini'
                    open={modalOpen}
                    onClose={this.close}
                >
                    <Modal.Content>
                        <Form size='mini' onSubmit={this.createUpdateCategory.bind(this)}>
                            <Grid>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Form.Input 
                                            fluid label='Category name' 
                                            placeholder='Name...' required
                                            value={categoryName}
                                            name="categoryName"
                                            onChange={this.onChange}
                                        />
                                        <Form.Select fluid required
                                            options={cityListOptions}
                                            multiple name='cities'
                                            placeholder='Выберите...'
                                            value={cityIdList}
                                            onChange={this.onSelectChange}
                                        />
                                        {isEdit &&
                                            <Form.Checkbox 
                                                label='Category is active'
                                                name='categoryActive'
                                                checked={categoryActive}
                                                onChange={this.onSelectChange}
                                            />
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Button color='green' size='mini'
                                                type='submit' floated='right'>
                                            <Icon name={isEdit ? 'edit' : 'add'}/> 
                                                {isEdit ? 'Update' : 'Create'}
                                        </Button>
                                        <Button color='orange' size='mini' 
                                                type='button' floated='right'
                                                onClick={this.handleModalToggle.bind(this, isCategory ? true : false, false)}>
                                            <Icon name='cancel' /> Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal
                        centered={false} size='mini'
                        open={iconModalOpen}
                        onClose={this.handleIconModalToggle}
                    >
                        <Modal.Content>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Input 
                                            fluid type='file'
                                            onChange = {(e) => this.onFileChange(e)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Button color='green' size='mini' floated='right'
                                                onClick={this.uploadCategoryIcon.bind(this)}>
                                            <Icon name='upload'/> 
                                                Upload
                                        </Button>
                                        <Button color='orange' size='mini' floated='right'
                                                onClick={this.handleIconModalToggle}>
                                            <Icon name='cancel' /> Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Content>
                    </Modal>
                <Confirm
                    open={confirmOpen}
                    content='Вы действительно хотите удалить?'
                    cancelButton='Отмена'
                    confirmButton="Удалить"
                    onCancel={this.handelConfirmation.bind(this, '', isCategory)}
                    onConfirm={this.removeCategory.bind(this, categoryId)}
                />
                <Dimmer active={this.props.requestInProcess} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>
            </React.Fragment>
        );
    }
}

CategoryList.propTypes = {
    getCategoryList: PropTypes.func,
    categoryList: PropTypes.array,
    subCategoryList: PropTypes.array,
    requestInProcess: PropTypes.bool,
    getCityList: PropTypes.func,
    cityList: PropTypes.array,
    postCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    uploadCategoryIcon: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCategoryList: (url) => dispatch(getCategoryList(url)),
        getCityList: (url) => dispatch(getCityList(url)),
        postCategory: (url, data, parent_id, isEdit) => dispatch(postCategory(url, data, parent_id, isEdit)),
        deleteCategory: (url, categoryId, parent_id) => dispatch(deleteCategory(url, categoryId, parent_id)),
        uploadCategoryIcon: (url, data) => dispatch(uploadCategoryIcon(url, data))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        categoryList: state.categoryList,
        subCategoryList: state.subCategoryList,
        cityList: state.cityList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
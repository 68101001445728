import React from 'react';
import { Grid, Header, Input, Image, Modal, Form, Icon, Button } from 'semantic-ui-react';

const SocialnetData = (props) => {
    const { socialNetList } = props;
    // if(Object.keys(rule).length === 0 && rule.constructor === Object)
    if(socialNetList === undefined)
        return "Загрузка...";
    const socialNetOptions = [
        {key: 0, value: 'facebook', text: 'facebook'},
        {key: 1, value: 'instagram', text: 'instagram'},
        {key: 2, value: 'vk', text: 'vk'},
        {key: 3, value: 'youtube', text: 'youtube'},
        {key: 4, value: 'tiktok', text: 'tiktok'},
        {key: 5, value: '2gis', text: '2gis'}
    ];
    return (
        <Grid style={{marginTop: '5px'}}>
            <Grid.Row>
                <Grid.Column width={2} verticalAlign='middle'>
                    <Header as='h3'>Website:</Header>
                </Grid.Column>
                <Grid.Column width={3}>
                    {props.siteEditIsOn ?
                        <Input
                            placeholder='website...'
                            value={props.website}
                            name="website"
                            onChange={props.onChange}
                        /> :
                        <p>{props.website}</p>
                    }
                </Grid.Column>
                <Grid.Column width={11} verticalAlign='middle'>
                    {props.siteEditIsOn ?
                        <Button.Group size='mini'>
                            <Button onClick={props.toggleSiteEdit}
                                >Cancel
                            </Button>
                            <Button.Or text='|' />
                            <Button positive onClick={props.updateWebsite}>
                                Update
                            </Button>
                        </Button.Group> :
                        <Button size='mini' color='orange'
                                onClick={props.toggleSiteEdit}>
                            <Icon name='edit' /> Edit website
                        </Button>
                    }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={10}>
                <Grid.Column verticalAlign='middle'>
                    <Header as='h3'>Social networks:</Header>
                </Grid.Column>
                {socialNetList.length > 0 &&
                    <React.Fragment>
                        {socialNetList.map(function(socialNet, index) {
                            let name;
                            switch(socialNet.type){
                                case 'youtube': name = 'youtube'; break;
                                case 'vk': name = 'vk'; break;
                                case 'instagram': name = 'instagram'; break;
                                case 'twitter': name = 'twitter'; break;
                                case 'facebook': name = 'facebook'; break;
                                case 'tiktok': name = 'tiktok'; break;
                                case '2gis': name = '2gis'; break;
                                default: name = 'no_image';
                            }
                            return (
                                <Grid.Column key={index} textAlign='center' 
                                            style={{cursor: 'pointer'}}
                                            onClick={props.editOrAddNet.bind(this, 'edit', socialNet.type, socialNet.url, index)}>
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <Image 
                                                style={{width: '4em', height: '4em'}} 
                                                avatar src={require('../../assets/img/'+ name +'.png')}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                            )
                        })}
                    </React.Fragment>
                }
                    <Grid.Column textAlign='center' style={{cursor: 'pointer'}}
                                onClick={props.editOrAddNet.bind(this, 'add', '', '', socialNetList.length)}>
                        <Image 
                            style={{width: '4em', height: '4em'}} 
                            avatar src={require('../../assets/img/add_icon.jpg')}
                        />
                    </Grid.Column>
            </Grid.Row>
            <Modal
                    centered={false} size='mini'
                    open={props.socialNetModalOpen}
                    onClose={props.handleModalToggle}
                >
                <Modal.Content>
                    <Form size='mini' onSubmit={props.addSocialNet.bind(this)}>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Form.Select name='network_type'
                                        options={socialNetOptions} 
                                        label='Network type:' required
                                        placeholder='Choose type...'
                                        value={props.network_type || ''}
                                        onChange={props.onSelectChange}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input 
                                        fluid label='Network url' 
                                        placeholder='url...' required
                                        value={props.network_url}
                                        name="network_url"
                                        onChange={props.onChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row width={16}>
                                <Grid.Column>
                                    <Button color='red' size='mini' type='button'
                                            onClick={props.removeSocialNet}>
                                        <Icon name='cancel' /> Delete
                                    </Button>
                                    <Button color='green' size='mini'
                                            type='submit' floated='right'>
                                        <Icon name={props.editOrAdd === 'add' ? 'add' : 'edit'}/> 
                                            {props.editOrAdd === 'add' ? 'Add' : 'Edit'}
                                    </Button>
                                    <Button color='orange' size='mini' 
                                            type='button' floated='right'
                                            onClick={props.handleModalToggle}>
                                        <Icon name='cancel' /> Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        </Grid>
    )
}
export default SocialnetData;
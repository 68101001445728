import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateToken } from '../../actions/authAction';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Grid } from 'semantic-ui-react'
import Login from './Login';
import Sidemenu from './Sidemenu';
import Main from './Main';
import CityList from './CityList';
import CategoryList from './CategoryList';
// import OrganizationList from './OrganizationList';
import BranchList from './BranchList';
import Branch from './Branch';
import UserList from './UserList';
import User from './User';
import WithdrawalList from './WithdrawalList';
import OrderList from './OrderList';
import Withdrawal from './Withdrawal';
import BusinessUserList from './BusinessUserList';
import DealList from './DealList';
import ServiceList from './ServiceList';
import SaleList from './SaleList';
import Statistic from './Statistic';
import Promoter from './Promoter';

import '../../assets/css/App.css';

class NotFound extends React.Component {
  render() {
    return <h2>Ресурс не найден</h2>;
  }
}

class App extends Component {

  UNSAFE_componentWillMount() {
    this.props.updateToken(sessionStorage.getItem('tokenInfo'));
  }

  render() {
    if (this.props.token === null) {
      return (
        <HashRouter>
          <Switch>
            <Route exact path="/" render={(props) => (<Login {...props} />)} />
          </Switch>
        </HashRouter>
      );
    }

    return (
      <HashRouter>
        <Grid style={{ marginTop: '0', marginBottom: '0' }}>
          <Grid.Row style={{ padding: '0' }}>
            <Grid.Column width={2} className='sidemenu' style={{ paddingRight: '0' }}>
              <Route render={(props) => (<Sidemenu {...props} />)} />
            </Grid.Column>
            <Grid.Column width={14} style={{ padding: '0 15px 0 0', backgroundColor: 'honeydew' }}>
              <Switch>
                <Route exact path="/" render={(props) => (<Main {...props} />)} />
                <Route exact path="/cities" render={(props) => (<CityList {...props} />)} />
                <Route exact path="/categories" render={(props) => (<CategoryList {...props} />)} />
                {/* <Route exact path="/organizations" render={(props) => (<OrganizationList {...props} />)} /> */}
                <Route exact path="/branches" render={(props) => (<BranchList {...props} />)} />
                <Route exact path="/branch/:branchId" render={(props) => (<Branch {...props} />)} />
                <Route exact path="/users" render={(props) => (<UserList {...props} />)} />
                <Route exact path="/user/:userId" render={(props) => (<User {...props} />)} />
                <Route exact path="/withdrawals" render={(props) => (<WithdrawalList {...props} />)} />
                <Route exact path="/withdrawal/:orderId" render={(props) => (<Withdrawal {...props} />)} />
                <Route exact path="/orders" render={(props) => (<OrderList {...props} />)} />
                <Route exact path="/business-users" render={(props) => (<BusinessUserList {...props} />)} />
                <Route exact path="/deals" render={(props) => (<DealList {...props} />)} />
                <Route exact path="/services" render={(props) => (<ServiceList {...props} />)} />
                <Route exact path="/sales" render={(props) => (<SaleList {...props} />)} />
                <Route exact path="/stats" render={(props) => (<Statistic {...props} />)} />
                <Route exact path="/promoter" render={(props) => (<Promoter {...props} />)} />
                <Route component={NotFound} />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </HashRouter>
    );
  }
}

App.propTypes = {
  token: PropTypes.string,
  updateToken: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateToken: (token) => dispatch(updateToken(token))
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.token
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import axios from 'axios';
import { requestHasErrored, requestInProcess, updateItemId } from './commonAction';
import {timeDiff, getNewToken} from '../services/common';
var JSONbigString = require('json-bigint')({"storeAsString": true});

export function getServiceList(url) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.get(url, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
            if (response.status === 200) {
                // console.log(response);
                const arr = response.data.data;
                var arrLength = arr.length;
                var lastItemId = arrLength > 0 ? typeof arr[arrLength - 1].id === 'number' ? arr[arrLength - 1].id.toString() : arr[arrLength - 1].id : '';
                dispatch(updateItemId(lastItemId));
                dispatch(requestInProcess(false));
                dispatch(getServiceListSuccess(arr));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function getServiceListSuccess(list) {
    return {
        type: 'GET_SERVICE_LIST_SUCCESS',
        list
    };
}

export function emptyServiceList() {
    return {
        type: 'EMPTY_SERVICE_LIST'
    };
}

export function postService(url, data) {
  return async (dispatch) => {
      dispatch(requestInProcess(true));
      var token = sessionStorage.getItem('tokenInfo');
      //always check token validation
      if(timeDiff()){
          await getNewToken(token).then(function(res) {
              token = res.data.data.access_token;
          });
      }
      
      try {
          const response = await axios.post(url, data, { 'headers': { "Authorization": `Bearer ${token}` }, transformResponse: data => JSONbigString.parse(data) });
          if (response.data.message === "Created"  || response.data.message === "Success") {
              // console.log(response);
              dispatch(requestInProcess(false));
              dispatch(addServiceSuccess(response.data.data));
          }
          return true;
      }
      catch (error) {
          dispatch(requestInProcess(false));
          dispatch(requestHasErrored(true));
          // console.log(error);
          return false;
      }      
  };
}

export function addServiceSuccess(service) {
  return {
      type: 'ADD_SERVICE_SUCCESS',
      service
  };
}

export function handleRemove(url, id) {
    return async (dispatch) => {
        dispatch(requestInProcess(true));
        var token = sessionStorage.getItem('tokenInfo');
        //always check token validation
        if(timeDiff()){
            await getNewToken(token).then(function(res) {
                token = res.data.data.access_token;
            });
        }
        
        try {
            const response = await axios.delete(url, { 'headers': { "Authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                // console.log(response);
                dispatch(deleteServiceSuccess(id));
                dispatch(requestInProcess(false));
            }
            return response;
        }
        catch (error) {
            dispatch(requestInProcess(false));
            dispatch(requestHasErrored(true));
            // console.log(error);
            return Promise.reject(error.response);
        }      
    };
}

export function deleteServiceSuccess(serviceId) {
    return {
        type: 'DELETE_SERVICE_SUCCESS',
        serviceId
    };
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPromoter, updatePromoter, postQr } from '../../actions/promoterAction';
import { Grid, Form, Button, Header, Image } from 'semantic-ui-react';
import _ from 'lodash';

class Promoter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            registeredUser: {},
            userId: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        if(sessionStorage.getItem("phone")){
            this.setState({phone: sessionStorage.getItem("phone")}, function(){
                this.props.getPromoter('/api/v1/promoters/user/search?phone=%2B' + this.state.phone);
            })
        }
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.promoter !== this.props.promoter){
            const {promoter} = this.props;
            this.setState({
                registeredUser: promoter,
                userId: promoter.id
            });
        }
    }

    componentWillUnmount(){
        //need to clear phone from session
        sessionStorage.removeItem("phone");
    }

    onChange(e) {
        const {value, name} = e.target;
        
        this.setState({
            [name]: value
        });
    }
    
    searchPromoter(){
        sessionStorage.setItem('phone', this.state.phone);
        this.props.getPromoter('/api/v1/promoters/user/search?phone=%2B' + this.state.phone);
    }

    grantRevokeGenLinkPromoter(reqType){
        const grantRevoke = reqType !== 'delete' ? reqType === 'put' ? '/grant' : '/link' : '/revoke';
        const data = {
            role: "simple"
        }

        this.props.updatePromoter('/api/v1/promoters/' + this.state.userId + grantRevoke, data , reqType);
    }

    generateQr(){
        this.props.postQr('/api/v1/promoters/' + this.state.userId + '/qr');
    }

    render() {
        const {phone, registeredUser} = this.state;
        return (
            <Grid style={{margin: '0'}}>
                <Grid.Row>
                    <Grid.Column width={6}></Grid.Column>
                    <Grid.Column textAlign='center' width={4}>
                        <Form onSubmit={this.searchPromoter.bind(this)}>
                            <Form.Input type='text' placeholder='7 778 555 6677' action>
                                <input required name="phone" value={phone} onChange={this.onChange} />
                                <Button primary type='submit'>Search</Button>
                            </Form.Input>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={6}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        {_.isEmpty(registeredUser) ?
                            '' :
                            <Grid style={{margin: '0'}}>
                                <Grid.Row>
                                    <Grid.Column width={2} verticalAlign='middle'>
                                        {_.has(registeredUser, 'promoter') ?
                                            <Button color='red' compact onClick={this.grantRevokeGenLinkPromoter.bind(this, 'delete')}>Revoke</Button> :
                                            <Button color='green' compact onClick={this.grantRevokeGenLinkPromoter.bind(this, 'put')}>Promote</Button>
                                        }
                                    </Grid.Column>
                                    <Grid.Column width={6} textAlign='right' verticalAlign='middle'>
                                        <Header as='h5'>
                                            <span style={{color: 'blue', marginRight: '15px'}}>
                                                First Name:
                                            </span> {registeredUser.first_name}
                                        </Header>
                                        <Header as='h5'>
                                            <span style={{color: 'blue', marginRight: '15px'}}>
                                                Last Name:
                                            </span> {registeredUser.last_name}
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width={4} textAlign='left'>
                                        <Image 
                                            style={{height: '100px', width: '100px'}} avatar
                                            src={registeredUser.avatar === '' ? require('../../assets/img/no_image.png') : registeredUser.avatar}  
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4} textAlign='left' verticalAlign='middle'>
                                        <Header as='h5'>
                                            <span style={{color: 'red', marginRight: '15px'}}>
                                                Balance:
                                            </span> {registeredUser.balance}
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={6}></Grid.Column>
                    <Grid.Column textAlign='center' width={4}>
                        {_.has(registeredUser, 'promoter') ?
                            registeredUser.promoter.short_link === "" ? 
                                <Button color='instagram' compact onClick={this.grantRevokeGenLinkPromoter.bind(this, 'post')}>Generate link</Button> : 
                                registeredUser.promoter.short_link :
                            ''
                        }
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {_.has(registeredUser, 'promoter') ?
                            registeredUser.promoter.short_link !== "" ? 
                            registeredUser.promoter.qr.id !== "" ? 
                            <Image
                                src={registeredUser.promoter.qr.url}
                                verticalAlign='middle' centered
                                style={{padding: '10px', height: '150px'}}
                            /> : <Button color='vk' compact onClick={this.generateQr.bind(this)}>Generate QR</Button> : "" :
                            ''
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

Promoter.propTypes = {
    getPromoter: PropTypes.func,
    promoter: PropTypes.object,
    requestInProcess: PropTypes.bool,
    updatePromoter: PropTypes.func,
    postQr: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPromoter: (url) => dispatch(getPromoter(url)),
        updatePromoter: (url, data, reqType) => dispatch(updatePromoter(url, data, reqType)),
        postQr: (url) => dispatch(postQr(url))
    };
};

const mapStateToProps = (state) => {
    return {
        promoter: state.promoter,
        requestInProcess: state.requestInProcess
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promoter);
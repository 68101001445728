import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleRemove } from '../../actions/branchAction';
import { getProductList, postProduct, putProduct, 
            deleteProduct, postProductPhoto, updateProductSuccess } from '../../actions/productAction';
import { Grid, Button, Header, Table, Icon, Modal, Form, Popup } from 'semantic-ui-react';
import BannerData from '../stateless/BannerData';

class ProductList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productList: [],
            editIsOn: false,
            productName: '',
            productDescription: '',
            productPrice: '',
            productId: '',
            //for Modal
            modalOpen: false,
            formIsCreate: true,
            photoModalOpen: false,
            //for images
            photosVisible: false,
            photoList: [],
            photo_id: '',
            file: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.createProduct = this.createProduct.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.addProductPhoto = this.addProductPhoto.bind(this);
        this.deleteProductPhoto = this.deleteProductPhoto.bind(this);
    }

    componentDidMount(){
        // console.log("ProductList didMount");
        const {branchId} = this.props.match.params;
        const menuId = sessionStorage.getItem("menuId");
        this.props.getProductList('/api/v1/branches/' + branchId + '/products?menu_id=' + menuId);
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if(prevProps.productList !== this.props.productList){
            this.setState({productList: this.props.productList})
        }
    }

    onChange(e) {
        const {name, value} = e.target;
        // console.log(value, name);
        this.setState({
            [name]: value
        });
    }

    onFileChange = (e) => {
        // console.log(e.target.files[0]);
        this.setState({file: e.target.files[0]});
    }

    handleModalToggle = (productId) => {
        if(productId === ''){
            this.setState({
                productName: '',
                productDescription: '',
                productPrice: ''
            })
        }
        else{
            const product = this.state.productList.find(p => p.id === productId);
            this.setState({
                productName: product.name,
                productDescription: product.description,
                productPrice: product.price
            })
        }

        this.setState({ 
            productId,
            modalOpen: !this.state.modalOpen,
            formIsCreate: productId === '' ? true : false
        });
    }

    handleTogglePhotos = (photoList, productName, productId) => {
        this.setState({
            photoList,
            productName,
            productId
        }, function(){
            this.setState({
                photosVisible: !this.state.photosVisible
            })
        });
    }

    handlePhotoModalToggle = () => this.setState({ photoModalOpen: !this.state.photoModalOpen }, function(){
        //when cancel is pressed set file to an empty object
        if(!this.state.photoModalOpen)
            this.setState({ file: {} });
    })
    //for Dimmer in BannerData Component
    handleDimmerToggle = (id) => this.setState({ photo_id: id });

    createProduct(){
        const {branchId} = this.props.match.params;
        const menuId = sessionStorage.getItem("menuId");
        const{productName, productDescription, productPrice} = this.state;
        var data = {
            name: productName,
            description: productDescription,
            price: Number(productPrice),
            menu_id: menuId,
            photos: []
        };

        this.props.postProduct('/api/v1/branches/' + branchId + '/products', data)
        .then(function(res) {
            if(res.status === 200 || res.status === 201){
                this.setState({
                    modalOpen: false,
                    productName: '',
                    productDescription: '',
                    productPrice: '', //better to set the initial state
                });
            }
        }.bind(this));
    }

    toggleEdit = (productId, productName, status) => {
        this.setState({
            productId,
            productName,
        }, function(){
            this.setState({editIsOn: status ? status : !status})
        });
    };

    //updates the name of a product
    updateProduct(){
        const {branchId} = this.props.match.params;
        const{productList, productName, productDescription, productPrice, productId} = this.state;
        const selectedProduct = productList.find(p => p.id === productId);
        var data = {
            name: productName,
            description: productDescription,
            price: Number(productPrice),
            photos: selectedProduct.photos
        };

        this.props.putProduct('/api/v1/branches/' + branchId + '/products/' + productId, data)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    modalOpen: false,
                    productName: '',
                    productDescription: '',
                    productPrice: '', 
                    productId: '' //better to set the initial state
                });
            }
        }.bind(this));
    }

    //deletes the product
    removeProduct(productId){
        const {branchId} = this.props.match.params;

        this.props.deleteProduct('/api/v1/branches/' + branchId + '/products/' + productId, productId)
    }

    //uploads photo to product
    addProductPhoto() {
        const {branchId} = this.props.match.params;
        const {photoList, file, productId, productList} = this.state;
        var data = new FormData();
        data.append('photo', file);
        this.props.postProductPhoto('/api/v1/branches/' + branchId + '/products/' + productId + '/photos', data)
        .then(function(res) {
            if(res.status === 200){
                var uploadedFile = {
                    id: res.data.data.id,
                    url: res.data.data.url,
                    mime: res.data.data.mime
                }
                this.setState({
                    photoList: [...photoList, uploadedFile],
                    photoModalOpen: false,
                    file: {}
                }, function(){
                    var editedProduct = productList.find(p => p.id === productId);
                    editedProduct.photos = this.state.photoList;
                    this.props.updateProductSuccess(editedProduct);
                });
            }
        }.bind(this));
    }

    //removes photo of product by id
    deleteProductPhoto(id) {
        const {branchId} = this.props.match.params;
        const {photoList, productId, productList} = this.state;
        this.props.handleRemove('/api/v1/branches/' + branchId + '/products/' + productId + '/photos/' + id)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    photoList: photoList.filter(photo => photo.id !== id)
                }, function(){
                    var editedProduct = productList.find(p => p.id === productId);
                    editedProduct.photos = this.state.photoList;
                    this.props.updateProductSuccess(editedProduct);
                });
            }
        }.bind(this));
    }

    //activate/deactivate Product
    activateDeactivateProduct = (id, enabled) => {
        const {branchId} = this.props.match.params;
        const data = { enabled };
        this.props.putProduct('/api/v1/branches/' + branchId + '/products/' + id + '/activated', data);
    }

    //make/unmake the Product as a Gift
    makeUnmakeGift = (id, enabled) => {
        const {branchId} = this.props.match.params;
        const data = { enabled };
        this.props.putProduct('/api/v1/branches/' + branchId + '/products/' + id + '/gift/activate', data);
    }

    render() {
        const { productList, productName, productDescription, productPrice, 
                modalOpen, formIsCreate, photosVisible } = this.state;
        return (
            <React.Fragment>
                {photosVisible ? 
                    <Grid.Row columns={1}>
                        <Grid.Column style={{marginBottom: '15px'}}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={2}>
                                        <Button floated='left' compact color='olive' inverted
                                                onClick={this.handleTogglePhotos.bind(this, [], '', '')}>
                                            <Icon name='arrow left' />product list
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column width={12} textAlign='center' verticalAlign='middle'>
                                        <Header as='h4' style={{color: 'peru'}}>{productName}</Header>
                                    </Grid.Column>
                                    <Grid.Column width={2}></Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            <BannerData 
                                bannerList={this.state.photoList}
                                bannerModalOpen={this.state.photoModalOpen}
                                banner_id={this.state.photo_id}
                                onFileChange={this.onFileChange}
                                handleDimmerToggle={this.handleDimmerToggle}
                                addBanner={this.addProductPhoto}
                                deleteBanner={this.deleteProductPhoto}
                                handleBannerModalToggle={this.handlePhotoModalToggle}
                            />
                        </Grid.Column>
                    </Grid.Row> :
                    <Grid.Row columns={1}>
                        <Grid.Column style={{marginBottom: '10px'}}>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Button floated='left' compact color='blue'
                                                onClick={this.props.showHideProducts.bind(this, "")}>
                                            <Icon name='arrow left' />menu list
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign='center' verticalAlign='middle'>
                                        <Header as='h3'>Product List</Header>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button compact floated='right' color='teal'
                                                onClick={this.handleModalToggle.bind(this, '')}>
                                            Add product
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column>
                            <Table celled striped color='blue'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>№</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Product name
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Description
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Price
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Photos
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Active
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Gift
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Action
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                {productList.length > 0 &&
                                    <Table.Body>
                                        {productList.map((product, index) => (
                                            <Table.Row key={product.id}>
                                                <Table.Cell width={1}>
                                                    {index + 1}
                                                </Table.Cell>
                                                <Table.Cell width={2}>
                                                    {product.name}
                                                </Table.Cell>
                                                <Table.Cell width={5}>
                                                    {product.description}
                                                </Table.Cell>
                                                <Table.Cell width={2}>
                                                    {product.price}
                                                </Table.Cell>
                                                <Table.Cell width={2} textAlign='center'>
                                                    <Icon 
                                                        name='images' color='blue' inverted 
                                                        style={{cursor: 'pointer'}}
                                                        onClick={this.handleTogglePhotos.bind(this, product.photos, product.name, product.id)}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell width={1}>
                                                    <Popup
                                                        content={product.enabled ? 'Deactivate' : 'Activate'} 
                                                        trigger={<Icon size='large' color='green'
                                                            name={product.enabled ? 'check square' : 'square outline'} 
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => this.activateDeactivateProduct(product.id, !product.enabled)}
                                                        />} 
                                                    />
                                                </Table.Cell>
                                                <Table.Cell width={1}>
                                                    <Popup
                                                        content={product.gift ? 'Remove from gift' : 'Make a gift'} 
                                                        trigger={<Icon size='large' color='green'
                                                            name={product.gift ? 'check square' : 'square outline'} 
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => this.makeUnmakeGift(product.id, !product.gift)}
                                                        />} 
                                                    />
                                                </Table.Cell>
                                                <Table.Cell width={2}>
                                                    <Button inverted size='mini' color='yellow'
                                                            style={{paddingLeft: '8px', paddingRight: '8px'}}
                                                            onClick={this.handleModalToggle.bind(this, product.id)}>
                                                        <Icon name='pencil' />
                                                        Edit
                                                    </Button>
                                                    <Button inverted size='mini' color='red'
                                                            style={{paddingLeft: '8px', paddingRight: '8px'}}
                                                            onClick={this.removeProduct.bind(this, product.id)}>
                                                        <Icon name='trash' />
                                                        Delete
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))} 
                                    </Table.Body>
                                }
                                {!productList.length > 0 &&
                                    <Table.Body> 
                                        <Table.Row> 
                                            <Table.Cell style={{textAlign: 'center'}} colSpan='12'>Пусто</Table.Cell>
                                        </Table.Row> 
                                    </Table.Body>
                                }
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                }
                <Modal
                        centered={false} size='small'
                        open={modalOpen}
                        onClose={this.handleModalToggle.bind(this, '')}
                    >
                    <Modal.Content>
                        <Form onSubmit={formIsCreate ? this.createProduct : this.updateProduct}>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Input 
                                            fluid label='Product name' 
                                            placeholder='Product name' required
                                            value={productName}
                                            name="productName"
                                            onChange={this.onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.TextArea 
                                            label='Product description' rows='5'
                                            name="productDescription" required
                                            placeholder ='Product description' 
                                            value={productDescription}
                                            onChange={this.onChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Input 
                                            fluid label='Product price' type='number'
                                            placeholder='Product price' required
                                            value={productPrice}
                                            name="productPrice"
                                            onChange={this.onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row width={16}>
                                    <Grid.Column>
                                        <Button color='green' size='mini'
                                                type='submit' floated='right'>
                                            <Icon name={formIsCreate ? 'add' : 'save'}/> 
                                                {formIsCreate ? 'Create' : 'Save'}
                                        </Button>
                                        <Button color='orange' size='mini' 
                                                type='button' floated='right'
                                                onClick={this.handleModalToggle.bind(this, '')}>
                                            <Icon name='cancel' /> Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

ProductList.propTypes = {
    getProductList: PropTypes.func,
    postProduct: PropTypes.func,
    putProduct: PropTypes.func,
    deleteProduct: PropTypes.func,
    requestInProcess: PropTypes.bool,
    productList: PropTypes.array,
    handleRemove: PropTypes.func,
    postProductPhoto: PropTypes.func,
    updateProductSuccess: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductList: (url) => dispatch(getProductList(url)),
        postProduct: (url, data) => dispatch(postProduct(url, data)),
        putProduct: (url, data) => dispatch(putProduct(url, data)),
        deleteProduct: (url, id) => dispatch(deleteProduct(url, id)),
        handleRemove: (url) => dispatch(handleRemove(url)),
        postProductPhoto: (url, data) => dispatch(postProductPhoto(url, data)),
        updateProductSuccess: (product) => dispatch(updateProductSuccess(product))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        productList: state.productList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
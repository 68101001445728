import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Grid, Modal, Button } from "semantic-ui-react";

import { dynamicGetMethod } from "../../actions/branchAction";

const StatsBranchInfo = ({ modalOpen, handleModalToggle, branchId, numberWithCommas }) => {
  const [initialData, setInitialData] = useState({
    unique_user_amount: null,
    new_users_amount: null,
    loyal_users_amount: null,
    average_bill: null
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (branchId) {
      dispatch(dynamicGetMethod(`/api/v1/analytics/branches/${branchId}/additional_info`))
        .then((res) => {
          if (res.status === 200) {
            setInitialData(res.data.data);
          }
        });
    }
  }, [branchId]);

  return (
    <Modal
      centered={false}
      size='small'
      open={modalOpen}
      onClose={() => handleModalToggle(null)}
    >
      <Modal.Content>
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column>
              <h3>Уникальные клиенты: <span style={{ color: '#1dd235' }}>{initialData.unique_user_amount}</span></h3>
              <h3>Новые клиенты: <span style={{ color: '#1dd235' }}>{initialData.new_users_amount}</span></h3>
              <h3>Лояльные клиенты: <span style={{ color: '#1dd235' }}>{initialData.loyal_users_amount}</span></h3>
              <h3>Средний чек: <span style={{ color: '#1dd235' }}>{numberWithCommas(initialData.average_bill)}</span></h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16}>
            <Grid.Column>
              <Button
                color='teal'
                type='button'
                floated='right'
                onClick={() => handleModalToggle(null)}
              >
                Close
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

export default StatsBranchInfo;
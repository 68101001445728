import React from 'react';
import {Grid, Button, Form} from 'semantic-ui-react';
import BranchAnalitics from '../stateful/BranchAnalitics';

const expDateOptions = [
    {key: 0, value: 2592000000, text: 'Месяц'},
    {key: 1, value: 7776000000, text: 'Квартал'},
    {key: 3, value: 15552000000, text: 'Пол года'},
    {key: 4, value: 31104000000, text: 'Год'},
]

const CertificateData = (props) => {

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={6}>
                    {props.certificateAvailable && 
                        <Form size='mini' className='certificate-form'>
                            <Form.Select 
                                label='Срок сертификата' disabled
                                value={props.certificateExpDate}
                                options={expDateOptions}
                            />
                        </Form>
                    }
                </Grid.Column>
                <Grid.Column width={4} textAlign='center'>
                    <Button compact style={{marginBottom: '10px'}}
                            color={props.certificateAvailable ? 'red' : 'green'}
                            onClick={props.certificateAvailable ? props.activateDeactivateCertificate.bind(this, false) : props.activateDeactivateCertificate.bind(this, true)}>
                        {props.certificateAvailable ? 'Deactivate Certificate' : 'Activate Certificate'}
                    </Button>
                </Grid.Column>
                {props.certificateAvailable &&
                <Grid.Column width={6} textAlign='center'>
                    {!props.editCertificateExpDate ?
                        <Button compact style={{marginBottom: '10px'}} color='teal' type="button"
                                onClick={props.toggleEditCertificateExpDate}>
                            Изменить срок сертификата
                        </Button> :
                        <Grid>
                            <Grid.Column width={8}>
                                <Form size='mini'>
                                    <Form.Select 
                                        label='Срок сертификата' placeholder='Выберите...'
                                        name="certificateExpDate"
                                        value={props.certificateExpDate}
                                        options={expDateOptions}
                                        onChange={props.onSelectChange} 
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={8} verticalAlign='middle'>
                                <Button.Group size='mini'>
                                    <Button size='mini' color="green" type="button"
                                            onClick={props.updateCertificateExpDate}>
                                        Изменить
                                    </Button>
                                    <Button.Or size='mini' text='|' />
                                    <Button size='mini' color='orange' type='button' 
                                            onClick={props.toggleEditCertificateExpDate}>
                                        Отмена
                                    </Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid>    
                    }
                </Grid.Column>}
            </Grid.Row>
                {props.certificateAvailable &&
                    <BranchAnalitics {...props} />
                }
        </Grid>
    )
};
export default CertificateData;
import { combineReducers } from 'redux';
import { currentPage, requestHasErrored, requestInProcess, lastItemId } from './commonReducer';
import { token } from './authReducer';
import { cityList } from './cityReducer';
import { categoryList, subCategoryList, allcategoryList } from './categoryReducer';
import { organizationList } from './organizationReducer';
import { branchList, branch, corporateUserList, starsList, dropdownBranchList } from './branchReducer';
import { userList, businessUserList } from './userReducer';
import { orderList, order } from './orderReducer';
import { coordinates } from './mapReducer';
import { menuList } from './menuReducer';
import { productList } from './productReducer';
import { dealList } from './dealReducer';
import { saleList } from './saleReducer';
import { serviceList } from './serviceReducer';
import { promoter } from './promoterReducer';

export default combineReducers({
    currentPage, requestHasErrored, requestInProcess, lastItemId,
    token,
    cityList,
    categoryList, subCategoryList, allcategoryList,
    organizationList,
    branchList, branch, corporateUserList, starsList, dropdownBranchList,
    userList, businessUserList,
    orderList, order,
    coordinates,
    menuList,
    productList,
    dealList,
    saleList,
    serviceList,
    promoter
});
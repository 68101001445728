export function userList(state = [], action) {
    switch (action.type) {
        case 'GET_USER_LIST_SUCCESS':
            return state.concat(action.list);
        case 'SEARCH_LIST_SUCCESS':
            return action.list;
        case 'EMPTY_USER_LIST':
            return [];
        case 'UPDATE_USER':
            const index = state.findIndex(x => x.id === action.user.id);
            return [...state.slice(0, index), action.user, ...state.slice(index + 1)];
        case 'DELETE_USER_SUCCESS':
            return state.filter(item => item.id !== action.userId);
        default:
            return state;
    }
}

export function businessUserList(state = [], action) {
    switch (action.type) {
        case 'GET_BUSINESS_USER_LIST_SUCCESS':
            return state.concat(action.list);
        case 'ADD_BUSINESS_USER_TO_LIST':
            return [action.businessUser, ...state];
        case 'UPDATE_BUSINESS_USER':
            const index = state.findIndex(businessUser => businessUser.id === action.userId);
            return [...state.slice(0, index), action.businessUser, ...state.slice(index + 1)];
        case 'SEARCH_LIST_SUCCESS':
            return action.list;
        case 'EMPTY_BUSER_LIST':
            return [];
        case 'DELETE_BUSINESS_USER_SUCCESS':
            return state.filter(item => item.id !== action.userId);
        default:
            return state;
    }
}
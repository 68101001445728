import React from 'react';
import { Grid, Button } from 'semantic-ui-react';

const SecurityData = (props) => {

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <Button
            compact
            style={{ marginBottom: '10px' }}
            color={props.securityAvailable ? 'red' : 'green'}
            onClick={props.securityAvailable ? props.activateDeactivateSecurity.bind(this, false) : props.activateDeactivateSecurity.bind(this, true)}
          >
            {props.securityAvailable ? 'Deactivate' : 'Activate'}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};
export default SecurityData;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-2';

import { login } from '../../actions/authAction';
import MessageComponent from '../stateless/Message';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/semantic-ui.css';

const tabs = [
  { id: 0, code: 'phone', name: 'Phone number' },
  { id: 1, code: 'email', name: 'Email' },
  { id: 2, code: 'userId', name: 'User ID' }
]

class Login extends Component {
  state = {
    login: '',
    loginType: 'phone', // phone, email, userId
    password: '',
    positive: false,
    hidden: true,
    messageTitle: null,
    messageBody: null
  };

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  onPhoneInputChange = (value) => {
    this.setState({ login: value });
  }

  login = (e) => {
    e.preventDefault();

    const { loginType, login, password } = this.state;

    const data = {
      "login": loginType === 'phone' ? '+' + login : login.trim(),
      "password": password.trim()
    };

    this.props.login('/api/v1/auth/management', data)
      .then(res => {
        if (res.status === 200) {
          if (sessionStorage.getItem("role") === 'admin')
            this.props.history.push('/cities');
          else if (sessionStorage.getItem("role") === 'accountant')
            this.props.history.push('/withdrawals');
          else
            this.props.history.push('/statistics');
        }
      })
      .catch(error => {
        if (error.status === 401) {
          this.setState({
            positive: false,
            hidden: false,
            messageTitle: 'Ошибка!',
            messageBody: 'Вы ввели неправильный логин и/или пароль.'
          });
        }
        else if (error.status === 404) {
          this.setState({
            positive: false,
            hidden: false,
            messageTitle: 'Ошибка!',
            messageBody: 'Пожалуйста подтвердите вашу почту.'
          });
        }
        else {
          this.setState({
            positive: false,
            hidden: false,
            messageTitle: 'Ошибка Интернета!',
            messageBody: 'Извините, ошибка с Интернетом. Попробуйте позже!'
          });
        }
        
        setTimeout(() => {
          this.setState({ hidden: true });
        }, 4000);
      });
  }

  render() {
    const { login, loginType, positive, hidden, messageTitle, messageBody } = this.state;

    return (
      <div className='login-form'>
        {/* Heads up! The styles below are necessary for the correct render of this example.
          You can do same with CSS, the main idea is that all the elements up to the `Grid`
          below must have a height of 100%. */}
        <style>
          {
            `body > div,
            body > div > div,
            body > div > div > div.login-form {
              height: 100%;
              background-color: #f0f7ef;
            }`
          }
        </style>

        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='center'>
              Login
            </Header>
            <div
              style={{
                margin: '5px 0',
                display: 'flex',
                gap: '4px',
                justifyContent: 'center'
              }}
            >
              {tabs.map(tab => {
                return (
                  <div
                    key={tab.id}
                    style={{
                      backgroundColor: '#ffffff',
                      borderRadius: '8px',
                      padding: '5px 10px',
                      cursor: 'pointer',
                      color: loginType === tab.code ? '#00b5ad' : '#000000'
                    }}
                    onClick={() => this.setState({ loginType: tab.code, login: '' })}
                  >{tab.name}</div>
                );
              })}
            </div>
            <Form size='large' onSubmit={(e) => this.login(e)}>
              <div
                style={{
                  backgroundColor: '#ffffff',
                  padding: '1em',
                  borderRadius: '8px'
                }}
              >
                {loginType === 'phone' ?
                  <PhoneInput
                    onlyCountries={['kz', 'kg']}
                    country={'kz'}
                    value={login}
                    prefix='+'
                    onChange={(value) => this.onPhoneInputChange(value)}
                    containerStyle={{
                      marginBottom: '1em',
                      height: '43px'
                    }}
                    inputStyle={{
                      paddingLeft: '3em',
                      height: '100%'
                    }}
                  /> :
                  <Form.Input
                    fluid 
                    icon='user'
                    iconPosition='left' 
                    name='login'
                    placeholder={
                      loginType === 'email' ? 'example@gmail.com'
                        : '1234567890123'
                    }
                    type={loginType === 'userId' ? 'number' : 'email'}
                    value={login}
                    onChange={(e) => this.onChange(e)}
                  />
                }
                <Form.Input
                  fluid icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password' name='password'
                  onChange={(e) => this.onChange(e)}
                />

                <Button color='teal' fluid size='large' type='submit'>
                  Login
                </Button>
                {!hidden &&
                  <MessageComponent
                    positive={positive}
                    hidden={hidden}
                    messageTitle={messageTitle}
                    messageBody={messageBody}
                  />
                }
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
  requestInProcess: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (url, data) => dispatch(login(url, data))
  };
};

const mapStateToProps = (state) => {
  return {
    requestInProcess: state.requestInProcess
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMenuList, postMenu, putMenu, deleteMenu } from '../../actions/menuAction';
import { updateBranchDetails, removeObject } from '../../actions/branchAction';
import { Grid } from 'semantic-ui-react';
import MenuData from '../stateless/MenuData';
import ProductList from './ProductList';

class MenuPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuList: [],
            menuAvailable: this.props.menuAvailable,
            delivery: {...this.props.delivery},
            prevDelivery: {...this.props.delivery},
            pickup: {...this.props.pickup},
            prevPickup: {...this.props.pickup},
            createIsOn: false,
            editIsOn: false,
            menuName: '',
            currentMenuId: '',
            productsVisible: false,
            deliveryIsOn: false,
            pickupIsOn: false
        };

        this.onChange = this.onChange.bind(this);
        this.createMenu = this.createMenu.bind(this);
        this.updateMenu = this.updateMenu.bind(this);
        this.removeMenu = this.removeMenu.bind(this);
    }

    componentDidMount(){
        //lets call the function when menu have to be seen
        if(this.state.menuAvailable){
            const {branchId} = this.props.match.params;
            this.props.getMenuList('/api/v1/branches/' + branchId + '/menus');
        }
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps);
        if(prevProps.menuList !== this.props.menuList){
            this.setState({menuList: this.props.menuList})
        }

        if(prevProps.menuAvailable !== this.props.menuAvailable){
            this.setState({menuAvailable: this.props.menuAvailable}, function(){
                if(this.state.menuAvailable){
                    const {branchId} = this.props.match.params;
                    this.props.getMenuList('/api/v1/branches/' + branchId + '/menus');
                }
            })
        }
    }

    onChange(e) {
        const {name, value} = e.target;
        // console.log(value, name);
        if(name === 'delivery_price_in_city' || name === 'delivery_price_out_city' || name === 'delivery_time'){
            const {delivery} = this.state;
            delivery[name.substring(9, name.length)] = name.includes('time') ? value !== '' ? Number(value)*60000 : '' : value !== '' ? Number(value) : '';
            this.setState({delivery});
        }
        else if(name === 'pickup_price_in_city' || name === 'pickup_price_out_city' || name === 'pickup_time'){
            const {pickup} = this.state;
            pickup[name.substring(7, name.length)] = name.includes('time') ? value !== '' ? Number(value)*60000 : '' : value !== '' ? Number(value) : '';
            this.setState({pickup});
        }
        else{
            this.setState({
                [name]: value
            });
        }
    }

    toggleCreate = () => this.setState({createIsOn: !this.state.createIsOn, menuName: '', editIsOn: false});

    createMenu(){
        const {branchId} = this.props.match.params;
        const{menuName} = this.state;
        var data = {
            name: menuName
        };

        this.props.postMenu('/api/v1/branches/' + branchId + '/menus/', data)
        .then(function(res) {
            if(res.status === 200 || res.status === 201){
                this.setState({
                    createIsOn: false,
                    menuName: '' //better to set the initial state
                });
            }
        }.bind(this));
    }

    toggleEdit = (menuId, menuName, status) => {
        this.setState({
            currentMenuId: menuId,
            menuName,
            createIsOn: false
        }, function(){
            this.setState({editIsOn: status ? status : !status})
        });
    };

    //updates the name of a menu
    updateMenu(){
        const {branchId} = this.props.match.params;
        const{menuName, currentMenuId} = this.state;
        var data = {
            name: menuName
        };
        var menu = {
            id: currentMenuId,
            name: menuName
        }

        this.props.putMenu('/api/v1/branches/' + branchId + '/menus/' + currentMenuId, data, menu)
        .then(function(res) {
            if(res.status === 200){
                this.setState({
                    editIsOn: false,
                    menuName: '', currentMenuId: '' //better to set the initial state
                });
            }
        }.bind(this));
    }

    //deletes the menu
    removeMenu(menuId){
        const {branchId} = this.props.match.params;

        this.props.deleteMenu('/api/v1/branches/' + branchId + '/menus/' + menuId, menuId)
    }

    //to open productList
    showHideProducts = (id) => {
        if(id === "")
            sessionStorage.removeItem("menuId");
        else
            sessionStorage.setItem("menuId", id);
        this.setState({ productsVisible: !this.state.productsVisible });
    }

    toggleDeliveryEdit = () => {
        this.setState({deliveryIsOn: !this.state.deliveryIsOn}, () => {
            //when pressing cancel we set the values to previous
            if(!this.state.deliveryIsOn){
                // console.log(this.state.prevDelivery)
                this.setState({
                    delivery: this.state.prevDelivery
                })
            }
        })
    }

    togglePickupEdit = () => {
        this.setState({pickupIsOn: !this.state.pickupIsOn}, () => {
            //when pressing cancel we set the values to previous
            if(!this.state.pickupIsOn){
                // console.log(this.state.prevPickup)
                this.setState({
                    pickup: this.state.prevPickup
                })
            }
        })
    }

    createOrSaveDeliverence = (createOrSave, type) => {
        const {branchId} = this.props.match.params;
        const {delivery, pickup} = this.state;
        var data = {};
        if(createOrSave === 'create'){
            data.type = type ==='delivery' ? 'DELIVERY' : 'PICKUP';
            data.price_in_city = 0;
            data.price_out_city = 0;
            data.time = 1800000;
        }else{
            data = type ==='delivery' ? delivery : pickup;
        }
        this.props.updateBranchDetails('/api/v1/branches/' + branchId + '/configuration/online/delivery', data)
        .then(res => {
            if(res.status === 200){
                // console.log(res.data)
                if(type === 'delivery'){
                    this.setState({
                        deliveryIsOn: false,
                        delivery: res.data.data.configuration.online.delivery,
                        prevDelivery: res.data.data.configuration.online.delivery
                    }, () => {
                        this.props.updateState('delivery', this.state.delivery)
                    });
                }else{
                    this.setState({
                        pickupIsOn: false,
                        pickup: res.data.data.configuration.online.pickup,
                        prevPickup: res.data.data.configuration.online.pickup
                    }, () => {
                        this.props.updateState('pickup', this.state.pickup)
                    });
                }
            }
        })
    }

    deleteDeliverence = (type) => {
        const {branchId} = this.props.match.params;
        this.props.removeObject('/api/v1/branches/' + branchId + '/configuration/online/delivery?type=' + type.toUpperCase())
        .then(res => {
            if(res.status === 200){
                // console.log(res.data)
                if(type === 'delivery'){
                    this.setState({
                        delivery: {},
                        prevDelivery: {}
                    }, () => {
                        this.props.updateState('delivery', this.state.delivery)
                    });
                }else{
                    this.setState({
                        pickup: {},
                        prevPickup: {}
                    }, () => {
                        this.props.updateState('pickup', this.state.pickup)
                    });
                }
            }
        })
    }

    render() {
        const { productsVisible } = this.state;
        return (
            <Grid>
                {productsVisible ?
                    <ProductList 
                        {...this.props}
                        showHideProducts={this.showHideProducts}
                    /> :
                    <MenuData  
                        {...this.state}
                        activateDeactivateMenu={this.props.activateDeactivateMenu}
                        onChange={this.onChange}
                        toggleCreate={this.toggleCreate}
                        createMenu={this.createMenu}
                        toggleEdit={this.toggleEdit}
                        updateMenu={this.updateMenu}
                        removeMenu={this.removeMenu}
                        showHideProducts={this.showHideProducts}
                        toggleDeliveryEdit={this.toggleDeliveryEdit}
                        togglePickupEdit={this.togglePickupEdit}
                        createOrSaveDeliverence={this.createOrSaveDeliverence}
                        deleteDeliverence={this.deleteDeliverence}
                    />
                }
            </Grid>
        );
    }
}

MenuPanel.propTypes = {
    getMenuList: PropTypes.func,
    postMenu: PropTypes.func,
    putMenu: PropTypes.func,
    deleteMenu: PropTypes.func,
    requestInProcess: PropTypes.bool,
    menuList: PropTypes.array,
    updateBranchDetails: PropTypes.func,
    removeObject: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMenuList: (url) => dispatch(getMenuList(url)),
        postMenu: (url, data) => dispatch(postMenu(url, data)),
        putMenu: (url, data, menu) => dispatch(putMenu(url, data, menu)),
        deleteMenu: (url, id) => dispatch(deleteMenu(url, id)),
        updateBranchDetails: (url, data) => dispatch(updateBranchDetails(url, data)),
        removeObject: (url) => dispatch(removeObject(url))
    };
};

const mapStateToProps = (state) => {
    return {
        requestInProcess: state.requestInProcess,
        menuList: state.menuList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPanel);